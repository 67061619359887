import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExitDeleteRemoveClose } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { Icon } from './Icon.component';
import { Label, LabelType } from './Label.component';
import { Skeleton } from './Skeleton/Skeleton.component';
import { ColorType } from './index';

interface InputProps {
  id?: string;
  label?: string;
  labelColor?: ColorType;
  bgColor?: ColorType;
  hasCloseButton?: boolean;
  onClick?: (id?: string) => void;
  translationOn?: boolean;
  disabled?: boolean;
  iconLeft?: any;
  iconColor?: string; // hex color
  isLoading?: boolean;
  dataTestId?: string;
  labelClass?: string;
  closeButtonAlt?: string;
  iconLeftAlt?: string;
}

export const Pill = memo(
  ({
    id,
    label = '',
    bgColor = ColorType.GREY2,
    labelColor = ColorType.GREY6,
    hasCloseButton,
    onClick,
    translationOn = false,
    disabled = false,
    iconLeft,
    iconColor,
    isLoading = false,
    dataTestId = '',
    labelClass = '',
    closeButtonAlt = '',
    iconLeftAlt = '',
  }: InputProps) => {
    const { t } = useTranslation();
    const handleOnClick = () => {
      onClick && onClick(id);
    };
    if (isLoading) {
      return (
        <Skeleton
          innerClassName='rounded-3xl'
          height='h-6'
          width='w-14'
          isPill
        />
      );
    }
    return (
      <div
        className={`px-2 py-0.5 gap-1 bg-${bgColor} rounded-3xl flex flex-row items-center h-6 whitespace-nowrap w-max`}
      >
        {iconLeft && (
          <Icon
            src={iconLeft}
            color={iconColor}
            size={IconSize.SIZE_16x16}
            alt={iconLeftAlt}
          />
        )}
        <Label
          type={LabelType.LABEL_S_MEDIUM}
          text={translationOn ? t(label) : label}
          color={labelColor}
          dataTestId={dataTestId}
          className={labelClass}
        />
        {hasCloseButton && (
          <Icon
            src={ExitDeleteRemoveClose}
            size={IconSize.SIZE_16x16}
            onClick={handleOnClick}
            disabled={disabled}
            alt={closeButtonAlt}
          />
        )}
      </div>
    );
  },
);

Pill.displayName = 'Pill';
