import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  DoughnutController,
  Legend,
  LinearScale,
} from 'chart.js';
import { memo, useEffect, useMemo, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '../Skeleton/Skeleton.component';
import { ColorType, getHexColorByType, isHexColor } from '../shared/Colors.g';

ChartJS.register(
  ArcElement,
  Legend,
  CategoryScale,
  LinearScale,
  DoughnutController,
);

interface DoughnutChartProps {
  items?: Array<any>;
  className?: string;
  valueField?: string;
  labelField?: string;
  colorField?: string;
  isLoading?: boolean;
}

export const DoughnutChart = memo(
  ({
    items = [],
    className = 'flex h-40',
    valueField = 'value',
    labelField = 'label',
    colorField = 'color',
    isLoading = false,
  }: DoughnutChartProps) => {
    const { t } = useTranslation();
    const chartRef = useRef<ChartJS<'doughnut'> | null>(null);

    useEffect(() => {
      if (chartRef && chartRef.current) {
        chartRef.current?.canvas.setAttribute(
          'aria-label',
          t('chart_alt_chargers_status_pie_chart'),
        );
      }
    }, []);

    const sortedItems = useMemo(() => {
      return items.sort((a, b) => (a[valueField] > b[valueField] ? -1 : 1));
    }, [items, valueField]);

    const labels = useMemo(() => {
      return sortedItems.map(
        (item: any) => `${item[labelField]} (${item[valueField]})`,
      );
    }, [labelField, sortedItems, valueField]);

    const data = useMemo(() => {
      return sortedItems.map((item: any) => item[valueField]);
    }, [sortedItems, valueField]);

    const colors = useMemo(() => {
      return sortedItems.map((item: any) =>
        isHexColor(item[colorField])
          ? item[colorField]
          : getHexColorByType(item[colorField]),
      );
    }, [colorField, sortedItems]);

    if (isLoading) {
      return (
        <div className='flex flex-row gap-8'>
          <div className='flex'>
            <Skeleton
              className='border-[28px] rounded-[50%] border-grey2'
              height='h-20'
              width='w-20'
              loadingColor='white'
            />
          </div>
          <div className='flex self-center'>
            <Skeleton
              height='h-5'
              width='w-36'
              count={4}
              className='flex flex-col gap-2'
            />
          </div>
        </div>
      );
    }

    return (
      <div className={className}>
        <Doughnut
          ref={chartRef}
          data={{
            labels: labels,
            datasets: [
              {
                data: data,
                backgroundColor: colors,
              },
            ],
          }}
          options={{
            events: [],
            responsive: true,
            maintainAspectRatio: false,
            cutout: 40,
            layout: {
              padding: {
                top: 2,
                bottom: 2,
                left: 2,
                right: 2,
              },
            },
            plugins: {
              tooltip: {
                enabled: false,
              },
              legend: {
                onClick: (e) => {},
                display: true,
                position: 'right',
                labels: {
                  color: getHexColorByType(ColorType.GREY6),
                  boxWidth: 12,
                  boxHeight: 12,
                  font: {
                    size: 14,
                  },
                },
              },
            },
          }}
        />
      </div>
    );
  },
);

DoughnutChart.displayName = 'DoughnutChart';
