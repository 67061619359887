// React
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { BooleanParam, NumberParam, useQueryParams } from 'use-query-params';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import {
  Button,
  ButtonSize,
  ButtonType,
  Card,
  ColorType,
  Dropdown,
  DropdownType,
  Label,
  LabelType,
  MODAL_TYPES,
  Pill,
  useGlobalModalContext,
} from '../_ui';

// API
import { useLazyFetchLocationsV3Query } from '../../services/location.api';

// Utils
import { copyChargingToInuse } from './utils';

// Constants
import {
  AddPlus2,
  ChargerEV,
  CompanyBuilding,
  Location as LocationIcon,
} from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { NUMBER } from '../../constant/Number.constant';
import {
  CHARGER_FLAG,
  CHARGER_STATUS,
  OCPP_STATUS,
} from '../../constant/Text.constant';
import {
  CommaArrayParam,
  DashArrayParam,
} from '../../constant/UrlSearchParams.constant';
import { useCompany } from '../../hooks/useCompany';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import { Charger, Location } from '../../stores/types';
import InputSearch from '../_ui/InputSearch/InputSearch.component';
import { ChargerBulkEditor } from './ChargerBulkEditor';
import { ChargerGrid } from './ChargerGrid.component';
import { ChargerSharedDetailEditor } from './ChargerSharedDetailEditor';
import { ChargerSpecificDetailEditor } from './ChargerSpecificDetailEditor';
import { ChargersOverview } from './ChargersOverview.component';

export const Chargers = () => {
  const { t } = useTranslation();
  const { showModal, hideModal } = useGlobalModalContext();
  const { isMultiViewActive, companies, companyScope, companyId } =
    useCompany();
  const theme = useSelector(getCurrentTheme);
  const [isClearInput, setIsClearInput] = useState(false);
  const [chargerListQuery, setChargerListQuery] = useQueryParams({
    status: DashArrayParam,
    locationId: CommaArrayParam,
    maintenanceFlag: BooleanParam,
    searches: CommaArrayParam,
    page: NumberParam,
  });

  // If any dropdown filter change, reset to page 1
  useEffect(() => {
    setChargerListQuery({ page: 1 });
  }, [
    chargerListQuery.searches,
    chargerListQuery.locationId,
    chargerListQuery.status,
    chargerListQuery.maintenanceFlag,
    setChargerListQuery,
  ]);
  const handleLoadPage = (page: number) => {
    setChargerListQuery({ page: page });
  };

  // Refetch & get charger id from url (if present) when url query changes
  const selectedStatusFilter = useMemo(() => {
    const ocppStatus = Object.values(OCPP_STATUS);

    return (chargerListQuery?.status || [])?.map((item) => {
      const type = item && ocppStatus.includes(item) ? 'OCPP_STATUS' : 'STATUS';
      return { type, status: item };
    });
  }, [chargerListQuery]);

  const numOfPills = useMemo(() => {
    return (
      (chargerListQuery.searches?.length || 0) +
      (chargerListQuery.locationId?.length || 0) +
      (chargerListQuery.status?.length || 0) +
      (chargerListQuery.maintenanceFlag ? 1 : 0)
    );
  }, [
    chargerListQuery.locationId?.length,
    chargerListQuery.maintenanceFlag,
    chargerListQuery.searches?.length,
    chargerListQuery.status?.length,
  ]);

  const isFilterDisabled = useMemo(() => {
    return numOfPills >= NUMBER.MAX_FILTERS;
  }, [numOfPills]);

  // Refetch & get status from url (if present) when url query changes
  const statusDropdownList = useMemo(() => {
    return Object.values(CHARGER_STATUS)
      .filter(
        (status) =>
          // these 2 status are not in /charger endpoints
          status !== CHARGER_STATUS.PREPARING &&
          status !== CHARGER_STATUS.SCHEDULED,
      )
      .map((status) => {
        if (status === 'in_use') {
          const children = Object.values(OCPP_STATUS).map((ocppStatus) => ({
            status: ocppStatus,
            label: `${ocppStatus}`,
            selected: chargerListQuery.status
              ? chargerListQuery.status?.includes(ocppStatus)
              : false,
            disabled: isFilterDisabled,
          }));

          const isParentDisabled =
            isFilterDisabled ||
            numOfPills + children.filter((child) => !child.selected).length >
              NUMBER.MAX_FILTERS;

          return {
            status,
            label: `${copyChargingToInuse(status)}`,
            selected: children?.every((child: any) => child.selected) || false,
            children,
            disabled: isParentDisabled,
          };
        }
        return {
          status,
          label: `${copyChargingToInuse(status)}`,
          selected: chargerListQuery.status
            ? chargerListQuery.status?.includes(status)
            : false,
          disabled: isFilterDisabled,
        };
      });
  }, [chargerListQuery, isFilterDisabled]);

  const [triggerFetchLocationV3, { data: locationData }] =
    useLazyFetchLocationsV3Query();

  // Refetch & get maintenanceFlag from url (if present) when url query changes
  const flagDropdownList = useMemo(() => {
    return Object.values(CHARGER_FLAG).map((status) => ({
      status,
      label: `${status}`,
      selected: chargerListQuery.maintenanceFlag || false,
      disabled: isFilterDisabled,
    }));
  }, [chargerListQuery, isFilterDisabled]);

  const [selectedChargers, setSelectedChargers] = useState<Array<Charger>>([]);

  const handleAddChargerClick = () => {
    showModal(MODAL_TYPES.WIZARD_MODAL, {
      stepTitles: [
        t('chargers_add_step_1_title'),
        t('chargers_add_step_2_title'),
      ],
      stepComponents: [
        // eslint-disable-next-line react/jsx-key
        <ChargerSharedDetailEditor />,
        // eslint-disable-next-line react/jsx-key
        <ChargerSpecificDetailEditor />,
      ],
      shouldCloseOnOverlayClick: false,
    });
  };

  const handleClearAllClick = () => {
    setChargerListQuery({
      locationId: undefined,
      status: undefined,
      maintenanceFlag: undefined,
      searches: undefined,
      page: 1,
    });

    // Reset input search values
    setIsClearInput(true);
    setTimeout(() => setIsClearInput(false), NUMBER.ZERO);
  };

  useEffect(() => {
    // clear all existing filters
    handleClearAllClick();

    // clear selected chargers
    setSelectedChargers([]);

    triggerFetchLocationV3({
      scope: companyScope,
    });
  }, [companyId, companyScope, triggerFetchLocationV3]);

  const locationDropdownList = useMemo(() => {
    if (!companies || !locationData) {
      return [];
    }
    return companies
      ?.map((company: any) => {
        const children = locationData?.entities
          .filter((item: Location) => item.companyId === company.id)
          .map((location: Location) => {
            return {
              id: location.id,
              label: location.name,
              selected: chargerListQuery.locationId?.some(
                (id) => id === location.id,
              ),
              disabled: isFilterDisabled,
            };
          });

        const isCompanyDisabled =
          isFilterDisabled ||
          numOfPills + children.filter((child) => !child.selected).length >
            NUMBER.MAX_FILTERS;
        return {
          id: company.name,
          label: company.name,
          companyIcon: CompanyBuilding,
          iconLeftAlt: t('company'),
          iconRightAlt: t('icon_alt_view_companies'),
          selected: children?.every((child: any) => child.selected) || false,
          children: children || [],
          disabled: isCompanyDisabled,
        };
      })
      .filter((item: any) => !!item.children.length);
  }, [chargerListQuery.locationId, companies, locationData, isFilterDisabled]);

  const handleLocationPillClick = (id: any) => {
    setChargerListQuery({
      locationId: chargerListQuery.locationId?.filter((i) => i !== id),
    });
  };

  const handleSearchPillClick = (item: any) => {
    setChargerListQuery({
      searches: chargerListQuery.searches?.filter((i) => i !== item),
    });
  };

  const renderLocationPills = () => {
    const locationPillsToShow: any[] = [];
    locationDropdownList.forEach((ele) => {
      ele.children.forEach((child: any) => {
        if (child.selected) {
          locationPillsToShow.push({
            id: child.id,
            label: child.label,
          });
        }
      });
    });

    return locationPillsToShow.map((item) => {
      return (
        <Pill
          id={item.id}
          iconLeft={LocationIcon}
          key={item.id}
          label={item.label}
          hasCloseButton
          onClick={handleLocationPillClick}
          translationOn
          closeButtonAlt={t('icon_alt_remove_filter_tag')}
        />
      );
    });
  };

  const renderSearchChargerPills = () => {
    return chargerListQuery.searches?.map((item) => {
      return (
        <Pill
          key={item}
          label={`"${item}"`}
          hasCloseButton
          onClick={() => handleSearchPillClick(item)}
          translationOn
          iconLeft={ChargerEV}
          closeButtonAlt={t('icon_alt_remove_filter_tag')}
        />
      );
    });
  };

  const handleStatusPillClick = (clickedItem: any) => {
    if (clickedItem.type === 'OCPP_STATUS') {
      const filterStatus = [...selectedStatusFilter].filter(
        (i) => i.status !== clickedItem.status,
      );
      setChargerListQuery({
        status:
          filterStatus?.length > 0
            ? filterStatus.map((items) => items.status)
            : undefined,
      });
    } else {
      const filteredStatusList: any = statusDropdownList.find(
        (ele: any) => ele.status === clickedItem.status,
      );

      let filterStatus;
      if (filteredStatusList?.children) {
        const filteredStatusListData: string[] =
          filteredStatusList?.children.map((ele: any) => ele.status);
        filterStatus = [...selectedStatusFilter].filter((i) => {
          return !filteredStatusListData.includes(i.status || '');
        });
      } else {
        filterStatus = [...selectedStatusFilter].filter((i) => {
          return filteredStatusList.status !== i.status;
        });
      }

      setChargerListQuery({
        status:
          filterStatus?.length > 0
            ? filterStatus.map((item) => item.status)
            : undefined,
      });
    }
  };

  const handleFlagPillClick = () => {
    setChargerListQuery({
      maintenanceFlag: !chargerListQuery.maintenanceFlag,
    });
  };

  const renderStatusPills = () => {
    const statusPillsToShow: any[] = [];

    statusDropdownList.forEach((ele) => {
      if (ele.selected) {
        statusPillsToShow.push({
          status: ele.status,
          type: 'STATUS',
          label: ele.label,
        });
      } else {
        ele?.children?.forEach((child: any) => {
          if (child.selected) {
            statusPillsToShow.push({
              status: child.status,
              label: child.label,
              type: 'OCPP_STATUS',
            });
          }
        });
      }
    });
    return statusPillsToShow.map((item) => {
      return (
        <Pill
          key={item.status}
          label={item.label}
          hasCloseButton
          onClick={() => handleStatusPillClick(item)}
          translationOn
          closeButtonAlt={t('icon_alt_remove_filter_tag')}
        />
      );
    });
  };

  const renderFlagPills = () => {
    return flagDropdownList
      .filter((item) => item.selected)
      .map((item) => {
        return (
          <Pill
            key={item.status}
            label={item.label}
            hasCloseButton
            onClick={handleFlagPillClick}
            translationOn
            closeButtonAlt={t('icon_alt_remove_filter_tag')}
          />
        );
      });
  };

  const handleEditBulkChargers = () => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('edit_chargers'),
      width: '540px',
      height: 'max-content',
      onRenderBody: () => (
        <ChargerBulkEditor
          selectedChargers={selectedChargers}
          setSelectedChargers={setSelectedChargers}
          hideModal={hideModal}
        />
      ),
      shouldCloseOnOverlayClick: false,
    });
  };

  const handleLocationFilterChange = (items: any) => {
    const newLocationIds: Array<string> = [];
    items.forEach((item: any) => {
      item.children.forEach((child: any) => {
        if (child.selected) {
          newLocationIds.push(child.id);
        }
      });
    });
    setChargerListQuery({
      locationId: newLocationIds,
    });
  };

  const handleStatusChange = (items: any) => {
    const existingSelectedFilter = [...selectedStatusFilter];
    const selectedStatusFilters: any[] = [];

    items.forEach((item: any) => {
      if (item.status === CHARGER_STATUS.IN_USE) {
        item?.children?.forEach((child: any) => {
          const filterObj = { type: 'OCPP_STATUS', status: child.status };
          if (child.selected) {
            if (!_.some(selectedStatusFilter, filterObj)) {
              selectedStatusFilters.push(filterObj);
            }
          } else if (_.some(selectedStatusFilter, filterObj)) {
            _.remove(existingSelectedFilter, { status: child.status });
          }
        });
      } else {
        const filterObj = { type: 'STATUS', status: item.status };
        if (item.selected) {
          if (!_.some(selectedStatusFilter, filterObj)) {
            selectedStatusFilters.push(filterObj);
          }
        } else if (_.some(selectedStatusFilter, filterObj)) {
          _.remove(existingSelectedFilter, { status: item.status });
        }
      }
    });

    setChargerListQuery({
      status: [...existingSelectedFilter, ...selectedStatusFilters].map(
        (ele) => ele.status,
      ),
    });
  };

  const handleUnderMaintenanceDropdownChange = (items: any) => {
    const filteredList = items.filter((item: any) => item.selected === true);
    setChargerListQuery({
      maintenanceFlag:
        filteredList?.length > 0 ? filteredList[0].selected : undefined,
    });
  };

  const handleSearch = (item: any) => {
    const filteredSearches =
      chargerListQuery.searches?.filter((search) => search === item) || [];
    let arr: any[] = chargerListQuery.searches || [];
    if (filteredSearches.length > 0) {
      arr = [...(chargerListQuery.searches || [])];
    } else {
      arr = [...(chargerListQuery.searches || []), item];
    }
    setChargerListQuery({ searches: arr });
  };

  const renderDropdown = () => {
    return (
      <div className='flex flex-row justify-between'>
        <div className='flex gap-3'>
          <InputSearch
            clearInput={isClearInput}
            width='280px'
            iconSize={IconSize.SIZE_24x24}
            inputIconPadding='pl-11'
            suffixIconClassName='mr-4'
            options={[]}
            onSelect={handleSearch}
            placeholder={t('charger_filter_holder')}
            contentDivWidth='w-[277px]'
            disabled={isFilterDisabled}
            inputId='charger-input-search'
            inputTitle={t('charger_filter_holder')}
          />
          <Dropdown
            placeholder={t('location')}
            placeholderLabelType={LabelType.DROPDOWN_HEADER}
            headerWidth='auto'
            contentDivHeight={464}
            contentDivWidth={335}
            items={locationDropdownList}
            onItemClick={handleLocationFilterChange}
            type={DropdownType.CHECKBOX_TREE}
            disabled={isFilterDisabled}
            iconAlt={t('icon_alt_location_dropdown')}
          />
          <Dropdown
            placeholder={t('status')}
            placeholderLabelType={LabelType.DROPDOWN_HEADER}
            type={DropdownType.CHECKBOX_TREE}
            headerWidth='auto'
            items={statusDropdownList}
            onItemClick={handleStatusChange}
            translationOn
            showFooter
            dataTestId='select-status'
            disabled={isFilterDisabled}
            iconAlt={t('icon_alt_status_dropdown')}
          />
          <Dropdown
            placeholder={t('flag')}
            placeholderLabelType={LabelType.DROPDOWN_HEADER}
            type={DropdownType.CHECKBOX}
            headerWidth='auto'
            items={flagDropdownList}
            onItemClick={handleUnderMaintenanceDropdownChange}
            translationOn
            showFooter
            disabled={isFilterDisabled}
            iconAlt={t('icon_alt_maintenance_dropdown')}
          />
        </div>
        {!isMultiViewActive && (
          <div className='flex gap-3'>
            <Button
              onClick={() => {
                if (selectedChargers.length) {
                  handleEditBulkChargers();
                }
              }}
              label={`${t('Edit')} ${
                selectedChargers.length ? selectedChargers.length : ''
              } ${t('selected')}`}
              size={ButtonSize.SMALL}
              type={ButtonType.SECONDARY}
              disabled={selectedChargers.length === 0}
              dataTestId='editChargerButton'
            />
            <Button
              icon={AddPlus2}
              onClick={handleAddChargerClick}
              label={t('chargers_add_label')}
              size={ButtonSize.SMALL}
              type={ButtonType.TERTIARY}
              dataTestId='addChargerButton'
              iconAlt={t('icon_alt_add_charger')}
            />
          </div>
        )}
      </div>
    );
  };

  const renderClearAllButton = () => {
    if (numOfPills > 0) {
      return (
        <div className='flex flex-row gap-4'>
          <Label
            type={LabelType.BODY3}
            color={ColorType.GREY6}
            text={`${numOfPills}/${NUMBER.TWENTY} ${t('filters')}`}
          />
          <button
            type='button'
            className='text-left'
            onClick={handleClearAllClick}
          >
            <Label
              type={LabelType.DROPDOWN_ITEM_SELECTED}
              color={ColorType.BLACK}
              text={t('session_clear_all')}
              style={{ color: theme.navigationSelectedColor }}
            />
          </button>
        </div>
      );
    }
    return null;
  };

  const renderChargerTable = () => {
    return (
      <div>
        <div className='flex flex-col'>
          {renderDropdown()}
          {numOfPills > 0 && (
            <div className='flex flex-row gap-2 items-baseline justify-between mt-2'>
              <div className='flex flex-row items-center gap-2 my-1.5 flex-wrap'>
                {renderStatusPills()}
                {renderLocationPills()}
                {renderFlagPills()}
                {renderSearchChargerPills()}
              </div>
              <div className='flex flex-row items-baseline min-w-fit'>
                {renderClearAllButton()}
              </div>
            </div>
          )}
          <ChargerGrid
            status={chargerListQuery.status}
            maintenanceFlag={chargerListQuery.maintenanceFlag}
            locationIds={chargerListQuery.locationId}
            searches={chargerListQuery.searches}
            page={chargerListQuery.page || 1}
            handleLoadPage={handleLoadPage}
            selectedChargers={selectedChargers}
            setSelectedChargers={setSelectedChargers}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <Card>
        <ChargersOverview />
        {renderChargerTable()}
      </Card>
    </div>
  );
};
