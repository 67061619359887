import { addHours } from 'date-fns';
import { forEach } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { ExternalLink } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { NUMBER } from '../../../constant/Number.constant';
import { getCurrentTheme } from '../../../stores/selectors/theme.selector';
import { formatDateTime } from '../../../utils/Date.Util';
import { ColorType, Icon, Label, LabelType, Skeleton } from '../../_ui';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../_ui/Tooltip.component';
import './message.css';

interface IProps {
  session: any;
  isLoading: boolean;
  messageClicked: string;
  searches: Array<string>;
}

const renderText = (text: string, highLightWord: any) => {
  const regex = new RegExp(`(${highLightWord})`, 'gi');
  const parts = text?.split(regex);
  let str = '';
  forEach(parts, (part) => {
    if (part.toLowerCase() === highLightWord?.toLowerCase()) {
      str += `<span style="background-color: #BBE7F6" text-sm text-black font-normal'>${part}</span>`;
    } else {
      str += `<span className='text-sm text-black font-normal'>${part}</span>`;
    }
  });
  return str;
};

const Message = ({ session, isLoading, searches, messageClicked }: IProps) => {
  const theme = useSelector(getCurrentTheme);
  const { t } = useTranslation();
  const [companyId] = useQueryParam('companyId', StringParam);

  if (isLoading) {
    return <Skeleton height='h-[18px]' width='w-full' />;
  }
  if (!session?.deviceId) {
    return null;
  }

  const { deviceId, receivedTimeUtc, message } = session;

  const receivedDate = new Date(`${receivedTimeUtc}`);
  const timezoneOffsetMinutes = receivedDate.getTimezoneOffset();
  const offsetHours = -timezoneOffsetMinutes / NUMBER.SIXTY; // Note the negative sign
  const convertedTime = addHours(receivedDate, offsetHours);

  const receivedISODate = formatDateTime(
    convertedTime,
    "yyyy-MM-dd'T'HH:mm:ss.SSSXXX",
  );

  const textMessage = (messages: string) => {
    if (searches.length > 0) {
      let str: any = messages;
      forEach(searches, (search: any) => {
        str = renderText(str, search.label);
      });
      return str;
    }
    return messages;
  };

  return (
    <div>
      <Tooltip
        open={message === messageClicked}
        placement='top'
        tipOffset={{
          mainAxis: 5,
          crossAxis: 0,
        }}
      >
        <TooltipTrigger>
          <div>
            <div
              className='message'
              id='messageText'
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: textMessage(message) }}
            />

            {message?.length > NUMBER.THREE_HUNDRED && (
              <Link
                className='flex items-center mt-[4px]'
                target='_blank'
                to={`/${deviceId}/${receivedISODate}/message?companyId=${companyId}`}
              >
                <Label
                  text={t('view_whole_message')}
                  type={LabelType.LABEL_XS}
                  color={ColorType.GREY4}
                  style={{ color: theme.navigationSelectedColor }}
                />
                <Icon
                  className='ml-[8px]'
                  src={ExternalLink}
                  color={theme.brand_2}
                  size={IconSize.SIZE_20x20}
                  alt={t('icon_alt_view_whole_message')}
                />
              </Link>
            )}
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <Label
            text={t('copied_message_to_clipboard')}
            type={LabelType.BODY3}
            color={ColorType.WHITE}
          />
        </TooltipContent>
      </Tooltip>
    </div>
  );
};

export default Message;
