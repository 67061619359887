import { identity, pickBy } from 'lodash';
import { useEffect } from 'react';
import { useLazyFetchStatisticsQuery } from '../../../services/history.api';
import { Statistics } from '../../../stores/types';
import { convertToLocaleCurrency } from '../../../utils/Currency.Util';
import { formatDate } from '../../../utils/Date.Util';
import { ColorType, Label, LabelType } from '../../_ui';
import ReportItem from './ReportItem.component';
import { PAYMENT_METHODS } from './ReportStats.component';

interface IProps {
  date: Date;
  className?: string;
  paymentLabel?: string;
  paymentMethods?: PAYMENT_METHODS;
  isShowTotal?: boolean;
  defaultCompanyCurrency?: string;
}
const ViewPayout = ({
  date,
  className,
  paymentLabel = '',
  paymentMethods,
  isShowTotal = false,
  defaultCompanyCurrency,
}: IProps) => {
  const [triggerFetchPayout, { stats, isStatsLoading }] =
    useLazyFetchStatisticsQuery({
      pollingInterval: 60000,
      selectFromResult: (endPoint) => ({
        stats: endPoint.data ? (endPoint.data[0] as Statistics) : undefined,
        isStatsLoading: endPoint.isFetching,
      }),
    });

  useEffect(() => {
    const params = {
      fromDate: formatDate(date, 'yyyy-MM'),
      toDate: formatDate(date, 'yyyy-MM'),
      currency: 'USD',
      paymentMethods,
    };
    triggerFetchPayout(pickBy(params, identity));
  }, [date, triggerFetchPayout, paymentMethods]);

  const handleFeesCollectedDisplay = () => {
    return stats
      ? convertToLocaleCurrency(stats.revenue, stats.revenueCurrency, 2)
      : convertToLocaleCurrency(0, defaultCompanyCurrency, 2);
  };

  return isShowTotal ? (
    <>
      <Label
        text={handleFeesCollectedDisplay()}
        type={LabelType.H2}
        color={ColorType.BLACK}
        className='inline'
        isLoading={isStatsLoading}
        skeletonHeight='h-10'
        skeletonWidth='w-40'
        dataTestId='fees-collected'
      />
      {!isStatsLoading && (
        <Label
          text={stats?.revenueCurrency || defaultCompanyCurrency}
          type={LabelType.LABEL_M_MEDIUM}
          color={ColorType.GREY5}
          className='inline ml-4'
        />
      )}
    </>
  ) : (
    <ReportItem
      className={className}
      name={paymentLabel}
      collection={handleFeesCollectedDisplay()}
      currency={stats?.revenueCurrency || defaultCompanyCurrency}
      isLoading={isStatsLoading}
    />
  );
};

export default ViewPayout;
