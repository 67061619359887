import { addHours, setHours, setMinutes, startOfDay } from 'date-fns';
import { memo, useEffect, useRef, useState } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  AddPlus,
  ArrowLeft,
  ArrowRight,
  RemoveMinus,
} from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { NUMBER } from '../../../constant/Number.constant';
import { useAuth } from '../../../hooks';
import {
  convertTimeStringTo24Hour,
  validateInputHours,
  validateInputMinutes,
} from '../../../utils/Date.Util';
import { padDigit } from '../../../utils/Number.Util';
import Divider from '../Divider.component';
import { Icon } from '../Icon.component';
import { FormInput, InputType, ValidationType } from '../Input.component';
import { Label, LabelType } from '../Label.component';
import { Select, SelectItem } from '../select/Select.component';
import { ColorType } from '../shared/Colors.g';

enum InputNames {
  HOURS = 'hours',
  MINUTES = 'minutes',
}

type SparkDateTimePickerProps = {
  onChange?: (date: any) => void;
  onClickOutSide?: any;
};

// eslint-disable-next-line react/display-name
export const SparkDateTimePicker = memo(
  ({ onChange, onClickOutSide }: SparkDateTimePickerProps) => {
    const auth = useAuth();
    const focusedInput = useRef('');
    const [dateRange, setDateRange] = useState([
      startOfDay(new Date()),
      addHours(startOfDay(new Date()), 1),
    ]);
    const [hourOptions] = useState<number[]>([1, 3]);
    const [amPmOptions] = useState<string[]>(['AM', 'PM']);
    const [selTimeData, setSelTimeData] = useState({
      hours: 0, // 0 index for select 1 hours option
      after: {
        hours: padDigit(12, 2),
        minutes: padDigit(0, 2),
        amPm: 0, // 0 index for the am options
      },
    });

    const onDateRangeChange = (date: any) => {
      const [hours, minutes] = convertTimeStringTo24Hour(
        `${selTimeData.after.hours}:${selTimeData.after.minutes} ${
          amPmOptions[selTimeData.after.amPm]
        }`,
      );

      const startDate = setMinutes(
        setHours(startOfDay(date), +hours),
        +minutes,
      );
      const endDate = addHours(startDate, hourOptions[selTimeData.hours]);

      setDateRange([startDate, endDate]);
      onChange && onChange([startDate, endDate]);
    };

    const handleTimeChange = (key: string, value: any) => {
      // for re-focus input after re-render
      focusedInput.current = key;
      setSelTimeData({
        ...selTimeData,
        after: { ...selTimeData.after, [key]: value },
      });
    };

    useEffect(() => {
      setTimeout(() => {
        document.getElementById(focusedInput.current)?.focus();
      }, NUMBER.ZERO);
    }, [selTimeData]);

    const handleHour = (isIncrement: boolean) => {
      if (isIncrement) {
        if (
          selTimeData.after.hours &&
          Number(selTimeData.after.hours) < NUMBER.MAX_HOURS
        ) {
          handleTimeChange(
            InputNames.HOURS,
            padDigit(Number(selTimeData.after.hours) + 1, 2),
          );
        } else if (selTimeData.after.hours) {
          handleTimeChange(InputNames.HOURS, padDigit(1, 2));
        }
      } else {
        if (selTimeData.after.hours && Number(selTimeData.after.hours) > 1) {
          handleTimeChange(
            InputNames.HOURS,
            padDigit(Number(selTimeData.after.hours) - 1, 2),
          );
        } else if (selTimeData.after.hours) {
          handleTimeChange(InputNames.HOURS, padDigit(12, 2));
        }
      }
    };

    const handleMinutes = (isIncrement: boolean) => {
      if (isIncrement) {
        if (
          selTimeData.after.minutes &&
          Number(selTimeData.after.minutes) + NUMBER.INCREMENT_INTERVAL <
            NUMBER.MAX_MINUTES
        ) {
          handleTimeChange(
            InputNames.MINUTES,
            padDigit(
              Number(selTimeData.after.minutes) + NUMBER.INCREMENT_INTERVAL,
              2,
            ),
          );
        } else if (selTimeData.after.minutes) {
          handleTimeChange(InputNames.MINUTES, padDigit(0, 2));
        }
      } else {
        if (
          selTimeData.after.minutes &&
          Number(selTimeData.after.minutes) - 15 > 1
        ) {
          handleTimeChange(
            InputNames.MINUTES,
            padDigit(Number(selTimeData.after.minutes) - 15, 2),
          );
        } else if (selTimeData.after.minutes) {
          handleTimeChange(InputNames.MINUTES, padDigit(0, 2));
        }
      }
    };

    useEffect(() => {
      // validate hours and minutes and calculate start and end date
      if (
        validateInputHours(selTimeData.after.hours) &&
        validateInputMinutes(selTimeData.after.minutes)
      ) {
        onDateRangeChange(dateRange[0]);
      }
    }, [selTimeData]);

    const DateContainer = ({
      className,
      children,
    }: {
      className: string;
      children: any;
    }) => {
      return (
        <CalendarContainer
          className={`react-datepicker_container rounded-lg shadow-[0_0_16px] shadow-grey2 flex flex-col ${className}`}
        >
          {children}
          <div className='flex flex-col gap-4 max-w-[320px] px-5 pb-5'>
            <Divider margin='0' />
            <Select
              fullWidthClassName='w-full'
              placement='bottom-start'
              defaultIndex={selTimeData.hours}
              onItemSelected={(val: any) => {
                focusedInput.current = '';
                setSelTimeData({ ...selTimeData, hours: val });
              }}
              buttonProps={{
                labelType: LabelType.BODY3,
                className: 'min-w-full',
                buttonStyle: {
                  height: '40px',
                  className: 'w-full',
                },
              }}
            >
              {hourOptions.map((hour: number) => {
                return (
                  <SelectItem
                    key={hour}
                    label={`${hour} hour`}
                    labelType={LabelType.BODY3}
                  />
                );
              })}
            </Select>
            <div className='flex flex-row items-center gap-2'>
              <Label
                text='After'
                type={LabelType.LABEL_S}
                color={ColorType.GREY6}
              />
              <div className='flex flex-col gap-2 justify-center items-center'>
                <Icon
                  src={AddPlus}
                  size={IconSize.SIZE_16x16}
                  onClick={() => handleHour(true)}
                />
                <FormInput
                  inputId={InputNames.HOURS}
                  key='hours-picker'
                  inputClass='text-center'
                  defaultValue={selTimeData.after.hours}
                  inputType={InputType.NUMBER}
                  validationType={ValidationType.HOURS_12}
                  placeholder='12'
                  onChange={(event: any) => {
                    handleTimeChange(InputNames.HOURS, event.target.value);
                  }}
                  width='50px'
                  maxLen='2'
                />
                <Icon
                  src={RemoveMinus}
                  size={IconSize.SIZE_16x16}
                  onClick={() => handleHour(false)}
                />
              </div>

              <Label
                text=':'
                type={LabelType.LABEL_S}
                color={ColorType.GREY6}
                className='w-[5px]'
              />
              <div className='flex flex-col gap-2 justify-center items-center'>
                <Icon
                  src={AddPlus}
                  size={IconSize.SIZE_16x16}
                  onClick={() => handleMinutes(true)}
                />
                <FormInput
                  inputId={InputNames.MINUTES}
                  key='minute-picker'
                  inputClass='text-center'
                  defaultValue={selTimeData.after.minutes}
                  inputType={InputType.NUMBER}
                  validationType={ValidationType.MINS}
                  placeholder='00'
                  onChange={(event: any) =>
                    handleTimeChange(InputNames.MINUTES, event.target.value)
                  }
                  width='50px'
                  maxLen='2'
                />
                <Icon
                  src={RemoveMinus}
                  size={IconSize.SIZE_16x16}
                  onClick={() => handleMinutes(false)}
                />
              </div>

              <Select
                fullWidthClassName='w-[66px]'
                placement='top'
                defaultIndex={selTimeData.after.amPm}
                contentWidth={75}
                onItemSelected={(val: any) => {
                  focusedInput.current = '';
                  setSelTimeData({
                    ...selTimeData,
                    after: { ...selTimeData.after, amPm: val },
                  });
                }}
                buttonProps={{
                  labelType: LabelType.BODY3,
                  className: 'gap-0',
                  buttonStyle: {
                    height: '40px',
                    width: '75px',
                    padding: '10px 12px',
                  },
                }}
              >
                {amPmOptions.map((ampm: string) => {
                  return (
                    <SelectItem
                      key={ampm}
                      label={ampm}
                      labelType={LabelType.BODY3}
                    />
                  );
                })}
              </Select>
            </div>
          </div>
        </CalendarContainer>
      );
    };

    const renderArrow = (arrow: any) => {
      return <Icon src={arrow} size={IconSize.SIZE_20x20} />;
    };

    return (
      <DatePicker
        popperPlacement='top-start'
        selected={dateRange[0]}
        locale={auth.user.attributes.profile?.sitehostDashboardLanguage}
        onChange={(date: any) => onDateRangeChange(date)}
        inline
        useWeekdaysShort
        previousMonthButtonLabel={renderArrow(ArrowLeft)}
        nextMonthButtonLabel={renderArrow(ArrowRight)}
        onClickOutside={onClickOutSide}
        calendarContainer={DateContainer}
      />
    );
  },
);
