import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazy-load';
import SvgColor from 'react-svg-color';
import { IconSize } from '../../constant/IconSize.constant';
import { ColorType, getHexColorByType, isHexColor } from './shared/Colors.g';
import { IconDimension } from './shared/interface/IconDimension.interface';

export interface IconProps {
  src?: string;
  size?: IconDimension;
  color?: ColorType | string;
  className?: string;
  onClick?: Function;
  style?: any;
  disabled?: boolean;
  dataTestId?: string;
  lazyLoad?: boolean;
  alt?: string;
  isTranslationOn?: boolean;
}

export const Icon = memo(
  ({
    src,
    size = IconSize.SIZE_24x24,
    color,
    className,
    onClick,
    style = {},
    disabled = false,
    dataTestId,
    lazyLoad = false,
    isTranslationOn = false,
    alt = '',
  }: IconProps) => {
    const { t } = useTranslation();
    const { width, height } = size;
    const handleClick = (event: any) => {
      !disabled && onClick && onClick(event);
    };
    const svgColor = isHexColor(color)
      ? color
      : getHexColorByType(color as ColorType);

    return (
      <div
        className={`${
          onClick &&
          !disabled &&
          'cursor-pointer hover:bg-grey4 items-center justify-center'
        } ${className}`}
        onClick={handleClick}
        style={{
          ...style,
          width: `${width}px`,
          height: `${height}px`,
          flexShrink: 0,
        }}
        data-testid={dataTestId}
      >
        {lazyLoad ? (
          <LazyLoad height={height} threshold={0.95}>
            <SvgColor
              width={width}
              svg={src}
              colors={[svgColor, svgColor]}
              alt={isTranslationOn ? t(alt) : alt}
            />
          </LazyLoad>
        ) : (
          <SvgColor
            width={width}
            svg={src}
            colors={[svgColor, svgColor]}
            alt={isTranslationOn ? t(alt) : alt}
          />
        )}
      </div>
    );
  },
);

Icon.displayName = 'Icon';
