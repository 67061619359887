import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../hooks';
import { useLazyFetchLocationsQuery } from '../../services/location.api';
import { useLazyGetCircuitsQuery } from '../../services/pm.api';
import { SCOPE_TYPE } from '../../services/utils';
import {
  Circuit,
  DropdownOption,
  GetCircuitsApiArg,
  ParentBreakerOptions,
} from '../../stores/types/pm.interface';
import {
  Card,
  ColorType,
  Label,
  LabelType,
  MODAL_TYPES,
  Tab,
  Tabs,
  getHexColorByType,
  useGlobalModalContext,
} from '../_ui';
import { ActionWarningModal } from './ActionWarningModal';
import { LocationWithPower } from './LocationWithPower.component';
import { LocationWoPowerManagement } from './LocationWithoutPower/LocationWoPowerManagement.component';
import { NoPowerManaged } from './NoPowerManaged';
import { circuitBreakerOptions, sortBreakers, sortLocations } from './utils';

enum TABS {
  LOCATION_WITH_POWER = 0,
  LOCATION_WO_POWER,
}

export const Power = () => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const auth = useAuth();

  const [selectedTab, setSelectedTab] = useState<number>(
    TABS.LOCATION_WITH_POWER,
  );

  const [
    triggerFetchLocationByCompanyId,
    { data: unsortedLocations, isFetching },
  ] = useLazyFetchLocationsQuery();

  const locations = useMemo(() => {
    return sortLocations(unsortedLocations?.entities || []);
  }, [unsortedLocations?.entities]);

  const [
    triggerGetCircuits,
    {
      data: circuitsData,
      isLoading: isSiteSummariesLoading,
      isFetching: isSiteSummariesFetching,
    },
  ] = useLazyGetCircuitsQuery({ pollingInterval: 15000 });

  // useMemo instead selectFromResult can avoid extra refresh.
  const siteSummaries = useMemo(() => {
    if (circuitsData?.entities) {
      const siteSummary = [...circuitsData.entities];
      // sorting siteSummary based on sorted locations
      siteSummary.sort((a, b) => {
        return locations.findIndex((l) => l.id === a.locationId) >
          locations.findIndex((l) => l.id === b.locationId)
          ? 1
          : -1;
      });
      return {
        totalCount: circuitsData.totalCount,
        entities: siteSummary,
      };
    }
    return {
      totalCount: 0,
      entities: [],
    };
  }, [circuitsData?.entities, circuitsData?.totalCount, locations]);

  useEffect(() => {
    setSelectedTab(TABS.LOCATION_WITH_POWER);
  }, [siteSummaries]);

  useEffect(() => {
    triggerFetchLocationByCompanyId({ scope: SCOPE_TYPE.COMPANY });
  }, []);

  // locations without power management
  const locationsWithoutPM = useMemo(() => {
    let result: string[] = [];
    if (locations?.length > 0) {
      const managedLocations = new Set();
      siteSummaries.entities.forEach((siteSummary) => {
        managedLocations.add(siteSummary.locationId);
      });
      result = locations
        .filter((location) => {
          return !managedLocations.has(location.id);
        })
        .map((filteredLocation) => filteredLocation.id);
    }
    return result;
  }, [siteSummaries, locations]);

  useEffect(() => {
    if (!isFetching && locations?.length > 0) {
      const locationQuery = locations.map((location) => location.id).join(',');
      const circuitsQuery = {
        expandChildren: 'all',
        filter: {
          locationId: { in: locationQuery },
        },
      } as GetCircuitsApiArg;
      triggerGetCircuits(circuitsQuery);
    }
  }, [isFetching, locations, triggerGetCircuits]);

  const locationCircuitMap = useMemo(() => {
    // Creates map with locationId as key, Array of circuits for that location as value
    // Need to consolidate all circuit for a location as GET /circuit response doesn't
    const map = new Map<string, Circuit[]>();
    siteSummaries?.entities?.forEach((circuit) => {
      if (map.has(circuit.locationId)) {
        map.get(circuit.locationId)?.push(circuit);
      } else {
        map.set(circuit.locationId, [circuit]);
      }
    });

    const response: [string, Circuit[]][] = Array.from(map).map((item) => [
      item[0],
      sortBreakers(item[1]),
    ]);
    return response;
  }, [siteSummaries]);

  // Parent breaker dropdown options for sub-breaker and breaker load
  const parentBreakerData = useMemo(() => {
    const dropdownOptions: any = {};
    if (locationCircuitMap?.length) {
      locationCircuitMap.forEach((locationData: [string, Circuit[]]) => {
        const locationId = locationData[0] ?? '';
        locationData[1].forEach((circuitData) => {
          const circuitOptions: DropdownOption[] = circuitBreakerOptions(
            circuitData.mainBreakers || [],
            [],
          );
          if (dropdownOptions[locationId]) {
            dropdownOptions[locationId] = [
              ...dropdownOptions[locationId],
              ...(circuitOptions ?? []),
            ];
          } else {
            dropdownOptions[locationId] = circuitOptions;
          }
        });
      });
    }
    return dropdownOptions as ParentBreakerOptions;
  }, [locationCircuitMap]);

  const LabelSkeleton = ({ width, height }: any) => {
    return (
      <div className='flex flex-row pb-2'>
        <Label
          text=''
          type={LabelType.H4}
          color={ColorType.BLACK}
          skeletonLoadingColor='bg-grey2'
          isLoading
          skeletonWidth={width}
          skeletonHeight={height}
        />
      </div>
    );
  };

  const renderLocationSkeleton = () => {
    return (
      <Card className='mb-2 h-104'>
        <div className='flex flex-col'>
          <LabelSkeleton width='w-72' height='h-5' />
          <LabelSkeleton width='w-96' height='h-5' />
          <div
            className='px-4 py-4 mt-4 mb-6 shadow-[0_4px_16px_0px_rgba(0,0,0,0.1)]'
            style={{
              borderRadius: '8px',
              border: '1px solid #D1D6DB',
              background: getHexColorByType(ColorType.WHITE),
            }}
          >
            <LabelSkeleton width='w-72' height='h-5' />
            <LabelSkeleton width='w-96' height='h-5' />
            {Array.from({ length: 3 }, (_, index) => (
              <div
                key={index}
                className='px-6 pt-2'
                style={{
                  borderBottom: '1px solid #D1D6DB',
                  borderTop: '1px solid #D1D6DB',
                }}
              >
                <LabelSkeleton width='w-72' height='h-5' />
                <LabelSkeleton width='w-96' height='h-5' />
              </div>
            ))}
          </div>
          <LabelSkeleton width='w-36' height='h-5' />
          <LabelSkeleton width='w-96' height='h-5' />
        </div>
      </Card>
    );
  };

  const showEditWarning = (locationId: string, callbackFunction: Function) => {
    const acceptStatus = localStorage.getItem('showEditWarning');
    if (!acceptStatus || auth?.user?.username === 'test-user-01@chargelab.co') {
      callbackFunction(true);
      return;
    }

    const locationName = locations.filter((loc: any) => loc.id === locationId);

    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('pm_menu_action_warning_modal_title'),
      width: '540px',
      height: 'max-content',
      shouldCloseOnOverlayClick: false,
      onRenderBody: () => (
        <ActionWarningModal
          locationName={locationName[0].name || ''}
          callbackFunction={callbackFunction}
        />
      ),
    });
  };

  // Skeleton will only load the very first time data is fetched. It will not load when
  // any breaker is updated
  if (!circuitsData) {
    return renderLocationSkeleton();
  }

  if (locations?.length > 0) {
    if (locations.length - locationsWithoutPM.length > 0) {
      return (
        <>
          <div className='flex flex-row justify-between mb-6'>
            <Tabs onTabChange={setSelectedTab}>
              <Tab
                label={t('locationWithPM', {
                  count: locations.length - (locationsWithoutPM?.length || 0),
                })}
                dataTestId='pmWithLocationTab'
              />
              <Tab
                label={t('locationWoPM', {
                  count: locationsWithoutPM?.length,
                })}
                dataTestId='pmWithoutLocationTab'
              />
            </Tabs>
          </div>

          {selectedTab === TABS.LOCATION_WITH_POWER ? (
            <LocationWithPower
              data={locationCircuitMap}
              location={locations}
              parentBreakers={parentBreakerData}
              showWarning={showEditWarning}
            />
          ) : (
            <LocationWoPowerManagement
              data={locationsWithoutPM}
              locations={locations}
              showWarning={showEditWarning}
            />
          )}
        </>
      );
    }
    if (!isSiteSummariesFetching) {
      return (
        <LocationWoPowerManagement
          data={locationsWithoutPM}
          locations={locations}
          showWarning={showEditWarning}
        />
      );
    }
    return null;
  }
  return <NoPowerManaged isWithLocation={false} />;
};
