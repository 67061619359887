import { useTranslation } from 'react-i18next';

import { CompanyBuilding, More, User } from '../../assets/icons';
import { Charger } from '../../stores/types';
import { LocationWithChargers } from '../../stores/types/access/locationWithChargers.interface';
import { UserGroupType } from '../../stores/types/access/userGroupType.enum';
import {
  ColorType,
  Label,
  LabelType,
  Menu,
  MenuItem,
  Pill,
  getHexColorByType,
} from '../_ui';

interface PrivateChargerWithLocationProp {
  value: LocationWithChargers[];
  chargersTotalCountByLocation: any;
  mapKey: string;
  handleEditClick: any;
  handleDeleteAccess: any;
  autoStartEnabled: boolean;
}

const PrivateChargerWithLocation = ({
  value,
  chargersTotalCountByLocation,
  mapKey,
  handleEditClick,
  handleDeleteAccess,
  autoStartEnabled,
}: PrivateChargerWithLocationProp) => {
  const { t } = useTranslation();

  return (
    <div
      data-testid='private-charger-list'
      className={`grid grid-cols-2 border-b border-grey2 ${
        autoStartEnabled ? 'bg-grey0' : ''
      }`}
      key={mapKey}
    >
      <div className='pt-2'>
        {value.map((cw: any) => {
          return (
            <div key={cw.locationName}>
              <div className='items-center px-2 py-0'>
                <div className='flex flex-row gap-2'>
                  <Label
                    text={cw.locationName}
                    type={LabelType.LABEL_S_MEDIUM}
                    color={ColorType.BLACK}
                  />
                  <Label
                    text={`(${cw.chargers.length}/${
                      chargersTotalCountByLocation[cw.locationName]
                    })`}
                    type={LabelType.LABEL_S}
                    color={ColorType.GREY5}
                  />
                </div>
              </div>
              <div className='flex flex-row gap-1 py-2 px-2 flex-wrap'>
                {cw.chargers.map((charger: Charger) => {
                  return (
                    <Pill
                      key={charger.name}
                      label={charger.name}
                      labelColor={ColorType.GREY6}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className='flex flex-row'>
        <div>
          {autoStartEnabled && (
            <span className='py-2 px-2 text-grey6'>
              {t('access_restriction_auto_start_enabled')}
            </span>
          )}
          <div className='flex flex-row gap-1 py-2 px-2 flex-wrap'>
            {JSON.parse(mapKey).accessRules.map((accessRule: any) => {
              return accessRule.userGroups.map((userGroup: any) => {
                return (
                  <Pill
                    key={userGroup.userGroupSelector?.value}
                    iconLeft={
                      userGroup.userGroupSelector?.type ===
                      UserGroupType[UserGroupType.INDIVIDUAL]
                        ? User
                        : CompanyBuilding
                    }
                    iconColor={
                      autoStartEnabled
                        ? getHexColorByType(ColorType.GREY4)
                        : getHexColorByType(ColorType.GREY6)
                    }
                    label={userGroup.userGroupSelector?.value}
                    labelColor={
                      autoStartEnabled ? ColorType.GREY4 : ColorType.GREY6
                    }
                    dataTestId={userGroup.userGroupSelector?.value}
                  />
                );
              });
            })}
          </div>
        </div>
        {!autoStartEnabled && (
          <div className='flex pt-2 ml-auto'>
            <Menu
              placement='bottom-end'
              icon={More}
              dataTestId='access-select-menu'
              iconAlt={t('icon_alt_more_options')}
            >
              <MenuItem
                label={t('access_edit_group_label')}
                onClick={() =>
                  handleEditClick(
                    value.flatMap((val) => val.chargers.map((x) => x.id)),
                    JSON.parse(mapKey).accessRules,
                  )
                }
                dataTestId='edit-access-rule'
              />
              <MenuItem
                label={t('access_delete_group_label')}
                labelType={LabelType.BODY3}
                labelColor={ColorType.NEGATIVE1}
                onClick={() =>
                  handleDeleteAccess(
                    value.flatMap((val) => val.chargers.map((x) => x.id)),
                  )
                }
                dataTestId='delete-access-rule'
              />
            </Menu>
          </div>
        )}
      </div>
    </div>
  );
};

export default PrivateChargerWithLocation;
