import { identity, pickBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bolt,
  ChargerEV,
  ErrorWarningtraiangle,
  More,
} from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { NUMBER } from '../../../constant/Number.constant';
import {
  useDeleteTerminalsMutation,
  useFetchCombinedTerminalsQuery,
} from '../../../services/payouts.api';
import { TerminalObj } from '../../../stores/types/payter.interface';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Grid,
  Icon,
  Label,
  LabelType,
  MODAL_TYPES,
  Menu,
  MenuItem,
  useGlobalModalContext,
} from '../../_ui';
import ConnectedChargers from './ConnectedChargers.component';
import SetupTerminal from './SetupTerminal.component';
import { sortTerminals } from './utils';

const TerminalTable = () => {
  const { t } = useTranslation();
  const { showModal, hideModal } = useGlobalModalContext();

  const [currentTerminalPage, setCurrentTerminalPage] = useState(NUMBER.ONE);
  const MESSAGES_PER_ROW = NUMBER.TEN;
  const offsetIndex = (currentTerminalPage - 1) * MESSAGES_PER_ROW;

  const dataObj = useMemo(() => {
    const obj = {
      limit: MESSAGES_PER_ROW,
      offset: offsetIndex,
    };

    return pickBy(obj, identity);
  }, [offsetIndex, MESSAGES_PER_ROW]);

  const {
    data: terminalData,
    isLoading: isTerminalLoading,
    isFetching: isTerminalFetching,
  } = useFetchCombinedTerminalsQuery(dataObj);

  const isTerminalDataLoading = useMemo(() => {
    return isTerminalFetching || isTerminalLoading;
  }, [isTerminalFetching, isTerminalLoading]);

  const handleResetTerminal = (row: TerminalObj) => {
    showModal(MODAL_TYPES.ALERT_MODAL, {
      height: 'max-content',
      title: t('are_you_sure'),
      icon: ErrorWarningtraiangle,
      iconColor: ColorType.NEGATIVE1,
      message: (
        <>
          <Label
            text={t('payter_reset_warn_msg1')}
            type={LabelType.BODY3}
            color={ColorType.GREY6}
          />
          <Label
            className='mt-2'
            text={t('payter_reset_warn_msg2')}
            type={LabelType.BODY3}
            color={ColorType.GREY6}
          />
        </>
      ),
      buttons: [
        {
          label: t('cancel'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
        },
        {
          label: t('reset_terminal'),
          type: ButtonType.DESTRUCTIVE,
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
          isSubmit: true,
        },
      ],
      mutationHook: useDeleteTerminalsMutation,
      mutationParams: {
        body: {
          terminalId: row.id,
        },
        toast: {
          successTrans: 'successfully_reset_terminal',
          failedTrans: 'failed_to_reset_terminal',
        },
      },
      onClose: hideModal,
      shouldCloseOnOverlayClick: false,
    });
  };

  const handleSetupTerminal = (terminal: TerminalObj, isEdit = false) => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: isEdit ? t('edit_terminal') : t('setup_payter_terminal'),
      overflow: 'auto',
      width: '540px',
      height: 'fit-content',
      onRenderBody: () => (
        <SetupTerminal
          terminalsList={terminalData?.entities}
          terminal={terminal}
          hideModal={hideModal}
          isEdit={isEdit}
        />
      ),
      shouldCloseOnOverlayClick: false,
    });
  };

  const columnsSettings = [
    {
      key: 'serialNumber',
      title: t('terminal'),
      component: (row: TerminalObj) => {
        return (
          <Label
            isLoading={isTerminalDataLoading}
            text={row.serialNumber}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        );
      },
    },
    {
      key: 'associatedChargers',
      title: t('linked_charger'),
      component: (row: TerminalObj) => (
        <ConnectedChargers
          connectedChargerIds={row.associatedChargers}
          isTerminalDataLoading={isTerminalDataLoading}
        />
      ),
    },
    {
      key: 'action',
      title: '',
      component: (row: TerminalObj) => {
        return (
          !isTerminalDataLoading && (
            <div className='flex justify-end items-start'>
              {row?.id ? (
                <Menu
                  placement='bottom-end'
                  icon={More}
                  iconAlt={t('icon_alt_more_options')}
                >
                  <MenuItem
                    label={t('edit_linked_charger')}
                    dataTestId='edit-terminal'
                    onClick={() => handleSetupTerminal(row, true)}
                  />
                  <MenuItem
                    label={t('reset_terminal')}
                    onClick={() => handleResetTerminal(row)}
                    dataTestId='reset_terminal'
                    labelColor={ColorType.NEGATIVE1}
                  />
                </Menu>
              ) : (
                <Button
                  icon={ChargerEV}
                  label={t('link')}
                  type={ButtonType.OUTLINE}
                  onClick={() => handleSetupTerminal(row)}
                />
              )}
            </div>
          )
        );
      },
    },
  ];

  return isTerminalDataLoading || terminalData?.entities.length ? (
    <Grid
      local={false}
      columns={columnsSettings}
      data={terminalData?.entities && sortTerminals(terminalData?.entities)}
      primaryKey='serialNumber'
      isLoading={isTerminalDataLoading}
      hasRowBottomBorder
      verticalAlign='align-baseline'
      loadPage={(page) => setCurrentTerminalPage(page)}
      totalPage={Math.ceil((terminalData?.totalCount || 0) / MESSAGES_PER_ROW)}
    />
  ) : (
    <div className='flex flex-col w-full pt-4 pb-4 items-center'>
      <Icon src={Bolt} size={IconSize.SIZE_48x48} />
      <Label
        text={t('no_terminal_added')}
        type={LabelType.H4}
        color={ColorType.GREY5}
      />
      <Label
        text={t('linked_terminal_will_be_shown_here')}
        type={LabelType.BODY2}
        color={ColorType.GREY5}
      />
    </div>
  );
};

export default TerminalTable;
