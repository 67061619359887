import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Icon,
  Label,
  LabelType,
  ToggleGroup,
  ToggleItem,
} from '..';
import { ExitDeleteRemoveClose } from '../../../assets/icons';
import { useAuth } from '../../../hooks';
import { LANGUAGES } from '../../../services/translation/i18n';
import { useGlobalModalContext } from './GlobalModal.component';
import { MODAL_LAYER_INDEX } from './utils';

Modal.setAppElement('#root');

export const LanguageModal = () => {
  const { hideModal, store } = useGlobalModalContext();
  const { t } = useTranslation();
  const auth = useAuth();
  const [selectedLanuage, setSelectedLanguage] = useState(
    auth.user.attributes.profile?.sitehostDashboardLanguage,
  );

  const { modalProps } = store;
  const {
    title = t('profile_select_a_language'),
    width = '380px',
    height = '316px',
    onRenderFooter,
  } = modalProps;

  const handleClose = () => {
    hideModal();
  };
  const handleSave = () => {
    auth.updateUserLanguage(selectedLanuage);
    hideModal();
  };

  const handleSwitchLang = (lang: string) => {
    setSelectedLanguage(lang);
  };

  const renderHeader = () => {
    return (
      <div className='flex items-center justify-center sticky top-0 h-16'>
        <Icon
          src={ExitDeleteRemoveClose}
          className='w-[28px] h-[28px] hover:bg-grey2 rounded-full flex items-center justify-center  absolute left-2 cursor-pointer'
          onClick={handleClose}
        />
        <Label text={title} type={LabelType.H5} color={ColorType.BLACK} />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className='block mt-4'>
        <div className='flex flex-col gap-4 w-full'>
          <ToggleGroup
            defaultOption={selectedLanuage}
            onOptionChange={(option) => handleSwitchLang(option)}
            className='gap-4'
          >
            {LANGUAGES.map((lang) => (
              <ToggleItem
                key={lang}
                className='h-[40px]'
                label={t(lang)}
                option={lang}
                dataTestId={lang}
              />
            ))}
          </ToggleGroup>
          <div className='h-[40px]'>
            <Button
              label={t('save')}
              type={ButtonType.PRIMARY}
              size={ButtonSize.SMALL_FULL}
              onClick={handleSave}
              dataTestId='saveLanguage'
            />
          </div>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return <div>{onRenderFooter && onRenderFooter()}</div>;
  };

  return (
    <Modal
      isOpen
      onRequestClose={handleClose}
      style={{
        content: {
          top: '50%',
          left: '50%',
          width,
          height,
          transform: 'translate(-50%, -50%)',
          padding: 0,
          borderRadius: 10,
          overflow: 'hidden',
        },
        overlay: {
          zIndex: MODAL_LAYER_INDEX,
          backgroundColor: 'rgba(32,34,35,0.5)',
        },
      }}
    >
      <div className='flex flex-col px-4'>
        {renderHeader()}
        {renderBody()}
        {renderFooter()}
      </div>
    </Modal>
  );
};
