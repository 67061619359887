import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Bolt,
  ErrorWarningtraiangle,
  Info,
  More,
  ocppStatusIcon,
  Play,
  Reset,
  Stop,
} from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { CHARGER_STATUS, OCPP_STATUS } from '../../../constant/Text.constant';
import { URL_SEARCH_PARAM_KEY } from '../../../constant/UrlSearchParams.constant';
import { useAuth, USER_ROLE } from '../../../hooks';
import { useCompany } from '../../../hooks/useCompany';
import { useAllowedFeatures } from '../../../hooks/useFeaturePersonalization';
import { RoutePath } from '../../../routes';
import {
  useDeleteChargerMutation,
  useRebootChargerMutation,
} from '../../../services/charger.api';
import { useLazyFetchCompanyDetailQuery } from '../../../services/company.api';
import { useClearProfileMutation } from '../../../services/pm.api';
import {
  useLazyFetchSessionsQuery,
  useSessionStartChargingMutation,
  useSessionStopChargingMutation,
} from '../../../services/session.api';
import { getWebAppUrlPrefix } from '../../../stores/selectors/deployment.selector';
import { getCurrentTheme } from '../../../stores/selectors/theme.selector';
import { Charger } from '../../../stores/types';
import { ChargerPort, Session } from '../../../stores/types/sessions.interface';
import {
  convertToDate,
  getDifferenceInMinutes,
} from '../../../utils/Date.Util';
import { addSearchParam } from '../../../utils/SearchParam.Utils';
import {
  Button,
  ButtonSize,
  ButtonType,
  Card,
  ColorType,
  Divider,
  Icon,
  Label,
  LabelType,
  Menu,
  MenuItem,
  MODAL_TYPES,
  Skeleton,
  useGlobalModalContext,
} from '../../_ui';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../_ui/Tooltip.component';
import { handleTimeCalculation } from '../../Session/utils';
import { ChargerStatus } from '../ChargerStatus.component';
import ConnectorRowItem from '../ConnectorRowItem.component';
import {
  ChargerCompany,
  ChargerConfirmation,
  ChargerSettings,
} from '../move-charger';
import { getConnectorIcon, getValidRawStatus } from '../utils';
import { ViewQRCode } from '../ViewQRCode.component';
import { ChargerError } from './ChargerError.component';
import { SparkAnalyze } from './SparkAnalyze.component';

type ChargerSummeryProps = {
  charger: Charger;
  isLoading: boolean;
};

export const ChargerSummery = ({ charger, isLoading }: ChargerSummeryProps) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const theme = useSelector(getCurrentTheme);
  const { allowSparkAnalyze } = useAllowedFeatures();
  const { chargerId: id } = useParams();
  const { showModal } = useGlobalModalContext();
  const { switchCompany, userScope, isMultiViewActive } = useCompany();
  const [rebootCharger] = useRebootChargerMutation();
  const [sessionStart] = useSessionStartChargingMutation();
  const [sessionStop] = useSessionStopChargingMutation();
  const [chargerSessions, setChargerSessions] = useState<Session[]>([]);
  const [triggerFetchCompanyDetail, { data: company }] =
    useLazyFetchCompanyDetailQuery();
  const [triggerFetchSessions, { data: sessionData }] =
    useLazyFetchSessionsQuery();

  const webAppUrlPrefix = useSelector(getWebAppUrlPrefix);

  const isAnyPortCharging = useMemo(() => {
    return charger?.ports?.some((port) => port?.status === 'CHARGING');
  }, [charger]);

  const companyId = useMemo(() => {
    return charger?.ownerId;
  }, [charger]);
  useEffect(() => {
    if (sessionData) {
        const sessions = sessionData.entities.filter(
           (session) => !session.stopTime && !session.completeTime,
          );
        setChargerSessions(sessions);
      }
  }, [sessionData]);

  const getIsSinglePort = () => {
    return (charger?.ports?.length || 0) <= 1;
  };

  const handleViewChargerClick = () => {
    window.open(`${webAppUrlPrefix}/${charger?.name}`);
  };

  const handleViewQRCodeClick = () => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('view_qr_code'),
      width: '540px',
      height: 'max-content',
      shouldCloseOnOverlayClick: false,
      onRenderBody: () => (
        <ViewQRCode
          chargerName={charger?.name || ''}
          webAppUrl={webAppUrlPrefix}
        />
      ),
    });
  };

  const handleChargerMove = () => {
    showModal(MODAL_TYPES.WIZARD_MODAL, {
      width: '540px',
      height: 'max-content',
      defaultOverflow: 'auto',
      stepTitles: [
        t('charger_move_step_1_title'),
        t('charger_move_step_2_title'),
        t('charger_move_step_3_title'),
      ],
      stepComponents: [
        // eslint-disable-next-line react/jsx-key
        <ChargerCompany chargerInfo={charger} companyInfo={company} />,
        // eslint-disable-next-line react/jsx-key
        <ChargerSettings />,
        // eslint-disable-next-line react/jsx-key
        <ChargerConfirmation />,
      ],
      shouldCloseOnOverlayClick: false,
      onClose: () => {
        switchCompany(companyId!, RoutePath.CHARGERS);
      },
    });
  };

  const showClearProfileAlert = () => {
    showModal(MODAL_TYPES.ALERT_MODAL, {
      height: 'max-content',
      title: t('are_you_sure'),
      message: (
        <Trans
          i18nKey='pm_clear_profile_desc'
          components={{
            br: <br />,
          }}
        />
      ),
      mutationHook: useClearProfileMutation,
      mutationParams: {
        scope: 'all',
        chargerId: charger.id,
      },
      icon: ErrorWarningtraiangle,
      iconColor: ColorType.NEGATIVE1,
      buttons: [
        {
          label: t('cancel'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
        },
        {
          label: t('clear_profile'),
          type: ButtonType.DESTRUCTIVE,
          size: ButtonSize.SMALL,
          isSubmit: true,
        },
      ],
      shouldCloseOnOverlayClick: false,
    });
  };

  const handleDeleteCharger = () => {
    showModal(MODAL_TYPES.ALERT_MODAL, {
      height: 'max-content',
      title: t('charger_delete_warn_title'),
      icon: ErrorWarningtraiangle,
      iconColor: ColorType.NEGATIVE1,
      message: (
        <Trans
          i18nKey={t('charger_delete_warn_message')}
          components={{
            bold: <strong />,
          }}
        />
      ),
      mutationHook: useDeleteChargerMutation,
      mutationParams: {
        id,
        name: charger?.name,
        scope: userScope!,
        companyId: charger.ownerId!,
      },
      buttons: [
        {
          label: t('cancel'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
        },
        {
          label: t('delete'),
          type: ButtonType.DESTRUCTIVE,
          size: ButtonSize.SMALL,
          disableDelayTime: 3000,
          disableDelayLabel: t('wait_3_sec'),
          className: 'min-w-fit w-20',
          isSubmit: true,
          dataTestId: 'delete-charger-button',
        },
      ],
      onClose: (qResult: any) => {
        if (qResult?.isSuccess) {
          switchCompany(companyId!, RoutePath.CHARGERS);
        }
      },
      shouldCloseOnOverlayClick: false,
    });
  };

  const handleRefresh = () => {
    window.location.replace(
      `${addSearchParam(
        `../${RoutePath.CHARGERS}/${id}`,
        URL_SEARCH_PARAM_KEY.COMPANY_ID,
        isMultiViewActive ? 'ALL' : companyId || '',
      )}`,
    );
  };

  const showSpinnerAlert = () => {
    showModal(MODAL_TYPES.SPINNER_MODAL, {
      delay: 5000,
      onClose: handleRefresh,
    });
  };

  const onRebootClick = (chargerId?: string) => {
    if (chargerId) {
      rebootCharger({
        id: chargerId,
        scope: userScope!,
        companyId: charger.ownerId!,
      });
      showSpinnerAlert();
    }
  };

  const handleRebootClick = () => {
    const title = t('charer_reboot_charger');
    showModal(MODAL_TYPES.ALERT_MODAL, {
      title,
      message: t('charger_reboot_msg'),
      icon: Info,
      iconColor: ColorType.WARNING1,
      buttons: [
        {
          label: t('cancel'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
        },
        {
          label: t('reset'),
          type: ButtonType.PRIMARY,
          style: { backgroundColor: theme.navigationSelectedColor },
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
          onClick: () => onRebootClick(charger?.id),
        },
      ],
    });
  };

  const onStartClick = (portId?: string) => {
    portId &&
      sessionStart({ portId, scope: userScope!, companyId: charger.ownerId! });
    showSpinnerAlert();
  };

  const onStopClick = (portId?: string) => {
    const sessionId = chargerSessions?.find(
      (session : Session) => session.port.id === portId,
    )?.id;

    sessionId &&
      sessionStop({
        sessionId,
        scope: userScope!,
        companyId: charger.ownerId!,
      });
    showSpinnerAlert();
  };

  const handleStartClick = (portId: string, portPhysicalLocation: string) => {
    const title = t('charger_start_charging');
    showModal(MODAL_TYPES.ALERT_MODAL, {
      height: 'max-content',
      title,
      message: getIsSinglePort() ? (
        t('charger_start_msg')
      ) : (
        <Trans
          i18nKey='multiple_port_charger_start_msg'
          values={{
            physicalLocation: t(`${portPhysicalLocation.toLowerCase()}`),
          }}
        />
      ),
      icon: Info,
      buttons: [
        {
          label: t('cancel'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
        },
        {
          label: t('start'),
          type: ButtonType.PRIMARY,
          style: { backgroundColor: theme.navigationSelectedColor },
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
          onClick: () => onStartClick(portId),
        },
      ],
    });
  };

  const handleStopClick = (portId: string, portPhysicalLocation: string) => {
    const title = t('charger_stop_charging');
    showModal(MODAL_TYPES.ALERT_MODAL, {
      height: 'max-content',
      title,
      message: getIsSinglePort() ? (
        t('charger_stop_msg')
      ) : (
        <Trans
          i18nKey='multiple_port_charger_stop_msg'
          values={{
            physicalLocation: t(`${portPhysicalLocation.toLowerCase()}`),
          }}
        />
      ),
      icon: ErrorWarningtraiangle,
      iconColor: ColorType.NEGATIVE1,
      buttons: [
        {
          label: t('cancel'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
        },
        {
          label: t('stop'),
          type: ButtonType.DESTRUCTIVE,
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
          onClick: () => onStopClick(portId),
        },
      ],
    });
  };

  const renderSummeryHeader = () => {
    return (
      <div className='flex flex-row relative justify-between items-center'>
        <Tooltip placement='top'>
          <TooltipTrigger>
            <Label
              text={charger?.name || ''}
              type={LabelType.H4}
              color={ColorType.BLACK}
              isLoading={isLoading}
              skeletonHeight='h-6'
              className='truncate max-w-[364px]'
            />
          </TooltipTrigger>
          <TooltipContent style={{ maxWidth: '420px', wordWrap: 'break-word' }}>
            <Label
              text={charger?.name || ''}
              type={LabelType.BODY3}
              color={ColorType.WHITE}
            />
          </TooltipContent>
        </Tooltip>
        <div className='absolute right-0'>
          <Menu
            placement='bottom-end'
            icon={More}
            dataTestId='chargers-details-menu'
          >
            <MenuItem
              label={t('view_charger_as_driver')}
              onClick={handleViewChargerClick}
            />
            <MenuItem
              label={t('view_qr_code')}
              onClick={handleViewQRCodeClick}
            />
            <Divider margin='2px' />
            <MenuItem
              label={t('move_charger')}
              onClick={handleChargerMove}
              disabled={charger?.isPowerManaged}
            />
            <MenuItem
              label={t('pm_clear_profile')}
              labelColor={ColorType.NEGATIVE1}
              onClick={showClearProfileAlert}
              disabled={
                charger?.isPowerManaged ||
                charger.status === CHARGER_STATUS.COMING_SOON ||
                charger.status === CHARGER_STATUS.OFFLINE
              }
            />
            <MenuItem
              label={t('delete')}
              labelType={LabelType.BODY3}
              labelColor={ColorType.NEGATIVE1}
              onClick={handleDeleteCharger}
              disabled={charger?.isPowerManaged || isAnyPortCharging}
              dataTestId='chargers-details-delete-button'
            />
          </Menu>
        </div>
      </div>
    );
  };

  const renderChargerImage = useMemo(() => {
    if (isLoading) {
      return <Skeleton width='w-[127px]' height='h-[126px]' />;
    }

    if (!charger?.imageUrl) return null;

    return (
      <div className='flex self-center'>
        <img src={charger?.imageUrl} width={127} height={126} alt='' />
      </div>
    );
  }, [charger, isLoading]);

  const renderRebootButton = useMemo(() => {
    return (
      <Button
        label={t('reboot')}
        labelIconSize={IconSize.SIZE_16x16}
        type={ButtonType.OUTLINE}
        icon={Reset}
        size={ButtonSize.MINI_FULL}
        onClick={handleRebootClick}
      />
    );
  }, [charger]);

  const connectorStatus = (port: ChargerPort) => {
    // Single port charger that have no active transaction should return charger platform status for displaying in status pill(COR-183)
    if (
      charger?.ports?.length === 1 &&
      charger.status !== OCPP_STATUS.CHARGING
    ) {
      return charger.status;
    }
    // Multiport charger : If port have active transaction should return OCPP status else should return port status
    return port.status.toLowerCase() === OCPP_STATUS.CHARGING
      ? getValidRawStatus(
          port?.rawChargerStatus?.rawChargerStatus?.toLowerCase(),
        )
      : port.status.toLowerCase();
  };

  const calculateMin = (date: string) => {
    return getDifferenceInMinutes(new Date(), convertToDate(date));
  };

  const renderOcppStatus = (port: ChargerPort) => {
    if (!port?.rawChargerStatus?.rawChargerStatus) return null;
    const mins = calculateMin(
      port.rawChargerStatus?.rawChargerStatusChangedTime,
    );

    const rawStatus = getValidRawStatus(
      port.rawChargerStatus?.rawChargerStatus,
    );
    let text = t(rawStatus?.toLocaleLowerCase());
    if (rawStatus.toLowerCase() === OCPP_STATUS.SUSPENDED_EV) {
      text = 'SuspendedEV';
    } else if (rawStatus.toLowerCase() === OCPP_STATUS.SUSPENDED_EVSE) {
      text = 'SuspendedEVSE';
    }

    return (
      <ConnectorRowItem
        icon={ocppStatusIcon}
        leftText={t('ocpp_status')}
        rightText={`${text} (~${handleTimeCalculation(mins, true)})`}
      />
    );
  };

  const renderConnectors = useMemo(() => {
    return (
      <div className='flex flex-col gap-2  w-full'>
        {isLoading && <Skeleton width='w-70' height='h-43' />}
        {!isLoading &&
          charger?.ports?.map((port) => {
            return (
              <div
                key={port.id}
                className='flex flex-col gap-2.5 bg-grey0 p-2 rounded-lg'
              >
                <div className='flex flex-row items-center justify-between'>
                  {charger?.ports?.length > 1 && ( // BF-677 hide port position for single port
                    <div className='w-[3.75rem]'>
                      <Label
                        text={t(`${port.physicalLocation.toLowerCase()}`)}
                        type={LabelType.LABEL_S}
                        color={ColorType.BLACK}
                      />
                    </div>
                  )}
                  <div className='flex flex-row items-center'>
                    <ChargerStatus
                      status={connectorStatus(port)}
                      timeStamp={calculateMin(
                        port.rawChargerStatus?.rawChargerStatusChangedTime,
                      )}
                    />
                  </div>
                </div>
                <div>
                  <ConnectorRowItem
                    leftText={t('session_connector')}
                    rightText={port.connectorTypes[0]}
                    renderIcon={port.connectorTypes.map((type) => (
                      <Icon
                        key={type}
                        size={IconSize.SIZE_16x16}
                        src={getConnectorIcon(type)}
                        color={ColorType.BLACK}
                        className='mr-1'
                      />
                    ))}
                  />
                  <ConnectorRowItem
                    leftText={t('speed')}
                    rightText={`${port.maxWattage} ${t('kw_maximum')}`}
                    icon={Bolt}
                  />

                  {renderOcppStatus(port)}
                </div>
                <div className='flex flex-row items-center h-8 justify-between gap-2'>
                  <Button
                    label={t('start')}
                    type={ButtonType.OUTLINE}
                    icon={Play}
                    iconColor={ColorType.POSITIVE1}
                    size={ButtonSize.MINI}
                    onClick={() =>
                      handleStartClick(port.id, port.physicalLocation)
                    }
                    buttonStyle={{ borderWidth: 1, width: '100%' }}
                    labelContainerCss='label-flex-g-0'
                  />
                  <Button
                    label={t('stop')}
                    type={ButtonType.OUTLINE}
                    icon={Stop}
                    iconColor={ColorType.NEGATIVE1}
                    size={ButtonSize.MINI}
                    onClick={() =>
                      handleStopClick(port.id, port.physicalLocation)
                    }
                    buttonStyle={{ borderWidth: 1, width: '100%' }}
                    labelContainerCss='label-flex-g-0'
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  }, [charger, isLoading, chargerSessions]);

  const renderSparkAnalyze = () => {
    return (
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-row gap-1'>
            <Label
              text={t('spark_header_label')}
              type={LabelType.LABEL_M}
              color={ColorType.GREY5}
            />
            <Tooltip placement='top'>
              <TooltipTrigger>
                <Icon src={Info} size={IconSize.SIZE_20x20} />
              </TooltipTrigger>
              <TooltipContent>
                <Label
                  text={t('spark_header_tooltip')}
                  type={LabelType.LABEL_S_MEDIUM}
                  color={ColorType.WHITE}
                />
              </TooltipContent>
            </Tooltip>
          </div>
          <Label
            text={t('spark_desc_label')}
            type={LabelType.BODY3}
            color={ColorType.GREY6}
          />
        </div>
        <SparkAnalyze charger={charger} isModelView={false} />
      </div>
    );
  };

  useEffect(() => {
    if (companyId) {
      triggerFetchCompanyDetail({ id: companyId, scope: userScope! });
    }
  }, [companyId, auth]);

  useEffect(() => {
    if (companyId) {
      triggerFetchSessions({
        chargerIds: id ? [id] : [],
        sort: 'desc',
        scope: userScope!,
        companyId,
      });
      // triggerFetchingPricingModels({ companyId: companyId, scope: userScope! });
    }
  }, [companyId, userScope]);

  return (
    <div className='w-[453px]'>
      <Card OnRenderTitle={renderSummeryHeader}>
        <ChargerError chargerStatus={charger?.status} />
        <div className='flex flex-row gap-2'>
          <div className='w-[127px] flex flex-col gap-2'>
            {renderChargerImage}
            {renderRebootButton}
          </div>
          {renderConnectors}
        </div>

        {(auth.role === USER_ROLE.SUPPORT || allowSparkAnalyze) && (
          <>
            <Divider margin='12px' />
            {renderSparkAnalyze()}
          </>
        )}
      </Card>
    </div>
  );
};
