/* eslint-disable prefer-destructuring */
import { Session, SessionObj } from '../stores/types';
import { formatIso, getLastWeek } from '../utils/Date.Util';
import { API_KEYS, Api } from './base.api';
import { SCOPE_TYPE, providesListTag } from './utils';

export interface SessionOptions {
  locationId?: string;
  chargerIds?: string[];
  dateRange?: Date[];
  vehicleId?: string;
  filter_hasCompletedTx?: boolean;
  sort?: string;
  scope: SCOPE_TYPE;
  companyId: string;
}

export enum SESSION_TYPES {
  PAYMENT_TERMINAL = 'payment_terminal',
  DRIVER_APP = 'driver_app',
  CHARGER_AUTO_START = 'charger_auto_start',
}

const formatSessionParams = (params: any) => {
  const result: Record<string, any> = {};
  Object.entries(params)?.forEach(([key, value]: [string, any]) => {
    switch (key) {
      case 'locationId':
        if (value) {
          result['filter_eq[locationId]'] = value;
        }
        break;
      case 'chargerIds':
        if (value && value.length > 0) {
          result['filter_in[chargerId]'] = value.join(',');
        }
        break;
      case 'chargers':
        if (
          value &&
          value.length > 0 &&
          value.some((charger: any) => charger.selected)
        ) {
          result['filter_in[chargerId]'] = value
            .filter((charger: any) => charger.selected)
            .map((charger: any) => charger.id)
            .join(',');
        }
        break;
      case 'dateRange': {
        let startDate;
        let endDate;
        if (value && value.length >= 2) {
          startDate = value[0];
          endDate = value[1];
        } else {
          const lastWeek = getLastWeek();
          startDate = lastWeek[0];
          endDate = lastWeek[1];
        }
        result['filter_ge[createTime]'] = formatIso(startDate);
        result['filter_le[createTime]'] = formatIso(endDate);
        break;
      }
      case 'vehicleId': {
        if (value) {
          result['filter_eq[vehicleId]'] = value;
        }
        break;
      }
      default:
        result[key] = value;
        break;
    }
  });
  return result;
};

const getStartedStatus = (session: Session) => {
  const { startedVia } = session;

  let status = startedVia;
  if (startedVia === 'PAYMENT_TERMINAL') {
    status = SESSION_TYPES.PAYMENT_TERMINAL;
  } else if (startedVia === 'SYSTEM') {
    status = SESSION_TYPES.DRIVER_APP;
  } else if (startedVia === 'AUTOSTART') {
    status = SESSION_TYPES.CHARGER_AUTO_START;
  }
  return status;
};

export const sessionApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchSessions: builder.query<SessionObj, SessionOptions>({
      query: (params) => ({
        url: `/internal/core/v2/${API_KEYS.SESSIONS}`,
        params: formatSessionParams(params),
        method: 'GET',
      }),
      providesTags: (result, error, arg) =>
        providesListTag(result?.entities, API_KEYS.SESSIONS),
      transformResponse: (response: SessionObj) => {
        return {
          totalCount: response.totalCount,
          entities: response.entities.map((session: Session) => {
            return {
              ...session,
              startedVia: getStartedStatus(session),
            };
          }),
        };
      },
    }),
    sessionStartCharging: builder.mutation<
      Session,
      { portId: string; scope: SCOPE_TYPE; companyId: string }
    >({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.SESSIONS}`,
        params: { scope: payload.scope, companyId: payload.companyId },
        body: { portId: payload.portId },
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.CHARGERS, id: result?.chargerId },
        { type: API_KEYS.SESSIONS, id: 'LIST' },
      ],
    }),
    sessionStopCharging: builder.mutation<
      Session,
      { sessionId: string; scope: SCOPE_TYPE; companyId: string }
    >({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.SESSIONS}/${payload.sessionId}/stop`,
        params: { scope: payload.scope, companyId: payload.companyId },
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => {
        return [
          { type: API_KEYS.CHARGERS, id: result?.chargerId },
          { type: API_KEYS.SESSIONS, id: 'LIST' },
        ];
      },
    }),
  }),
});

export const {
  useFetchSessionsQuery,
  useLazyFetchSessionsQuery,
  useSessionStartChargingMutation,
  useSessionStopChargingMutation,
} = sessionApi;
