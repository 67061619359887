import { uniqueId } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import generatePDF from 'react-to-pdf';
import { CheckDone } from '../../../assets/icons';
import { Charger } from '../../../stores/types';
import { formatDateTimeRange } from '../../../utils/Date.Util';
import { pdfDefaultOption } from '../../../utils/Pdf.Utils';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Divider,
  Label,
  LabelType,
} from '../../_ui';
import { SparkAnalyze } from './SparkAnalyze.component';
import { SparkLoading } from './SparkLoading.component';

type SparkResultsProps = {
  charger: Charger;
  resultData?: any;
  dateTimeRange?: any;
  isSparkLimitExceed?: boolean;
};

export const SparkResults = ({
  charger,
  resultData,
  dateTimeRange,
  isSparkLimitExceed,
}: SparkResultsProps) => {
  const { t } = useTranslation();
  const copyToClickRef = useRef<any>(null);
  const [sparkResultData, setSparkResultData] = useState(resultData || null);
  const [isResultLoading, setIsResultLoading] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [copyState, setCopyState] = useState({
    isClipboardClicked: false,
    isCompleted: false,
    copiedContent: resultData.copyToClipboardContent,
  });

  const copyToClipboard = () => {
    setCopyState({ ...copyState, isClipboardClicked: true });

    // Added charger name and analysis time to clipboard data
    const clipboardContent = `${t('charger_id_label')}: ${charger?.name}\n ${t(
      'spark_time_range_label',
    )}: ${formatDateTimeRange(dateTimeRange.selDates)} \n\n ${
      copyState.copiedContent
    }`;
    navigator.clipboard.writeText(clipboardContent).finally(() => {
      setTimeout(() => {
        setCopyState({
          ...copyState,
          isClipboardClicked: false,
          isCompleted: true,
        });
      }, 3000);
    });
  };

  const openPdfNewTab = () => {
    setIsPdfLoading(true);
    setTimeout(async () => {
      const getTargetElement = () => document.getElementById('print-container');
      await generatePDF(getTargetElement, pdfDefaultOption);
      setIsPdfLoading(false);
    }, 1000);
  };

  const renderDiagnosticSummery = () => {
    if (!sparkResultData?.diagnostic) {
      return null;
    }
    return (
      <div className='flex flex-col gap-1'>
        <Label
          type={LabelType.LABEL_M}
          text={t('spark_result_header_1')}
          color={ColorType.BLACK2}
        />
        <Label
          type={LabelType.BODY3}
          text={sparkResultData.diagnostic}
          color={ColorType.GREY6}
        />
      </div>
    );
  };

  const renderIssues = () => {
    if (!sparkResultData.issues) {
      return null;
    }
    return (
      <div className='flex flex-col gap-1'>
        <Label
          type={LabelType.LABEL_M}
          text={t('spark_result_header_2')}
          color={ColorType.BLACK2}
        />
        <Label
          type={LabelType.BODY3}
          text={sparkResultData.issues}
          color={ColorType.GREY6}
        />
      </div>
    );
  };

  const renderActionableInsights = () => {
    if (!sparkResultData?.insights?.length) {
      return null;
    }
    return (
      <div className='flex flex-col gap-1'>
        <Label
          type={LabelType.LABEL_M}
          text={t('spark_result_header_3')}
          color={ColorType.BLACK2}
        />
        {sparkResultData.insights.map((insight: string, indexNo: number) => {
          return (
            <div key={uniqueId()} className='flex flex-row gap-2'>
              <Label
                type={LabelType.BODY3}
                text={`${indexNo + 1}. `}
                color={ColorType.GREY6}
              />
              <Label
                type={LabelType.BODY3}
                text={insight}
                color={ColorType.GREY6}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderReminders = () => {
    if (!sparkResultData?.reminders?.length) {
      return null;
    }
    return (
      <div className='flex flex-col gap-1'>
        <Label
          type={LabelType.LABEL_S_MEDIUM}
          text={t('spark_result_header_4')}
          color={ColorType.BLACK2}
        />
        {sparkResultData.reminders.map((insight: string, indexNo: number) => {
          return (
            <div key={uniqueId()} className='flex flex-row gap-2'>
              <Label
                type={LabelType.BODY3}
                text={`${indexNo + 1}. `}
                color={ColorType.GREY6}
              />
              <Label
                type={LabelType.BODY3}
                text={insight}
                color={ColorType.GREY6}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderResultResult = () => {
    if (isResultLoading) {
      return (
        <div className='flex items-center justify-center'>
          <SparkLoading />
        </div>
      );
    }
    if (!sparkResultData) {
      return null;
    }
    return (
      <div className='flex flex-col gap-4'>
        {renderDiagnosticSummery()}
        {renderIssues()}
        {renderActionableInsights()}
        {renderReminders()}
      </div>
    );
  };

  const renderPrintCopyButton = () => {
    if (isResultLoading) {
      return null;
    }
    return (
      <div
        data-html2canvas-ignore='true'
        className='flex flex-row gap-2 sticky bottom-0 bg-white pb-5'
      >
        <Button
          labelContainerCss='grow-0'
          icon={copyState.isCompleted ? CheckDone : undefined}
          iconColor={ColorType.BRAND2}
          type={ButtonType.SECONDARY}
          label={
            copyState.isCompleted
              ? t('clipboard_copied')
              : t('copy_to_clipboard')
          }
          size={ButtonSize.MEDIUM_FULL}
          onClick={copyToClipboard}
          isSumbit
          isLoading={copyState.isClipboardClicked}
        />
        <Button
          type={ButtonType.PRIMARY}
          label={t('open_in_new_tab')}
          size={ButtonSize.MEDIUM_FULL}
          onClick={openPdfNewTab}
          isSumbit
          isLoading={isPdfLoading}
        />
      </div>
    );
  };

  useEffect(() => {
    if (isResultLoading) {
      setCopyState({
        ...copyState,
        isClipboardClicked: false,
        isCompleted: false,
      });
    }
  }, [isResultLoading]);

  useEffect(() => {
    if (sparkResultData?.copyToClipboardContent) {
      setCopyState({
        ...copyState,
        copiedContent: sparkResultData?.copyToClipboardContent,
      });
    }
  }, [sparkResultData]);

  return (
    <>
      <div
        ref={copyToClickRef}
        className='flex flex-col gap-4 pb-4'
        id='print-container'
      >
        <div className='flex flex-row gap-8'>
          <Label
            text={t('charger_id_label')}
            type={LabelType.LABEL_S}
            className='min-w-[80px]'
          />
          <Label text={charger.name} type={LabelType.BODY3} />
        </div>
        <SparkAnalyze
          isSparkLimitExceed={isSparkLimitExceed}
          selectedDates={dateTimeRange}
          charger={charger}
          setIsResultLoading={setIsResultLoading}
          setSparkResultData={setSparkResultData}
          isPdfLoading={isPdfLoading}
          isModelView
        />
        <Divider />
        {renderResultResult()}
      </div>
      {renderPrintCopyButton()}
    </>
  );
};
