import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonSize,
  ButtonType,
  CheckBox,
  ColorType,
  FormInput,
  Grid,
  Label,
  LabelType,
} from '../../_ui';

import { NUMBER } from '../../../constant/Number.constant';
import { useCompany } from '../../../hooks/useCompany';
import { i18n } from '../../../services/translation/i18n';
import { Company } from '../../../stores/types';
import { ValidationType } from '../../_ui/Input.component';
import { validateEmail } from '../../_ui/modal/utils';
import UserLists from './UserLists.component';
import {
  countSelectedCompany,
  getCompanyById,
  sortComapniesById,
  startPageValue,
} from './utils';

interface IProps {
  users: Array<string>;
  selectedCompany: Array<Company>;
  setUsers: Function;
  setSelectedCompany: Function;
  selectedComapnyId: string;
}

const InviteUser = ({
  users,
  selectedCompany,
  setUsers,
  setSelectedCompany,
  selectedComapnyId,
}: IProps) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [isAddCompany, setIsAddCompany] = useState(false);
  const [currentPage, setCurrentPage] = useState(NUMBER.ONE);
  const [showMore, setShowMore] = useState(false);

  const { companies, queryStatus } = useCompany();

  useEffect(() => {
    if (companies?.length) {
      setSelectedCompany(getCompanyById(companies, selectedComapnyId));
    }
  }, [companies, selectedComapnyId, setSelectedCompany]);

  const onButtonValidation = useMemo(() => {
    return validateEmail(email) && !users.includes(email.toLowerCase());
  }, [users, email]);

  const ROW_PER_PAGE = NUMBER.TEN;

  const companylist = useMemo(
    () => sortComapniesById(companies || [], selectedComapnyId),
    [selectedComapnyId, companies],
  );
  const filteredComapany = useMemo(() => {
    if (companylist && companylist.length > NUMBER.ZERO) {
      const startIndex = (currentPage - NUMBER.ONE) * ROW_PER_PAGE;
      return companylist.slice(startIndex, startIndex + ROW_PER_PAGE);
    }
    return [];
  }, [currentPage, companylist, ROW_PER_PAGE]);

  const isAllCompanySelected = useMemo(() => {
    if (companylist?.length === NUMBER.ZERO) {
      return false;
    }
    return companylist?.every((ele: Company) => {
      return (
        selectedCompany.findIndex((company: Company) => company.id === ele.id) >
        -1
      );
    });
  }, [companylist, selectedCompany]);

  const handleAllSelected = (selected: boolean) => {
    if (selected) {
      setSelectedCompany(_.uniqBy([...selectedCompany, ...companylist], 'id'));
    } else {
      setSelectedCompany(_.differenceBy(selectedCompany, companylist, 'id'));
    }
  };

  const handleCheckboxSelection = (checked: boolean, row: any) => {
    let selectedChargersShallowCopy: Array<any> = [...selectedCompany];
    if (checked) {
      const selectedCharger = filteredComapany.find(
        (ele: any) => ele.id === row.id,
      );
      if (selectedCharger) selectedChargersShallowCopy.push(selectedCharger);
    } else {
      selectedChargersShallowCopy = selectedCompany.filter(
        (ele: any) => ele.id !== row.id,
      );
    }
    setSelectedCompany(selectedChargersShallowCopy);
  };

  const handleAddUsers = () => {
    setEmail('');
    setUsers([...users, email.toLowerCase()]);
  };
  const removeUsers = (userEmail: string) => {
    const filterUsers = users.filter((user: string) => user !== userEmail);
    setUsers(filterUsers);
  };

  const handleInviteCompany = () => {
    setIsAddCompany(!isAddCompany);
    setShowMore(false);
  };

  const isSelectedCompmany = (id: string) => {
    let response = false;
    const companyIndex = selectedCompany.findIndex((ele: any) => ele.id === id);
    if (companyIndex > -NUMBER.ONE) {
      response = true;
    }
    return response;
  };

  const renderCompanyGrid = () => {
    return (
      <Grid
        pageIndex={currentPage}
        loadPage={(page: number) => setCurrentPage(page)}
        columns={[
          {
            key: 'name',
            title: (
              <CheckBox
                label={t('company')}
                labelType={LabelType.LABEL_S}
                labelColor={ColorType.GREY6}
                onChange={handleAllSelected}
                selected={isAllCompanySelected}
              />
            ),
            component: (row: any) => (
              <CheckBox
                label={row.name}
                onChange={(checked: boolean) =>
                  handleCheckboxSelection(checked, row)
                }
                selected={isSelectedCompmany(row.id)}
              />
            ),
          },
        ]}
        data={filteredComapany}
        primaryKey='name'
        totalPage={Math.ceil(
          (companylist?.length || NUMBER.ZERO) / ROW_PER_PAGE,
        )}
        isLoading={queryStatus?.isLoading}
        showPageSearch={false}
      />
    );
  };

  return (
    <>
      <div>
        <Label
          text={t('selected_user_receive_email_text')}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
        <Label
          className='mt-[16px]'
          text={t('user_do_not_have_account_text')}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
      <div className='mt-[16px]'>
        <div className='flex flex-row'>
          <div className='w-[374px]'>
            <FormInput
              inputId='user_to_invite'
              label={t('user_to_invite')}
              placeholder={t('invite_email_holder')}
              showErrorIcon
              onChange={(event: any) => setEmail(event.target.value)}
              validationType={ValidationType.EMAIL}
              width='100%'
              props={{ value: email }}
              dataTestId='inviteEmailInput'
            />
          </div>
          <div className='ml-[16px] h-[40px] w-[118px] mt-[32px]'>
            <Button
              label={t('add_to_invite')}
              type={ButtonType.PRIMARY}
              disabled={!onButtonValidation}
              onClick={handleAddUsers}
              size={ButtonSize.SMALL_FULL}
              dataTestId='addInviteEmailButton'
            />
          </div>
        </div>
      </div>

      <UserLists
        users={users}
        removeUsers={removeUsers}
        showMore={showMore}
        setShowMore={setShowMore}
      />
      <div className='border-grey2 border-b mt-[12px]' />
      <div className='mt-[16px] divide-y divide-grey2'>
        <div className='flex flex-row items-end'>
          <CheckBox
            selected={isAddCompany}
            onChange={handleInviteCompany}
            label={t('invite_to_additional_companies')}
          />
        </div>
      </div>

      {isAddCompany && (
        <>
          <div className='mt-[16px]'>
            <div className='flex flex-row items-end justify-between mb-[8px]'>
              <div className='flex items-baseline'>
                <Label
                  text={t('companies')}
                  type={LabelType.LABEL_M}
                  color={ColorType.BLACK}
                  className='mr-[8px]'
                />
                {(companylist?.length || NUMBER.ZERO) < NUMBER.TEN && (
                  <Label
                    type={LabelType.LABEL_S_MEDIUM}
                    color={ColorType.GREY5}
                    text={i18n.t('show_delegation_selected_companies', {
                      page: startPageValue(currentPage, ROW_PER_PAGE),
                      number:
                        currentPage * ROW_PER_PAGE >
                        (companylist?.length || NUMBER.ZERO)
                          ? companylist?.length || NUMBER.ZERO
                          : ROW_PER_PAGE * currentPage,
                      total: companylist?.length || NUMBER.ZERO,
                    })}
                  />
                )}
              </div>
              <div className='ml-[16px] text-sm font-normal font-inter text-grey5'>
                {`${countSelectedCompany(selectedCompany)} ${t('selected')}`}
              </div>
            </div>
          </div>
          {renderCompanyGrid()}
        </>
      )}
    </>
  );
};

export default InviteUser;
