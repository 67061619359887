import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorWarningtraiangle } from '../../../assets/icons';
import { CHARGER_STATUS } from '../../../constant/Text.constant';
import { Card, ColorType, Icon, Label, LabelType } from '../../_ui';

type ChargerErrorProps = {
  chargerStatus: string;
};
export const ChargerError = ({ chargerStatus }: ChargerErrorProps) => {
  const { t } = useTranslation();

  const renderOfflineCharger = useMemo(() => {
    return (
      <div className='w-full mt-2 mb-4'>
        <Card className='border-2 border-warning1' bg='bg-warning0'>
          <div className='flex flex-row gap-3'>
            <div className='flex flex-row'>
              <Icon color={ColorType.WARNING1} src={ErrorWarningtraiangle} />
            </div>
            <div className='flex flex-col'>
              <Label
                text={t('charger_offline_warning')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
              />
              <ul className='list-disc ml-6 mt-2'>
                <li>
                  <Label
                    text={t('chargers_trouble_solition_2')}
                    type={LabelType.BODY3}
                    color={ColorType.GREY6}
                  />
                </li>
                <li>
                  <Label
                    text={t('chargers_trouble_solution_3')}
                    type={LabelType.BODY3}
                    color={ColorType.GREY6}
                  />
                </li>
              </ul>
            </div>
          </div>
        </Card>
      </div>
    );
  }, [chargerStatus]);

  const renderOutOfOrder = useMemo(() => {
    return (
      <div className='w-full mt-2 mb-4'>
        <Card className='border-2 border-negative1' bg='bg-negative0'>
          <div className='flex flex-row gap-3'>
            <div className=''>
              <Icon src={ErrorWarningtraiangle} color={ColorType.NEGATIVE1} />
            </div>
            <div className='flex flex-col'>
              <Label
                text={t('charger_detail_error')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
              />
              <ul className='list-disc ml-6 mt-2'>
                <li>
                  <Label
                    text={t('chargers_trouble_solution_3')}
                    type={LabelType.BODY3}
                    color={ColorType.GREY6}
                  />
                </li>
              </ul>
            </div>
          </div>
        </Card>
      </div>
    );
  }, [chargerStatus]);

  return (
    <>
      {chargerStatus === CHARGER_STATUS.OFFLINE && renderOfflineCharger}
      {chargerStatus === CHARGER_STATUS.OUT_OF_ORDER && renderOutOfOrder}
    </>
  );
};
