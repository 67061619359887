/* eslint-disable no-restricted-globals */
import parsePhoneNumber, {
  CountryCode,
  isValidPhoneNumber,
} from 'libphonenumber-js';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Country, State } from '../../lib/country-state';
import { Company } from '../../stores/types';
import {
  Button,
  ButtonSize,
  ButtonType,
  CheckBox,
  CheckBoxData,
  ColorType,
  Dropdown,
  FormInput,
  GroupDirection,
  Label,
  LabelType,
  RadioGroup,
} from '../_ui';
import { useGlobalModalContext } from '../_ui/modal/GlobalModal.component';

import { percent } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { USER_ROLE, useAuth } from '../../hooks';
import { useCompany } from '../../hooks/useCompany';
import {
  useAddCompanyMutation,
  useLazyCheckCompanyNameQuery,
  useUpdateCompanyMutation,
} from '../../services/company.api';
import { useUpdateCurrencyMutation } from '../../services/pricing.api';
import { useFetchWhiteLabelsQuery } from '../../services/whitelabels.api';
import {
  BankInfoProvided,
  BusinessInfo,
} from '../../stores/types/company.interface';
import { getCountriesByPreference } from '../Charger/utils';
import { formatPercent } from '../Vehicles/utils';
import { Form } from '../_ui/Form.component';
import { InputType, ValidationType } from '../_ui/Input.component';

interface CompanyEditorProp {
  company?: Company;
}
/**
 * https://chargelab.atlassian.net/browse/BF-1883
 * We cannot send a "none" channel id to the server.
 * Therefore, when a user selects "none", we send an empty string.
 * When the server's response doesn't contain a channel id,
 * we display "none" as the selected option.
 */
const CHANNEL_ID_NONE = 'none';

export const CompanyEditor = ({ company }: CompanyEditorProp) => {
  const auth = useAuth();

  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const { companies: companylist } = useCompany();

  const { userScope } = useCompany();
  const [updateCompany, updateCompanyQueryResult] = useUpdateCompanyMutation();
  const [updateCountryCurrency] = useUpdateCurrencyMutation();

  const [addCompany, addCompanyQueryResult] = useAddCompanyMutation();
  const [triggerCheckCompanyName, { data: checkResult }] =
    useLazyCheckCompanyNameQuery();

  const isCompanyExisted = useMemo(() => {
    return checkResult && checkResult.length > 0;
  }, [checkResult]);

  useEffect(() => {
    if (addCompanyQueryResult.isSuccess && addCompanyQueryResult.data?.id) {
      const setCompanyId = async () => {
        await auth.updateCompanyId(addCompanyQueryResult.data.id);
        window.location.replace(`/?companyId=${addCompanyQueryResult.data.id}`);
      };

      setCompanyId();
    }
  }, [
    addCompanyQueryResult.isSuccess,
    addCompanyQueryResult.isError,
    addCompanyQueryResult.data,
  ]);

  const isNew = useMemo(() => {
    return company === undefined;
  }, [company]);

  const [_company, setCompany] = useState(
    company
      ? {
          ...company,
          businessInfo: {
            ...company.businessInfo,
            channelPartnerId: !company.businessInfo?.channelPartnerId
              ? CHANNEL_ID_NONE
              : company.businessInfo?.channelPartnerId,
          },
        }
      : ({
          isoCountryCode: 'CA',
          serviceFeePercentage: '5.00',
          whiteLabellingId: '',
          businessInfo: {
            channelPartnerId: '',
            ela: false,
            testCompany: false,
          } as BusinessInfo,
        } as Company),
  );

  const channelPartnersDropdownList = useMemo(() => {
    const partners =
      companylist?.map((c) => ({
        id: c.id,
        label: c.name,
        selected:
          !!_company.businessInfo?.channelPartnerId &&
          c.id === _company.businessInfo.channelPartnerId,
      })) || [];
    partners.unshift({
      id: CHANNEL_ID_NONE,
      label: t('none'),
      selected: _company.businessInfo?.channelPartnerId === CHANNEL_ID_NONE,
    });
    return partners;
  }, [companylist, _company]);

  const [phoneCountryCode, setPhoneCountryCode] = useState<string>('1');
  const [phoneNationalNumber, setPhoneNationalNumber] = useState<string>('');
  const { data: whiteLabels } = useFetchWhiteLabelsQuery();

  useEffect(() => {
    if (_company.contactPhone) {
      const result = parsePhoneNumber(_company.contactPhone);
      let phoneCC = '1';
      let phoneNN = '';
      if (result && result?.isValid()) {
        phoneCC = result.countryCallingCode
          ? result.countryCallingCode
          : phoneCC;
        phoneNN = result.nationalNumber ? result.nationalNumber : phoneNN;
      }
      setPhoneCountryCode(phoneCC);
      setPhoneNationalNumber(phoneNN);
    }
  }, [_company.contactPhone]);

  const handleCompanyChange = (key: string, value: any) => {
    setCompany({
      ..._company,
      [key]: value,
    });
  };

  const provinceList = useMemo(() => {
    return State.getStatesOfCountry(_company.isoCountryCode || 'CA').map(
      (obj) => ({
        ...obj,
        id: obj.isoCode,
        selected: obj.isoCode === _company.stateOrRegion,
      }),
    );
  }, [_company.isoCountryCode, _company.stateOrRegion]);

  const countryList = useMemo(() => {
    const countries = Country.getAllCountries();
    const result = getCountriesByPreference(countries);
    return result.map((obj: any) => ({
      ...obj,
      id: obj.isoCode,
      flagAndCode: `${obj.flag} +${obj.phonecode}`,
      selected: obj.isoCode === _company.isoCountryCode,
    }));
  }, [_company]);

  const whiteLabelList = useMemo(() => {
    if (!whiteLabels || whiteLabels.length === 0) {
      return [];
    }
    const result = whiteLabels.map((whiteLabel) => ({
      ...whiteLabel,
      id: whiteLabel.whiteLabellingId,
      selected:
        whiteLabel.whiteLabellingId.toLowerCase() ===
        _company.whiteLabellingId?.toLowerCase(),
    }));

    return result;
  }, [whiteLabels, _company.whiteLabellingId]);

  const handleSaveClick = () => {
    const payload = {
      ..._company,
      contactEmail: _company.contactEmail || '',
      contactPhone: phoneNationalNumber
        ? `+${phoneCountryCode}${phoneNationalNumber}`
        : '',
      businessInfo: {
        ..._company.businessInfo,
        channelPartnerId:
          _company.businessInfo?.channelPartnerId === CHANNEL_ID_NONE
            ? ''
            : _company.businessInfo?.channelPartnerId,
      },
    };
    if (isNew) {
      addCompany(payload);
    } else {
      updateCompany({ company: payload, scope: userScope! });
    }
  };

  const updateCurrency = () => {
    if (updateCompanyQueryResult.data?.currency) {
      updateCountryCurrency({
        currency: updateCompanyQueryResult.data?.currency,
      });
    }
  };

  const isPhoneNumberValid = useMemo(() => {
    return (
      phoneNationalNumber === '' ||
      isValidPhoneNumber(
        phoneNationalNumber,
        _company.isoCountryCode as CountryCode,
      )
    );
  }, [phoneNationalNumber]);

  const onButtonValidation = () => {
    // empty
    if (!_company.name) {
      return false;
    }
    // validate phone number
    if (!isPhoneNumberValid) {
      return false;
    }
    // validate service fee
    if (
      auth.role === USER_ROLE.SUPPORT &&
      _.isEmpty(String(_company.serviceFeePercentage))
    ) {
      return false;
    }

    if (
      auth.role === USER_ROLE.SUPPORT &&
      !_company?.businessInfo?.channelPartnerId
    ) {
      return false;
    }
    if (
      auth.role === USER_ROLE.SUPPORT &&
      _.isEmpty(String(_company.whiteLabellingId))
    ) {
      return false;
    }

    if (
      !isNew &&
      auth.role === USER_ROLE.SUPPORT &&
      _company.bankInfoProvided !== BankInfoProvided.MISSING &&
      _company.bankInfoProvided !== BankInfoProvided.NOT_REQUIRED &&
      _company.bankInfoProvided !== BankInfoProvided.PROVIDED
    ) {
      return false;
    }

    // is company already exist?
    if (isCompanyExisted) {
      return false;
    }

    return true;
  };

  const handleCompanyNameChange = (value: string) => {
    if (value.length > 0) {
      triggerCheckCompanyName(value);
    }
    handleCompanyChange('name', value);
  };

  const handleBusinessInfoChange = (key: string, value: any) => {
    if (key === 'channelPartnerId' && value) {
      setCompany({
        ..._company,
        businessInfo: {
          ..._company.businessInfo,
          [key]: value,
          ela: false, // set ela to false if channelPartnerId is not None
        } as BusinessInfo,
      });
    } else {
      setCompany({
        ..._company,
        businessInfo: {
          ..._company.businessInfo,
          [key]: value,
        } as BusinessInfo,
      });
    }
  };

  /**
   * Method handles the creation of Bank Info Status radio button options
   */
  const bankInfoStatusOptions = () => {
    return [
      {
        id: BankInfoProvided.PROVIDED,
        label: t('banking_info_present'),
        selected: _company.bankInfoProvided === BankInfoProvided.PROVIDED,
      },
      {
        id: BankInfoProvided.MISSING,
        label: t('banking_info_not_present'),
        selected: _company.bankInfoProvided === BankInfoProvided.MISSING,
      },
      {
        id: BankInfoProvided.NOT_REQUIRED,
        label: t('banking_info_not_required'),
        selected: _company.bankInfoProvided === BankInfoProvided.NOT_REQUIRED,
      },
    ];
  };

  const handleBankInfoStatusChange = (items: CheckBoxData[]) => {
    const selectedOption = items.find((item) => item.selected === true);
    if (selectedOption) {
      handleCompanyChange('bankInfoProvided', selectedOption.id);
    }
  };

  const isCompanyElaDisabled = () =>
    _company.businessInfo?.channelPartnerId === CHANNEL_ID_NONE
      ? false
      : !!_company.businessInfo?.channelPartnerId;

  const renderBusinessInfo = () => {
    if (auth.role !== USER_ROLE.SUPPORT) {
      return null;
    }

    return (
      <div className='flex flex-col gap-4 mb-1'>
        <div className='flex flex-col gap-2'>
          <Label
            text={t('company_channel_partner')}
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
          />
          <Dropdown
            placement='top-start'
            headerWidth='100%'
            contentDivWidth={480}
            items={channelPartnersDropdownList}
            onItemClick={(item: any, index: number) => {
              handleBusinessInfoChange('channelPartnerId', item.id);
            }}
            labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
            chevdownIconHighlightColor={ColorType.GREY6}
            headerHighLightClassName='bg-grey1 border-grey3 rounded h-[40px] mr-1.5'
            dataTestId='companyChannel'
          />
        </div>
        {/* Only show following field while editing company to support users */}
        {!isNew && (
          <div className='flex flex-col gap-2'>
            <Label
              text={t('banking_info_provided_title')}
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
            />
            <RadioGroup
              itemHeight='20px'
              direction={GroupDirection.Horizontal}
              defaultItems={bankInfoStatusOptions()}
              onChange={handleBankInfoStatusChange}
            />
          </div>
        )}
        <div className='flex flex-col gap-2'>
          <Label
            text={t('company_ela_status')}
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
          />
          <CheckBox
            name='all_chargers'
            label={t('company_ela_desc')}
            translationOn
            selected={_company.businessInfo?.ela}
            onChange={(checked: boolean) =>
              handleBusinessInfoChange('ela', checked)
            }
            isDisabled={isCompanyElaDisabled()}
          />
        </div>
        <div className='flex flex-col gap-2'>
          <Label
            text={t('company_test_status')}
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
          />
          <CheckBox
            name='all_chargers'
            label={t('company_test_status_desc')}
            translationOn
            selected={_company.businessInfo?.testCompany}
            onChange={(checked: boolean) =>
              handleBusinessInfoChange('testCompany', checked)
            }
          />
        </div>
      </div>
    );
  };

  return (
    <Form
      onSubmit={handleSaveClick}
      queryResult={isNew ? addCompanyQueryResult : updateCompanyQueryResult}
      onQuerySuccess={() => {
        hideModal();
        if (!isNew) {
          updateCurrency();
        }
      }}
      onQueryFailed={() => {
        hideModal();
      }}
    >
      <div className='flex flex-col justify-between h-full'>
        <div className='flex flex-col gap-4'>
          <FormInput
            inputId='company_name'
            placeholder={t('company_name')}
            label={t('company_name')}
            onChange={(event: any) =>
              handleCompanyNameChange(event.target.value)
            }
            validationType={ValidationType.NOT_EMPTY}
            defaultValue={_company.name}
            width='100%'
            validaitonQueryResult={isCompanyExisted}
            errorLabel={t('company_name_ducplicated')}
            dataTestId='companyNameInput'
          />
          <div className='flex flex-col gap-2'>
            <div className='flex flex-row gap-2 items-baseline'>
              <Label
                text={t('company_address')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
              />
              <Label
                text={`(${t('optional')})`}
                type={LabelType.BODY3}
                color={ColorType.GREY6}
              />
            </div>

            <div className='flex flex-row gap-2'>
              <FormInput
                inputId='company_edit_street_number_holder'
                placeholder={t('company_edit_street_number_holder')}
                onChange={(event: any) =>
                  handleCompanyChange('streetNumber', event.target.value)
                }
                defaultValue={_company.streetNumber}
                width='85px'
                dataTestId='companyStreetNumber'
              />
              <FormInput
                placeholder={t('company_edit_street_name_holder')}
                onChange={(event: any) =>
                  handleCompanyChange('street', event.target.value)
                }
                defaultValue={_company.street}
                width='100%'
                dataTestId='companyStreetName'
                inputTitle={t('company_edit_street_name_holder')}
              />
            </div>
            <FormInput
              placeholder={t('company_edit_unit_holder')}
              onChange={(event: any) =>
                handleCompanyChange('unit', event.target.value)
              }
              defaultValue={_company.unit}
              width='100%'
              dataTestId='companyUnitHolder'
              inputTitle={t('company_edit_unit_holder')}
            />
          </div>

          <div className='flex flex-row gap-4'>
            <div className='flex-1 flex flex-col gap-2'>
              <div className='flex flex-row gap-2 items-baseline'>
                <Label
                  text={t('company_city')}
                  type={LabelType.LABEL_M}
                  color={ColorType.BLACK}
                  labelHtmlFor='company_city'
                />
                <Label
                  text={`(${t('optional')})`}
                  type={LabelType.BODY3}
                  color={ColorType.GREY6}
                />
              </div>
              <FormInput
                inputId='company_city'
                placeholder={t('company_city')}
                onChange={(event: any) =>
                  handleCompanyChange('city', event.target.value)
                }
                defaultValue={_company.city}
                width='100%'
                dataTestId='companyCity'
              />
            </div>
            <div className='flex-1 flex flex-col gap-2'>
              <div className='flex flex-row gap-2 items-baseline'>
                <Label
                  text={t('company_state')}
                  type={LabelType.LABEL_M}
                  color={ColorType.BLACK}
                />
                <Label
                  text={`(${t('optional')})`}
                  type={LabelType.BODY3}
                  color={ColorType.GREY6}
                />
              </div>
              <Dropdown
                placement='bottom-end'
                placeholder={t('location_state_placeholder')}
                headerWidth='100%'
                label='name'
                items={provinceList}
                onItemClick={(item: any, index: number) =>
                  handleCompanyChange('stateOrRegion', item.isoCode)
                }
                labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
                chevdownIconHighlightColor={ColorType.GREY6}
                headerHighLightClassName='hover:bg-grey2 bg-grey1 border-grey3 rounded h-[40px] mr-1.5'
                contentDivHeight={300}
                dataTestId='companyStateOrRegion'
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex-1 flex flex-col gap-2'>
              <div className='flex flex-row gap-2 items-baseline'>
                <Label
                  text={t('company_zip')}
                  type={LabelType.LABEL_M}
                  color={ColorType.BLACK}
                  labelHtmlFor='company_zip'
                />
                <Label
                  text={`(${t('optional')})`}
                  type={LabelType.BODY3}
                  color={ColorType.GREY6}
                />
              </div>
              <FormInput
                inputId='company_zip'
                placeholder={t('location_zip')}
                onChange={(event: any) =>
                  handleCompanyChange('zipOrPostalCode', event.target.value)
                }
                defaultValue={_company.zipOrPostalCode}
                width='100%'
                dataTestId='companyZipCode'
              />
            </div>
            <div className='flex-1 flex flex-col gap-2'>
              <Label
                text={t('company_country')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
              />
              <Dropdown
                placement='bottom-end'
                placeholder={t('location_country_placeholder')}
                headerWidth='100%'
                label='name'
                items={countryList}
                onItemClick={(item: any, index: number) => {
                  handleCompanyChange('isoCountryCode', item.isoCode);
                  setPhoneCountryCode(item.phonecode);
                  setPhoneNationalNumber('');
                }}
                labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
                chevdownIconHighlightColor={ColorType.GREY6}
                headerHighLightClassName='hover:bg-grey2 bg-grey1 border-grey3 rounded h-[40px] mr-1.5'
                contentDivHeight={300}
                dataTestId='companyLocationCountry'
              />
            </div>
          </div>
          <div className='flex flex-col gap-2'>
            <div className='flex flex-row gap-2 items-baseline'>
              <Label
                text={t('company_phone_number')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
                labelHtmlFor='company_phone_number'
              />
              <Label
                text={`(${t('optional')})`}
                type={LabelType.BODY3}
                color={ColorType.GREY6}
              />
            </div>
            <div className='flex flex-row gap-2'>
              <div style={{ width: '30%' }}>
                <Dropdown
                  headerWidth='100%'
                  label='flagAndCode'
                  items={countryList}
                  onItemClick={(item: any) => {
                    setPhoneCountryCode(item.phonecode);
                    handleCompanyChange('isoCountryCode', item.isoCode);
                    setPhoneNationalNumber('');
                  }}
                  labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
                  chevdownIconHighlightColor={ColorType.GREY6}
                  headerHighLightClassName='hover:bg-grey2 bg-grey1 border-grey3 rounded h-[40px] mr-1.5'
                  contentDivHeight={200}
                />
              </div>
              <div style={{ width: '70%' }}>
                <FormInput
                  inputId='company_phone_number'
                  placeholder={t('placeholder_phone')}
                  onChange={(event: any) => {
                    setPhoneNationalNumber(event.target.value);
                  }}
                  validationType={ValidationType.NUMBER}
                  defaultValue={phoneNationalNumber}
                  width='100%'
                  inputType={InputType.NUMBER}
                  maxLen='16'
                  validaitonQueryResult={!isPhoneNumberValid}
                  errorLabel={t('phone_number_invalid')}
                  dataTestId='companyPhoneInput'
                />
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-2'>
            <div className='flex flex-row gap-2 items-baseline'>
              <Label
                text={t('company_email')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
                labelHtmlFor='company_email'
              />
              <Label
                text={`(${t('optional')})`}
                type={LabelType.BODY3}
                color={ColorType.GREY6}
              />
            </div>
            <FormInput
              inputId='company_email'
              placeholder={t('company_email_holder')}
              showErrorIcon
              onChange={(event: any) =>
                handleCompanyChange('contactEmail', event.target.value)
              }
              validationType={ValidationType.EMAIL}
              defaultValue={_company.contactEmail}
              width='100%'
              dataTestId='companyEmail'
            />
          </div>
          {auth.role === USER_ROLE.SUPPORT && (
            <div>
              <Label
                text={t('company_service_fee')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
                className='mb-2'
                labelHtmlFor='company_service_fee'
              />
              <FormInput
                inputId='company_service_fee'
                showErrorIcon
                onChange={(event: any) =>
                  handleCompanyChange(
                    'serviceFeePercentage',
                    formatPercent(event.target.value),
                  )
                }
                inputType={InputType.PERCENT}
                defaultValue={_company.serviceFeePercentage}
                suffixIcon={percent}
                sufficIconSize={IconSize.SIZE_14x14}
                width='93px'
              />
            </div>
          )}
          {auth.role === USER_ROLE.SUPPORT && (
            <div className='flex flex-col gap-2'>
              <Label
                text={t('company_network')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
              />
              <Dropdown
                placement='top-start'
                headerWidth='100%'
                contentDivWidth={480}
                label='whiteLabellingId'
                items={whiteLabelList}
                onItemClick={(item: any, index: number) => {
                  handleCompanyChange(
                    'whiteLabellingId',
                    item.whiteLabellingId,
                  );
                }}
                labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
                chevdownIconHighlightColor={ColorType.GREY6}
                headerHighLightClassName='bg-grey1 border-grey3 rounded h-[40px] mr-1.5'
                dataTestId='companyNetwork'
              />
            </div>
          )}
          {renderBusinessInfo()}
        </div>
        <div className='sticky bottom-0 pt-[16px] pb-[20px] bg-white'>
          <Button
            label={t('save')}
            type={ButtonType.PRIMARY}
            disabled={!onButtonValidation()}
            size={ButtonSize.SMALL_FULL}
            isSumbit
            dataTestId='saveCompanyButton'
          />
        </div>
      </div>
    </Form>
  );
};
