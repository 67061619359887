import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RFIDCard, Vehicle as VehicleIcon } from '../../assets/icons';
import { useCompany } from '../../hooks/useCompany';
import { useNavigateWithSearchParam } from '../../hooks/useNavigateWithSearchParam';
import { RoutePath } from '../../routes';
import { useFetchLocationsQuery } from '../../services/location.api';
import { useFetchRfidCardsQuery } from '../../services/rfid.api';
import {
  useDeleteVehicleMutation,
  useEditVehicleMutation,
  useFetchVehiclesQuery,
  useLazyValidateVehicleNameQuery,
  useLazyValidateVehicleVinQuery,
} from '../../services/vehicles.api';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import { Vehicle } from '../../stores/types/vehicles.interface';
import { displayEneryKwh } from '../../utils/Number.Util';
import { Sessions } from '../Session';
import {
  Button,
  ButtonSize,
  ButtonType,
  Card,
  ColorType,
  Dropdown,
  DropdownType,
  FormInput,
  Label,
  LabelType,
  MODAL_TYPES,
  Pill,
  useGlobalModalContext,
} from '../_ui';
import { Form } from '../_ui/Form.component';
import { InputType, ValidationType } from '../_ui/Input.component';

const VIN_LEN = 17;
export const VehicleDetails = memo(() => {
  const { showModal, hideModal } = useGlobalModalContext();
  const { companyId, userScope } = useCompany();
  const { data: rfids } = useFetchRfidCardsQuery({
    includeLastUsedInfo: false,
  });
  const { navigateWithCompanyId } = useNavigateWithSearchParam();

  const { locations } = useFetchLocationsQuery(
    { scope: userScope! },
    {
      selectFromResult: (endPoint) => ({
        locations: endPoint.data ? endPoint.data?.entities : [],
      }),
    },
  );
  const [triggerDeleteVehicle] = useDeleteVehicleMutation();
  const [triggerEditVehicle, editVehicleQueryResult] = useEditVehicleMutation();
  const { vehicleId: id } = useParams();
  const { companyId: companyIdSearchParam } = useCompany();
  const { data: vehicles } = useFetchVehiclesQuery();

  const [vehicle, setVehicle] = useState<Vehicle | undefined>();
  const [editEnabled, setEditEnabled] = useState(false);

  const defaultVehicle = useMemo(() => {
    if (vehicles && vehicles.length > 0 && id) {
      return vehicles?.find((vehicle1) => vehicle1.vehicleId === id);
    }
  }, [vehicles, id]);

  const theme = useSelector(getCurrentTheme);
  const { t } = useTranslation();

  const [validateVehicleName, { isError: isVehicleNameError }] =
    useLazyValidateVehicleNameQuery();
  const [validateVehicleVin, { isError: isVehicleVinError }] =
    useLazyValidateVehicleVinQuery();

  const renderNav = (parent: string, name?: string) => (
    <div className='flex flex-row gap-1'>
      <Label
        text={parent}
        type={LabelType.BODY2}
        color={ColorType.ACCENT1}
        style={{ color: theme.navigationSelectedColor }}
        onClick={() => {
          navigateWithCompanyId(`/${RoutePath.VEHICLES}`, companyIdSearchParam);
        }}
      />

      <Label
        text={`/ ${name}`}
        type={LabelType.BODY2}
        color={ColorType.GREY6}
      />
    </div>
  );

  const renderHeader = () => {
    return (
      <div className='flex place-content-center'>
        <Label
          text={
            editEnabled
              ? t('vehicle_edit_detail')
              : defaultVehicle?.vehicleName || ''
          }
          type={LabelType.H4}
          color={ColorType.BLACK}
          dataTestId='vehicleDisplayID'
        />
      </div>
    );
  };

  const handleVehicleChange = (key: string, value: any) => {
    if (vehicle) {
      setVehicle({
        ...vehicle,
        [key]: value,
      });
    }
  };

  const handleValidateName = (val: string) => {
    if (val === defaultVehicle?.vehicleName) {
      return true;
    }
    if (!val || val.length === 0) {
      return false;
    }
    return !isVehicleNameError;
  };

  const handleValidateVin = (val: string) => {
    if (val === defaultVehicle?.vin) {
      return true;
    }

    if (isVehicleVinError) {
      return false;
    }

    return !val || val.length === VIN_LEN;
  };

  const locationsDropdown = useMemo(() => {
    return locations.map((location) => ({
      id: location.id,
      label: location.name,
      selected: vehicle?.locationId === location.id,
    }));
  }, [locations, vehicle]);

  const hanldeRfidChange = (items: any) => {
    if (vehicle) {
      const cardNumbers =
        items
          .filter((item: any) => item.selected)
          .map((item1: any) => item1.cardNumber) || [];
      setVehicle({
        ...vehicle,
        rfids: cardNumbers.map((cardNumber: string) => ({ cardNumber })),
      });
    }
  };

  const rfidsDropdown = useMemo(() => {
    return (
      rfids?.entities?.map((rfid) => ({
        ...rfid,
        selected: vehicle?.rfids?.some(
          (_rfid) => _rfid.cardNumber === rfid.cardNumber,
        ),
        pillLabel:
          vehicles?.find((vehicle1) => vehicle1.vehicleId === rfid.vehicleId)
            ?.vehicleName || '',
        pillIcon: VehicleIcon,
      })) || []
    );
  }, [vehicle, rfids]);

  const renderBatteryCapacityError = () => {
    if (!vehicle?.batteryCapacityKwh || vehicle?.batteryCapacityKwh > 0) {
      return null;
    }
    return (
      <div>
        <Label
          type={LabelType.LABEL_S}
          color={ColorType.NEGATIVE1}
          className='pt-1 block'
          text={t('vehicle_battery_capacity_warn')}
        />
      </div>
    );
  };

  const renderNameError = () => {
    if (vehicle?.vehicleName && !handleValidateName(vehicle?.vehicleName)) {
      return (
        <div className='mt-2'>
          <Label
            text={t('vehicles_name_exist_error')}
            type={LabelType.BODY3}
            color={ColorType.NEGATIVE1}
          />
        </div>
      );
    }
    return null;
  };

  const renderVinError = () => {
    if (
      vehicle?.vin !== defaultVehicle?.vin &&
      vehicles?.some((vehicle1) => vehicle1.vin === vehicle?.vin)
    ) {
      return (
        <div className='mt-2'>
          <Label
            text={t('vehicles_vin_error_dupilcate')}
            type={LabelType.BODY3}
            color={ColorType.NEGATIVE1}
          />
        </div>
      );
    }
    if (vehicle?.vin && !handleValidateVin(vehicle.vin)) {
      return (
        <div className='mt-2'>
          <Label
            text={t('vehicles_vin_error')}
            type={LabelType.BODY3}
            color={ColorType.NEGATIVE1}
          />
        </div>
      );
    }

    return null;
  };

  const handleVinChange = (value: string) => {
    if (String(value).length === VIN_LEN && value !== defaultVehicle?.vin) {
      validateVehicleVin(value);
    }
    handleVehicleChange('vin', value);
  };

  const renderEditor = () => {
    return (
      <div className='flex flex-col mt-[30px] gap-[4px]'>
        <div className='flex flex-col'>
          <div className='flex flex-row items-center'>
            <Label
              text={t('vehicles_name_unit_id')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='w-[9.25rem]'
            />
            <FormInput
              placeholder={t('vehicles_name_unit_id_holder')}
              onChange={(event: any) => {
                validateVehicleName(event.target.value);
                handleVehicleChange('vehicleName', event.target.value);
              }}
              validationType={ValidationType.NOT_EMPTY}
              defaultValue={vehicle?.vehicleName}
              width='215px'
              onValidation={handleValidateName}
              validaitonQueryResult={isVehicleNameError}
              showErrorIcon
              dataTestId='vehicleName'
            />
          </div>
          <div className='ml-[148px]'>{renderNameError()}</div>
        </div>

        <div className='flex flex-col'>
          <div className='flex flex-row items-center'>
            <Label
              text={t('vehicles_vin')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='w-[9.25rem]'
            />
            <FormInput
              placeholder={t('vehicles_vin')}
              onChange={(event: any) => {
                handleVinChange(event.target.value);
              }}
              validationType={ValidationType.NOT_EMPTY}
              defaultValue={vehicle?.vin}
              width='215px'
              minLen={String(VIN_LEN)}
              maxLen={String(VIN_LEN)}
              onValidation={handleValidateVin}
              validaitonQueryResult={isVehicleVinError}
              showErrorIcon
              inputType={InputType.VIN}
              dataTestId='vehicleVIN'
            />
          </div>
          <div className='ml-[148px]'>{renderVinError()}</div>
        </div>

        <div className='flex flex-row items-center'>
          <Label
            text={t('vehicles_make')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='w-[9.25rem]'
          />
          <FormInput
            placeholder={t('vehicles_make')}
            onChange={(event: any) =>
              handleVehicleChange('make', event.target.value)
            }
            validationType={ValidationType.NOT_EMPTY}
            defaultValue={vehicle?.make}
            width='215px'
            dataTestId='vehicleMake'
          />
        </div>
        <div className='flex flex-row items-center'>
          <Label
            text={t('vehicles_model')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='w-[9.25rem]'
          />
          <FormInput
            placeholder={t('vehicles_model')}
            onChange={(event: any) =>
              handleVehicleChange('model', event.target.value)
            }
            validationType={ValidationType.NOT_EMPTY}
            defaultValue={vehicle?.model}
            width='215px'
            dataTestId='vehicleModel'
          />
        </div>
        <div className='flex flex-row items-center'>
          <Label
            text={t('vehicles_battery_capacity')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='w-[9.25rem]'
          />
          <div className='flex flex-col gap-2 w-[215px]'>
            <div className='flex flex-row gap-2 items-center'>
              <FormInput
                onChange={(event: any) =>
                  handleVehicleChange('batteryCapacityKwh', event.target.value)
                }
                inputType={InputType.NUMBER}
                validationType={ValidationType.POSITIVE_NUMBER}
                defaultValue={vehicle?.batteryCapacityKwh}
                width='64px'
                showErrorIcon
                dataTestId='vehicleBattery'
              />
              <div className=''>
                <Label
                  text={t('kwh')}
                  type={LabelType.BODY3}
                  color={ColorType.BLACK}
                />
              </div>
            </div>
            {renderBatteryCapacityError()}
          </div>
        </div>
        <div className='flex flex-row items-center'>
          <Label
            text={t('vehicles_home_location')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='w-[9.25rem]'
          />
          <Dropdown
            placeholder={t('vehicles_location_placeholder')}
            headerWidth={215}
            items={locationsDropdown}
            onItemClick={(item: any, index: number) =>
              handleVehicleChange('locationId', item.id)
            }
            labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
            chevdownIconHighlightColor={ColorType.GREY6}
            headerHighLightClassName='bg-grey1 border-white rounded border-2'
            headerClassName='bg-grey1 border-white rounded border-2'
            contentDivHeight={300}
            contentDivWidth={215}
          />
        </div>
        <div className='flex flex-row items-center'>
          <Label
            text={t('vehicles_note')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='w-[9.25rem]'
          />
          <FormInput
            placeholder={t('vehicles_note_place_holder')}
            secondLabel={`(${t('optional')})`}
            onChange={(event: any) =>
              handleVehicleChange('note', event.target.value)
            }
            validationType={ValidationType.NOT_EMPTY}
            defaultValue={vehicle?.note}
            width='215px'
          />
        </div>
        <div className='flex flex-row items-center'>
          <Label
            text={t('vehicles_rfids')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='w-[9.25rem]'
          />
          <Dropdown
            label='cardNumber'
            placeholder={t('vehicles_rfid_card_placeholder')}
            type={DropdownType.CHECKBOX}
            headerWidth={215}
            items={rfidsDropdown}
            onItemClick={(items: any) => hanldeRfidChange(items)}
            labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
            chevdownIconHighlightColor={ColorType.GREY6}
            headerHighLightClassName='bg-grey1 border-grey3 rounded'
            contentDivHeight={300}
            contentDivWidth={500}
            showPillHeader
            pillIcon={RFIDCard}
            showFooter
            dataTestId='rfidCard'
          />
        </div>
      </div>
    );
  };

  const homeLocation = useMemo(() => {
    return locations.find(
      (location) => location.id === defaultVehicle?.locationId,
    )?.name;
  }, [defaultVehicle?.locationId, locations]);

  const renderInfo = () => {
    return (
      <div className='flex flex-col mt-[30px]'>
        <div className='flex flex-row gap-12'>
          <Label
            text={t('vehicles_vin')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='w-[9.25rem] h-[40px]'
          />
          <Label
            text={defaultVehicle?.vin || ''}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        </div>
        <div className='flex flex-row gap-12'>
          <Label
            text={t('vehicles_make')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='w-[9.25rem] h-[40px]'
          />
          <Label
            text={defaultVehicle?.make || ''}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        </div>
        <div className='flex flex-row gap-12'>
          <Label
            text={t('vehicles_model')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='w-[9.25rem] h-[40px]'
          />
          <Label
            text={defaultVehicle?.model || ''}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        </div>
        <div className='flex flex-row gap-12'>
          <Label
            text={t('vehicles_battery_capacity')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='w-[9.25rem] h-[40px]'
          />
          <Label
            text={
              defaultVehicle?.batteryCapacityKwh
                ? displayEneryKwh(defaultVehicle?.batteryCapacityKwh, t('kwh'))
                : ''
            }
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        </div>
        <div className='flex flex-row gap-12'>
          <Label
            text={t('vehicles_home_location')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='w-[9.25rem] h-[40px]'
          />
          <Label
            text={homeLocation || ''}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        </div>
        <div className='flex flex-row gap-12'>
          <Label
            text={t('vehicles_note')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='w-[9.25rem] h-[40px]'
          />
          <Label
            text={defaultVehicle?.note || ''}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            className='w-[45%] mb-5 overflow-hidden break-words'
          />
        </div>
        <div className='flex flex-row gap-12'>
          <Label
            text={t('vehicles_rfids')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='w-[9.25rem] h-[40px]'
          />
          <div className='flex flex-col gap-[4px]'>
            {defaultVehicle?.rfids.map((rfid) => {
              return (
                <Pill
                  iconLeft={RFIDCard}
                  key={rfid.cardNumber}
                  label={rfid.cardNumber}
                  iconLeftAlt={t('icon_alt_rfid_card')}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const handleDelete = () => {
    if (defaultVehicle?.vehicleId) {
      showModal(MODAL_TYPES.ALERT_MODAL, {
        height: 'max-content',
        title: t('vehicle_delete_title'),
        message: t('vehicles_delete_msg'),
        iconColor: ColorType.NEGATIVE1,
        buttons: [
          {
            label: t('cancel'),
            type: ButtonType.TERTIARY,
            size: ButtonSize.SMALL,
            className: 'min-w-fit w-20',
          },
          {
            label: t('delete'),
            type: ButtonType.DESTRUCTIVE,
            size: ButtonSize.SMALL,
            className: 'min-w-fit w-20',
            dataTestId: 'deleteButton',
            onClick: () => {
              triggerDeleteVehicle(defaultVehicle);
              navigateWithCompanyId(
                `/${RoutePath.VEHICLES}`,
                companyIdSearchParam,
              );
            },
          },
        ],
      });
    }
  };

  const handleSave = () => {
    if (vehicle) {
      triggerEditVehicle(vehicle);
      // setEditEnabled(false);
    }
  };
  const handleCancel = () => {
    setVehicle(defaultVehicle);
    setEditEnabled(false);
  };

  const checkVinError =
    vehicle?.vin === defaultVehicle?.vin ? false : isVehicleVinError;

  const onSaveVildation = () => {
    if (
      isVehicleNameError ||
      checkVinError ||
      !vehicle?.vin ||
      vehicle.vin.length !== VIN_LEN ||
      !vehicle?.make ||
      !vehicle?.model ||
      !vehicle?.batteryCapacityKwh ||
      Number(vehicle?.batteryCapacityKwh) <= 0
    ) {
      return false;
    }
    return true;
  };

  const renderBottom = () => {
    return (
      <div className='divide'>
        <div className='mt-[12px]'>
          <Label
            text={t('vehicles_detail_msg')}
            type={LabelType.BODY3}
            color={ColorType.GREY6}
          />
        </div>
        <div className='mt-[20px]'>
          {editEnabled ? (
            <div className='flex flex-row-reverse gap-2'>
              <Button
                label={t('save')}
                size={ButtonSize.SMALL}
                type={ButtonType.PRIMARY}
                disabled={!onSaveVildation()}
                isSumbit
                dataTestId='vehicleSave'
              />

              <Button
                label={t('cancel')}
                size={ButtonSize.SMALL}
                type={ButtonType.TERTIARY}
                onClick={handleCancel}
              />
            </div>
          ) : (
            <Button
              label={t('delete')}
              size={ButtonSize.SMALL_FULL}
              type={ButtonType.DESTRUCTIVE}
              onClick={handleDelete}
              dataTestId='deleteVehicle'
            />
          )}
        </div>
      </div>
    );
  };

  const handleEdit = () => {
    setVehicle(defaultVehicle);
    setEditEnabled(true);
  };

  const renderDetail = () => {
    return (
      <Form
        onSubmit={handleSave}
        queryResult={editVehicleQueryResult}
        onQuerySuccess={() => {
          setEditEnabled(false);
        }}
        onQueryFailed={() => {
          setEditEnabled(false);
        }}
      >
        <div className='w-[453px] flex-none'>
          <Card
            headerButtonLabel={editEnabled ? '' : t('edit')}
            headerButtonOnClick={handleEdit}
            dataTestId='editVehicleButton'
          >
            <div className='flex flex-col divide-y gap-6 divide-grey2'>
              <div className='flex flex-col'>
                <div className='flex flex-col items-center'>
                  {renderHeader()}
                </div>
                {editEnabled ? renderEditor() : renderInfo()}
              </div>
              {renderBottom()}
            </div>
          </Card>
        </div>
      </Form>
    );
  };

  const renderSessions = () => {
    if (!defaultVehicle?.vehicleId) {
      return null;
    }
    return (
      <div className='grow'>
        <Sessions
          enableFilterCharger={false}
          enableExportCSV={false}
          dataMap={[
            'createTime|startTime',
            'port.charger.name',
            'status',
            'consumedEnergyKwh',
          ]}
          vehicleId={defaultVehicle?.vehicleId}
          companyId={companyId!}
        />
      </div>
    );
  };

  return (
    <div className='flex flex-col gap-6'>
      {renderNav(
        t('vehicles'),
        vehicle?.vehicleName || defaultVehicle?.vehicleName || '',
      )}
      <div className='flex flex-row gap-6'>
        {renderDetail()}
        {renderSessions()}
      </div>
    </div>
  );
});

VehicleDetails.displayName = 'VehicleDetails';
