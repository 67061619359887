import React, { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHover } from 'usehooks-ts';
import { IconSize } from '../../constant/IconSize.constant';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import { Icon as SVGIcon } from './Icon.component';
import { Label, LabelType } from './Label.component';
import { SubmitLoading } from './Skeleton/SubmitLoading.component';
import './button.css';
import { ColorType, getHexColorByType } from './index';

declare module 'react' {
  interface CSSProperties {
    [key: string]: string | number | undefined;
  }
}

export enum ButtonSize {
  MEDIUM = 'text-base font-semibold font-inter h-12 py-3 px-4',
  SMALL = 'text-sm font-semibold font-inter h-10 py-[10px] px-4',
  MEDIUM_FULL = 'text-base font-semibold font-inter h-12 py-3 px-4 w-full',
  SMALL_FULL = 'text-sm font-semibold font-inter h-10 py-[10px] px-4 w-full',

  /** Remaining work for button type  */
  MINI = 'px-4 h-8 py-1.5 min-w-fit w-20 font-inter', // for charger detail start and stop button
  MINI_FULL = 'px-4 h-8 py-1.5 min-w-fit w-full font-inter', // for new design of charger
}

export enum ButtonType {
  PRIMARY = 'bg-brand2 rounded hover:bg-brand3',
  SECONDARY = 'bg-brand0 rounded hover:bg-brand1',
  TERTIARY = 'bg-grey1 rounded hover:bg-grey2',
  OUTLINE = 'bg-white border border-grey3 rounded hover:bg-grey1',
  DESTRUCTIVE = 'bg-negative1 rounded hover:bg-negative2',
}

interface InputProps {
  size?: ButtonSize;
  label: string;
  labelIcon?: any; // for double icon button
  labelIconSize?: { width: number; height: number };
  labelIconColor?: string; // hex color
  topLeftLabel?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: ButtonType;
  className?: string;
  icon?: any; // Button icon
  iconColor?: string; // hex color
  iconRightAlign?: boolean; // Display icon on left or right side
  buttonStyle?: any;
  labelType?: LabelType;
  labelColor?: ColorType;
  iconSize?: { width: number; height: number };
  isSumbit?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  labelContainerCss?: string;
  disableDelayTime?: number;
  disableDelayLabel?: string;
  // TODO: KS Keeping optional until we complete all test
  dataTestId?: string;
  iconAlt?: string;
}

export const Button = memo(
  ({
    size = ButtonSize.SMALL,
    topLeftLabel,
    label,
    labelIcon,
    labelIconSize,
    labelIconColor,
    onClick,
    type = ButtonType.PRIMARY,
    className = '',
    icon = null,
    iconColor,
    iconRightAlign = false,
    buttonStyle = null,
    labelType,
    labelColor,
    iconSize = IconSize.SIZE_20x20,
    isSumbit = false,
    isLoading = true,
    disabled = false,
    labelContainerCss = 'justify-center',
    disableDelayTime = 0,
    disableDelayLabel,
    dataTestId,
    iconAlt = '',
  }: // enable = true,
  InputProps) => {
    const theme = useSelector(getCurrentTheme);
    const labelRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [topLeftLabelCoverWidth, setTopLeftLabelCoverWidth] = useState(0);
    const isHover = useHover(buttonRef);
    const [isDisabled, setIsDisabled] = useState(disabled);

    useEffect(() => {
      setIsDisabled(disabled);
    }, [disabled]);

    useEffect(() => {
      if (!isDisabled && disableDelayTime > 0 && !!disableDelayLabel) {
        setIsDisabled(true);
        const timer = setTimeout(() => {
          setIsDisabled(false);
        }, disableDelayTime);

        return () => {
          clearTimeout(timer);
        };
      }
    }, [disableDelayTime]);

    useEffect(() => {
      setTopLeftLabelCoverWidth(
        labelRef.current ? labelRef.current.getBoundingClientRect().width : 0,
      );
    }, [labelRef]);

    const getButtonStyle = () => {
      let styleValue = {};
      // disabled
      if (isDisabled) {
        styleValue = {
          backgroundColor: getHexColorByType(ColorType.GREY0),
          borderWidth: 0,
        };
      } else if (type === ButtonType.PRIMARY) {
        // primary white label BG color
        styleValue = {
          backgroundColor: isHover ? theme.brand_3 : theme.brand_2,
        };
      } else if (type === ButtonType.SECONDARY) {
        // secondary white label BG color
        styleValue = {
          backgroundColor: isHover ? theme.brand_1 : theme.brand_0,
        };
      }

      styleValue = {
        ...styleValue,
        '--dynamic-borderLeftWidth': topLeftLabel
          ? `${topLeftLabelCoverWidth + 2}px`
          : '0px',
        '--dynamic-borderColor': topLeftLabel
          ? isHover
            ? getHexColorByType(ColorType.GREY1)
            : getHexColorByType(ColorType.WHITE)
          : 'transparent',
      };

      if (buttonStyle) {
        styleValue = { ...styleValue, ...buttonStyle };
      }
      return styleValue;
    };

    const getLabelTypeAndColor = (buttonType: ButtonType) => {
      if (isDisabled) {
        return { lType: LabelType.LABEL_S, lColor: ColorType.GREY3 };
      }

      if (labelType && labelColor) {
        return { lType: labelType, lColor: labelColor as ColorType };
      }

      switch (buttonType) {
        case ButtonType.PRIMARY:
          return { lType: LabelType.LABEL_S, lColor: ColorType.WHITE };
        case ButtonType.TERTIARY:
          return { lType: LabelType.LABEL_S, lColor: ColorType.GREY6 };
        case ButtonType.SECONDARY:
          return {
            lType: LabelType.LABEL_S,
            lColor: theme.brand_2,
          };
        case ButtonType.DESTRUCTIVE:
          return { lType: LabelType.LABEL_S, lColor: ColorType.WHITE };
        case ButtonType.OUTLINE:
          return { lType: LabelType.LABEL_S, lColor: ColorType.BLACK };
        default:
          return { lType: LabelType.LABEL_S, lColor: ColorType.WHITE };
      }
    };

    const renderTopLeftLabel = () => {
      return (
        <div className='relative'>
          <div
            ref={labelRef}
            className='z-20 absolute -top-1.5 left-2 rounded '
          >
            <Label
              type={LabelType.LABEL_XXS}
              color={ColorType.BLACK}
              text={topLeftLabel}
            />
          </div>
        </div>
      );
    };

    if (isSumbit && isLoading) {
      return <SubmitLoading type={type} size={size} className={className} />;
    }

    const { lType, lColor } = getLabelTypeAndColor(type);

    return (
      <>
        {topLeftLabel && renderTopLeftLabel()}
        <button
          ref={buttonRef}
          className={`topleftlabel flex rounded items-center gap-2
          ${type} ${size} ${className} ${
            iconRightAlign
              ? 'flex-row-reverse justify-between'
              : 'flex-row justify-center'
          }`}
          onClick={onClick}
          disabled={isDisabled}
          style={getButtonStyle()}
          data-testid={dataTestId}
        >
          {icon && (
            <SVGIcon
              src={icon}
              size={iconSize}
              style={isDisabled ? { opacity: 0.4 } : {}}
              color={iconColor}
              alt={iconAlt}
            />
          )}
          <div
            className={`flex-grow flex text-ellipsis overflow-hidden ${labelContainerCss}`}
          >
            <Label
              text={
                isDisabled && disableDelayTime > 0 ? disableDelayLabel : label
              }
              type={lType}
              color={lColor}
              className='whitespace-nowrap'
            />
          </div>

          {labelIcon && (
            <SVGIcon
              src={labelIcon}
              size={labelIconSize}
              style={isDisabled ? { opacity: 0.4 } : {}}
              color={labelIconColor}
              alt={iconAlt}
            />
          )}
        </button>
      </>
    );
  },
);

Button.displayName = 'Button';
