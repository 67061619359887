import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddPlus2,
  ExternalLink,
  More,
  PayterIcon,
} from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { payterContext } from '../../../hooks/usePayter';
import {
  Button,
  ButtonType,
  ColorType,
  Icon,
  Label,
  LabelType,
  MODAL_TYPES,
  Menu,
  useGlobalModalContext,
} from '../../_ui';
import RenderLearnMoreText from './RenderLearnMoreText.component';
import UnlinkAccount from './UnlinkAccount.component';

const TerminalHeader = () => {
  const { t } = useTranslation();
  const { showModal, hideModal } = useGlobalModalContext();

  const payterConsumer = useContext(payterContext);
  const { isPayterConnected } = payterConsumer;

  const onAddPayterClick = () => {
    window.open('https://payter.us/cases/chargelab_terminal_activation.html');
    hideModal();
  };

  const addPayterTerminal = () => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('add_terminal'),
      overflow: 'auto',
      width: '540px',
      height: 'fit-content',

      onRenderBody: () => (
        <>
          <div className='mb-2'>
            <Label
              text={t('add_terminal_desc1')}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
          </div>
          <div className='mb-2'>
            <Label
              htmlText={t('add_terminal_desc2')}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
          </div>
          <div className='h-[40px] mb-5 mt-6'>
            <Button
              label={t('add_payter_terminal')}
              type={ButtonType.SECONDARY}
              icon={ExternalLink}
              iconRightAlign
              iconColor={ColorType.BRAND2}
              className='w-full mb-6 mt-4 !justify-center'
              labelContainerCss='grow-0'
              onClick={onAddPayterClick}
            />
          </div>
        </>
      ),
      onClose: hideModal,
      shouldCloseOnOverlayClick: false,
    });
  };

  return (
    <>
      <div className='flex flex-row justify-between mb-5'>
        <div>
          <Label
            text={t('payter_terminal_title')}
            type={LabelType.H4}
            color={ColorType.BLACK}
            className='mb-1'
            dataTestId='terminal-header-title'
          />
          <div className='flex items-center'>
            <Label
              text={t('powered_by')}
              type={LabelType.LABEL_XS_MEDIUM}
              color={ColorType.GREY5}
            />
            <Icon
              src={PayterIcon}
              size={IconSize.SIZE_52x12}
              className='ml-1.5'
            />
          </div>
        </div>
        {isPayterConnected && (
          <div className='flex items-center'>
            <Button
              icon={AddPlus2}
              iconColor={ColorType.GREY6}
              label={t('add_terminal')}
              type={ButtonType.TERTIARY}
              onClick={addPayterTerminal}
            />
            <div className='flex justify-end items-start ml-4'>
              <Menu
                placement='bottom-end'
                icon={More}
                iconAlt={t('icon_alt_more_options')}
              >
                <UnlinkAccount />
              </Menu>
            </div>
          </div>
        )}
      </div>
      <div className='mb-5'>
        <RenderLearnMoreText text='allow_driver_charging_session' />
      </div>
    </>
  );
};

export default TerminalHeader;
