import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ErrorWarningtraiangle, Info } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { useCompany } from '../../hooks/useCompany';
import { useFetchLocationsQuery } from '../../services/location.api';
import { useGetCircuitsQuery } from '../../services/pm.api';
import { useFetchPricingModelsQuery } from '../../services/pricing.api';
import {
  useFetchChargerStaticDataQuery,
  useFetchModelsQuery,
} from '../../services/provision';
import { SCOPE_TYPE } from '../../services/utils';
import { getWhiteLabellingCompanyName } from '../../stores/selectors/env.selector';
import {
  ChargerSharedDetails,
  Connectivity,
} from '../../stores/types/chargers.interface';
import { Price } from '../../stores/types/price.interface';
import { formatDate } from '../../utils/Date.Util';
import {
  Button,
  ButtonSize,
  ButtonType,
  CheckBoxData,
  ColorType,
  DateTimePicker,
  Dropdown,
  FormInput,
  GroupDirection,
  Icon,
  Label,
  LabelType,
  RadioGroup,
} from '../_ui';
import { InputType, ValidationType } from '../_ui/Input.component';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../_ui/Tooltip.component';
import { CustomInputType } from '../_ui/date-picker/Date-Picker.component';
import { CHARGER_NOT_VISIBLE_ON_MAP, CHARGER_VISIBLE_ON_MAP } from './utils';

export const ChargerSharedDetailEditor = (props: any) => {
  const { nextStep, setWizardState } = props;
  const { t } = useTranslation();
  const { companyId, userScope } = useCompany();

  const [_chargerSharedDetails, setChargerSharedDetails] =
    useState<ChargerSharedDetails>({} as ChargerSharedDetails);

  const [connectionType, setConnectionType] = useState(
    _chargerSharedDetails?.connectivity,
  );
  const [availableCircuit, setAvailableCircuit] = useState<any>();
  // chargerVisiblityOnMap is local state to handle the 'hiddenOnMap' field
  // It is introduced there needs to be a way to validate if user selected hidden / visible radio btn
  const [chargerVisiblityOnMap, setChargerVisiblityOnMap] = useState('');

  const whiteLabelCompanyName: any = useSelector(getWhiteLabellingCompanyName);
  const { manufacturerModelData } = useFetchModelsQuery(undefined, {
    selectFromResult: (endpoint) => ({
      manufacturerModelData: endpoint.data ? endpoint.data : [],
    }),
  });

  const { locations } = useFetchLocationsQuery(
    { scope: userScope! },
    {
      selectFromResult: (endPoint) => ({
        locations: endPoint.data ? endPoint.data.entities : [],
      }),
    },
  );
  const { data: circuitsData } = useGetCircuitsQuery({
    scope: SCOPE_TYPE.COMPANY,
  });

  const { pricing, defaultPricingRule } = useFetchPricingModelsQuery(
    { companyId: companyId!, scope: userScope! },
    {
      selectFromResult: (endPoint: any) => ({
        pricing: endPoint.data ? endPoint.data : [],
        defaultPricingRule: endPoint.data
          ? endPoint.data.find((priceRule: Price) => priceRule.defaultPrice)
          : undefined,
      }),
    },
  );

  const { networkProvider } = useFetchChargerStaticDataQuery(undefined, {
    selectFromResult: (endpoint) => ({
      networkProvider: endpoint.data ? endpoint.data.simCardProviders : [],
    }),
  });

  const checkIfLocationExistsInCircuit = (selectedId: string) => {
    const filteredCircuits = circuitsData?.entities.filter(
      (circuit) => circuit.locationId === selectedId,
    );
    setAvailableCircuit(filteredCircuits);
  };
  const locationDropdown = useMemo(() => {
    return locations.map((location) => ({
      id: location.id,
      label: location.name,
      selected: location.id === _chargerSharedDetails.locationId,
    }));
  }, [locations, _chargerSharedDetails.locationId]);

  const pricingDropdown = useMemo(() => {
    return pricing.map((pricingObj: Price) => ({
      id: pricingObj.id,
      label: pricingObj.displayName,
      selected: pricingObj.defaultPrice,
    }));
  }, [pricing]);

  const manufacturerDropdown = useMemo(() => {
    return manufacturerModelData.reduce(
      (pre: any, curr: any, index: number) => {
        const exist = pre.find((obj: any) => {
          return obj.label === curr.vendor;
        });
        if (!exist) {
          pre.push({
            id: curr.vendor,
            label: curr.vendor,
            selected:
              curr.vendor === _chargerSharedDetails.chargerManufacturerId,
          });
        }
        return pre;
      },
      [],
    );
  }, [manufacturerModelData]);

  const modelDropdown = useMemo(() => {
    return _.get(
      _.mapValues(
        _.groupBy(
          manufacturerModelData.map((model: any) => ({
            id: model.id,
            label: model.model,
            selected: model.id === _chargerSharedDetails.chargerModelId,
            manufacturer: model.vendor,
          })),
          (element) => element.manufacturer,
        ),
        (list) => list.map((obj) => _.omit(obj, 'manufacturer')),
      ),
      _chargerSharedDetails.chargerManufacturerId,
    );
  }, [
    manufacturerModelData,
    manufacturerDropdown,
    _chargerSharedDetails.chargerManufacturerId,
  ]);

  const networkProviderDropdown = useMemo(() => {
    return networkProvider.map((network: any, index: number) => ({
      id: index,
      label: network,
      selected: false,
    }));
  }, [networkProvider]);

  useEffect(() => {
    if (defaultPricingRule && !_chargerSharedDetails.priceModelId) {
      setChargerSharedDetails({
        ..._chargerSharedDetails,
        sharedConnectionType: _chargerSharedDetails.sharedConnectionType
          ? _chargerSharedDetails.sharedConnectionType
          : true,
        connectivity: _chargerSharedDetails.connectivity
          ? _chargerSharedDetails.connectivity
          : connectionType,
        whitelabeledSimCard: _chargerSharedDetails.whitelabeledSimCard
          ? _chargerSharedDetails.whitelabeledSimCard
          : true,
        priceModelId: defaultPricingRule.id,
      });
    }
  });

  const handleInputChange = (key: string, value: any) => {
    setChargerSharedDetails({
      ..._chargerSharedDetails,
      [key]: value,
    });
  };

  const connectionTypeData = () => {
    return [
      {
        id: Connectivity.SIM_CARD,
        label: t('chargers_sim_card'),
        selected: _chargerSharedDetails.connectivity === Connectivity.SIM_CARD,
      },
      {
        id: Connectivity.WIFI,
        label: t('chargers_wifi'),
        selected: _chargerSharedDetails.connectivity === Connectivity.WIFI,
      },
      {
        id: Connectivity.ETHERNET,
        label: t('chargers_ethernet'),
        selected: _chargerSharedDetails.connectivity === Connectivity.ETHERNET,
      },
      {
        id: Connectivity.VARIES_BY_USER,
        label: t('varies_by_user'),
        selected: _chargerSharedDetails.sharedConnectionType === false,
      },
    ];
  };

  const handleConnectionType = (items: CheckBoxData[]) => {
    const selectedConnection = items.find((item) => item.selected === true);
    const connectivity = selectedConnection?.id as Connectivity;
    const sharedConnectionType =
      selectedConnection?.id !== Connectivity.VARIES_BY_USER;
    setConnectionType(connectivity);
    setChargerSharedDetails({
      ..._chargerSharedDetails,
      connectivity: sharedConnectionType ? connectivity : '',
      sharedConnectionType,
    });
  };

  const renderDivForConnectionType = () => {
    return (
      <div className='flex flex-col gap-y-2'>
        <Label
          text={t('network_connectivity')}
          type={LabelType.LABEL_M}
          color={ColorType.BLACK}
        />
        <RadioGroup
          direction={GroupDirection.Horizontal}
          defaultItems={connectionTypeData()}
          onChange={handleConnectionType}
          dataTestId='add-charger-connection'
        />
      </div>
    );
  };

  const renderDivForSIMConnection = () => {
    if (
      _chargerSharedDetails.sharedConnectionType &&
      connectionType === Connectivity.SIM_CARD
    ) {
      const handleSimCardProvidedBy = (updatedItems: CheckBoxData[]) => {
        const selectedOption = updatedItems.find(
          (item) => item.selected === true,
        );
        setChargerSharedDetails({
          ..._chargerSharedDetails,
          whitelabeledSimCard: selectedOption?.id === 'YES',
        });
      };

      const simCardProvidedByData = () => {
        return [
          {
            id: 'YES',
            label: t('chargers_yes'),
            selected: _chargerSharedDetails?.whitelabeledSimCard,
          },
          {
            id: 'NO',
            label: t('chargers_no'),
            selected: !_chargerSharedDetails?.whitelabeledSimCard,
          },
        ];
      };

      return (
        <div className='flex flex-col gap-y-2'>
          <div className='text-base font-semibold font-inter text-black'>
            <Trans
              i18nKey='chargers_sim_card_provided_question'
              values={{ companyName: whiteLabelCompanyName }}
            />
          </div>
          <RadioGroup
            direction={GroupDirection.Horizontal}
            defaultItems={simCardProvidedByData()}
            onChange={handleSimCardProvidedBy}
          />
          <div className='flex flex-col gap-y-2'>
            <div className='flex flex-row gap-1'>
              <Label
                text={t('chargers_network_provider')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
              />
              <Label
                text={`(${t('chargers_optional')})`}
                type={LabelType.LABEL_M_MEDIUM}
                color={ColorType.GREY6}
              />
            </div>
            <Dropdown
              items={networkProviderDropdown}
              onItemClick={(selectedItem: any, index: number) =>
                handleInputChange('simCardProvider', selectedItem.label)
              }
              headerWidth='500px'
              placeholder={t('chargers_network_provider')}
              placeholderLabelType={LabelType.BODY3_G4}
              labelType={LabelType.BODY3_G4}
              labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
              contentDivWidth={500}
              contentDivHeight={300}
              chevdownIconHighlightColor={ColorType.GREY6}
              headerHighLightClassName='bg-grey1 border-grey3 rounded h-[40px] mr-1.5'
            />
          </div>
        </div>
      );
    }
  };

  const renderDivForWifiConnection = () => {
    if (
      _chargerSharedDetails.sharedConnectionType &&
      connectionType === Connectivity.WIFI
    ) {
      return (
        <div className='flex flex-row gap-4'>
          <div className='flex flex-col gap-y-2'>
            <FormInput
              inputId='chargers_wifi_network_name'
              label={t('chargers_wifi_network_name')}
              secondLabel={`(${t('chargers_optional')})`}
              secondLabelType={LabelType.LABEL_M_MEDIUM}
              secondLabelColor={ColorType.GREY6}
              defaultValue={_chargerSharedDetails?.wifiName}
              placeholder={t('chargers_wifi_network_name_placeholder')}
              inputType={InputType.TEXT}
              onChange={(event: any) =>
                handleInputChange('wifiName', event.target.value)
              }
            />
          </div>
          <div className='flex flex-col gap-y-2'>
            <FormInput
              inputId='chargers_wifi_network_password'
              label={t('chargers_wifi_network_password')}
              secondLabel={`(${t('chargers_optional')})`}
              secondLabelType={LabelType.LABEL_M_MEDIUM}
              secondLabelColor={ColorType.GREY6}
              defaultValue={_chargerSharedDetails?.wifiPassword}
              placeholder={t('chargers_wifi_network_password_placeholder')}
              inputType={InputType.TEXT}
              onChange={(event: any) =>
                handleInputChange('wifiPassword', event.target.value)
              }
            />
          </div>
        </div>
      );
    }
  };

  const handleMapVisibility = (updatedItems: CheckBoxData[]) => {
    const selectedOption = updatedItems.find((item) => item.selected === true);
    setChargerVisiblityOnMap(selectedOption?.id || '');
    setChargerSharedDetails({
      ..._chargerSharedDetails,
      hiddenOnMap: selectedOption?.id === CHARGER_NOT_VISIBLE_ON_MAP,
    });
  };

  const onButtonValidation = () => {
    if (_chargerSharedDetails.sharedConnectionType) {
      if (!_chargerSharedDetails.connectivity) {
        return false;
      }
    }
    if (
      !chargerVisiblityOnMap ||
      !_chargerSharedDetails.chargerModelId ||
      !_chargerSharedDetails.locationId ||
      !_chargerSharedDetails.priceModelId
    ) {
      return false;
    }
    return true;
  };

  const handleNextButtonClick = () => {
    setWizardState({ chargerSharedDetails: _chargerSharedDetails });
    nextStep();
  };

  const mapVisibilityData = () => {
    return [
      {
        id: CHARGER_VISIBLE_ON_MAP,
        label: t('charger_map_visible'),
        selected: chargerVisiblityOnMap === CHARGER_VISIBLE_ON_MAP,
      },
      {
        id: CHARGER_NOT_VISIBLE_ON_MAP,
        label: t('charger_map_not_visible'),
        selected: chargerVisiblityOnMap === CHARGER_NOT_VISIBLE_ON_MAP,
      },
    ];
  };

  return (
    <div className='divide-y divide-grey2'>
      <div className='pb-4'>
        <Label
          text={t('chargers_add_step_1_description_1')}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
        <Label text='&nbsp;' type={LabelType.LABEL_M} color={ColorType.BLACK} />
        <Label
          text={t('chargers_add_step_1_description_2')}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
      <div className='pt-4'>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-row gap-4'>
            <div className='flex w-[242px] flex-col gap-y-2'>
              <Label
                text={t('chargers_manufacturer')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
              />
              <Dropdown
                headerWidth='242px'
                items={manufacturerDropdown}
                onItemClick={(selectedItem: any, index: number) => {
                  setChargerSharedDetails({
                    ..._chargerSharedDetails,
                    chargerManufacturerId: selectedItem.id,
                    chargerModelId: '',
                  });
                }}
                placeholder={t('chargers_manufacturer')}
                placeholderLabelType={LabelType.BODY3_G4}
                labelType={LabelType.BODY3_G4}
                labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
                contentDivWidth={242}
                contentDivHeight={300}
                chevdownIconHighlightColor={ColorType.GREY6}
                headerHighLightClassName='bg-grey1 border-grey3 rounded h-[40px] mr-1.5'
                dataTestId='add-charger-manufacturer'
              />
            </div>
            <div className='flex w-[242px] flex-col gap-y-2'>
              <Label
                text={t('chargers_model')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
              />
              <Dropdown
                headerWidth='242px'
                items={modelDropdown || []}
                onItemClick={(selectedItem: any, index: number) =>
                  handleInputChange('chargerModelId', selectedItem.id)
                }
                placeholder={t('chargers_model')}
                placeholderLabelType={LabelType.BODY3_G4}
                labelType={LabelType.BODY3_G4}
                labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
                contentDivWidth={242}
                chevdownIconHighlightColor={ColorType.GREY6}
                headerHighLightClassName='bg-grey1 border-grey3 rounded h-[40px] mr-1.5'
                disabled={!modelDropdown}
                dataTestId='add-charger-model'
              />
            </div>
          </div>
          <div className='flex flex-col gap-y-2'>
            <Label
              text={t('chargers_location')}
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
            />
            <Dropdown
              headerWidth='500px'
              items={locationDropdown}
              onItemClick={(selectedItem: any, index: number) => {
                setChargerSharedDetails({
                  ..._chargerSharedDetails,
                  locationId: selectedItem.id,
                  locationName: selectedItem.label,
                });
                checkIfLocationExistsInCircuit(selectedItem.id);
              }}
              placeholder={t('chargers_location')}
              placeholderLabelType={LabelType.BODY3_G4}
              labelType={LabelType.BODY3_G4}
              contentDivWidth={500}
              labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
              chevdownIconHighlightColor={ColorType.GREY6}
              headerHighLightClassName='bg-grey1 border-grey3 rounded h-[40px] mr-1.5'
              dataTestId='add-charger-location'
            />
          </div>
          {availableCircuit?.length >= 1 && (
            <div className='flex flex-row gap-x-2'>
              <Icon
                src={ErrorWarningtraiangle}
                size={IconSize.SIZE_24x24}
                color={ColorType.WARNING1}
              />
              <Label
                text={t('pm_soft_warning')}
                type={LabelType.BODY4}
                color={ColorType.BLACK}
              />
            </div>
          )}
          <div className='flex flex-col gap-y-2'>
            <Label
              text={t('chargers_pricing_rule')}
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
            />
            <Dropdown
              headerWidth='500px'
              items={pricingDropdown}
              onItemClick={(selectedItem: any, index: number) =>
                handleInputChange('priceModelId', selectedItem.id)
              }
              labelType={LabelType.BODY3_G4}
              contentDivWidth={500}
              labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
              chevdownIconHighlightColor={ColorType.GREY6}
              headerHighLightClassName='bg-grey1 border-grey3 rounded h-[40px] mr-1.5'
            />
          </div>
          {renderDivForConnectionType()}
          {renderDivForSIMConnection()}
          {renderDivForWifiConnection()}
          <div className='flex flex-col gap-y-2'>
            <div className='flex flex-row gap-1 items-center'>
              <Label
                text={t('chargers_map_visibility')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
              />
              <Tooltip>
                <TooltipTrigger>
                  <Icon src={Info} size={IconSize.SIZE_24x24} />
                </TooltipTrigger>
                <TooltipContent>
                  <Label
                    text={t('chargers_map_visibility_hint')}
                    type={LabelType.BODY3}
                    color={ColorType.WHITE}
                  />
                </TooltipContent>
              </Tooltip>
            </div>
            <RadioGroup
              direction={GroupDirection.Horizontal}
              defaultItems={mapVisibilityData()}
              onChange={handleMapVisibility}
              dataTestId='add-charger-map-visibility'
            />
          </div>
          <div className='flex flex-col gap-y-2'>
            <FormInput
              inputId='chargers_directions'
              defaultValue={_chargerSharedDetails?.directions}
              label={t('chargers_directions')}
              secondLabel={`(${t('chargers_optional')})`}
              secondLabelType={LabelType.LABEL_M_MEDIUM}
              secondLabelColor={ColorType.GREY6}
              placeholder={t('chargers_directions')}
              inputType={InputType.TEXT}
              props={{ style: { width: '500px' } }}
              onChange={(event: any) =>
                handleInputChange('directions', event.target.value)
              }
            />
          </div>
          <div className='flex flex-col gap-y-2'>
            <FormInput
              inputId='chargers_internal_note'
              defaultValue={_chargerSharedDetails?.internalNote}
              label={t('chargers_internal_note')}
              secondLabel={`(${t('chargers_optional')})`}
              secondLabelType={LabelType.LABEL_M_MEDIUM}
              secondLabelColor={ColorType.GREY6}
              placeholder={t('chargers_internal_note_placeholder')}
              inputType={InputType.TEXT}
              props={{ style: { width: '500px' } }}
              onChange={(event: any) =>
                handleInputChange('internalNote', event.target.value)
              }
            />
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-y-2'>
              <FormInput
                inputId='chargers_installer'
                defaultValue={_chargerSharedDetails?.installer}
                label={t('chargers_installer')}
                secondLabel={`(${t('chargers_optional')})`}
                secondLabelType={LabelType.LABEL_M_MEDIUM}
                secondLabelColor={ColorType.GREY6}
                placeholder={t('chargers_installer')}
                inputType={InputType.TEXT}
                onChange={(event: any) =>
                  handleInputChange('installer', event.target.value)
                }
              />
            </div>
            <div className='flex flex-col gap-y-2'>
              <div className='flex flex-row gap-1'>
                <Label
                  text={t('chargers_installation_date')}
                  type={LabelType.LABEL_M}
                  color={ColorType.BLACK}
                  labelHtmlFor='installationDate'
                />
                <Label
                  text={`(${t('chargers_optional')})`}
                  type={LabelType.LABEL_M_MEDIUM}
                  color={ColorType.GREY6}
                />
              </div>
              <DateTimePicker
                onChange={(date) =>
                  handleInputChange(
                    'installationDate',
                    formatDate(date, 'yyyy-MM-dd'),
                  )
                }
                format='MM/dd/yyyy'
                customInputType={CustomInputType.FORMINPUTDATE}
                showDefaultDate={false}
                customFormInputDivWidth='w-[240px]'
                customFormInputErrorLabel={t(
                  'chargers_date_validation_message',
                )}
                inputId='installationDate'
              />
            </div>
          </div>
          <div className='flex flex-col gap-y-2'>
            <FormInput
              inputId='chargers_installer_contact'
              defaultValue={_chargerSharedDetails?.installerContactEmail}
              label={t('chargers_installer_contact')}
              secondLabel={`(${t('chargers_optional')})`}
              secondLabelType={LabelType.LABEL_M_MEDIUM}
              secondLabelColor={ColorType.GREY6}
              placeholder={t('chargers_installer_contact_placeholder')}
              inputType={InputType.TEXT}
              validationType={ValidationType.EMAIL}
              showErrorIcon
              errorLabel={t('chargers_email_validation_message')}
              props={{ style: { width: '500px' } }}
              onChange={(event: any) =>
                handleInputChange('installerContactEmail', event.target.value)
              }
            />
          </div>
          <div className='flex flex-col pt-[16px] pb-[20px] sticky bottom-0 bg-white'>
            <Button
              label={t('chargers_add_next')}
              type={ButtonType.PRIMARY}
              disabled={!onButtonValidation()}
              size={ButtonSize.SMALL}
              onClick={handleNextButtonClick}
              dataTestId='add-shared-details-button'
            />
          </div>
        </div>
      </div>
    </div>
  );
};
