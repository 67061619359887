/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import { memo, useEffect, useState } from 'react';

import { ChevronDown } from '../../../assets/icons';
import { NUMBER } from '../../../constant/Number.constant';
import { TEXT } from '../../../constant/Text.constant';
import { Icon } from '../Icon.component';
import CheckBox from './CheckBox.component';
import { CheckBoxData } from './types/CheckBox-Column.interface';

interface CheckBoxTreeProps {
  onChange?: Function;
  defaultNodes: CheckBoxData[];
  filterStr?: string;
  translationOn?: boolean;
  className?: string;
  width?: number;
  parentPadding?: string;
  childPadding?: string;
  rowClassName?: string;
  dataTestId?: string;
}

const CheckBoxTree = ({
  defaultNodes,
  onChange,
  filterStr = '',
  translationOn = false,
  className = '',
  width = 400,
  parentPadding = 'pl-[16px] pr-[12px]',
  childPadding = 'pl-[32px] pr-[12px]',
  rowClassName = '',
  dataTestId,
}: CheckBoxTreeProps) => {
  const [nodes, setNodes] = useState(defaultNodes);
  const [showHideChildNodes, setShowHideChildNodes] = useState<any>({});

  useEffect(() => {
    setNodes(defaultNodes);
  }, [defaultNodes]);

  const { ALL } = TEXT;

  const handleParentChange = (selected: boolean, pIndex: number) => {
    const isAllElementExist = nodes[0].id === ALL;
    let selectednodes = [];
    if (isAllElementExist && nodes[pIndex].id === ALL) {
      selectednodes = nodes.map((node) => {
        const newChildren = node.children?.map((child) => ({
          ...child,
          selected,
        }));
        return { ...node, selected, children: newChildren };
      });
    } else {
      let isAllElementSelected = true;
      selectednodes = nodes.map((node, index) => {
        if (isAllElementExist && index === 0) {
          return { ...node, selected: false };
        }
        if (index === pIndex) {
          const newChildren = node.children?.map((child) => ({
            ...child,
            selected,
          }));
          if (isAllElementSelected) {
            isAllElementSelected = selected || false;
          }
          return { ...node, selected, children: newChildren };
        }
        if (isAllElementSelected) isAllElementSelected = node.selected || false;
        return node;
      });
      if (isAllElementExist && isAllElementSelected) {
        selectednodes[0].selected = true;
      }
    }
    setNodes(selectednodes);
    if (onChange) onChange(selectednodes);
  };

  const handleChildrenChange = (
    selected: boolean,
    cIndex: number,
    pIndex: number,
  ) => {
    const isAllElementExist = nodes[0].id === ALL;
    let isAllElementSelected = true;
    const newNodes = nodes.map((node, index) => {
      if (isAllElementExist && index === 0) {
        return { ...node, selected: false };
      }
      if (index === pIndex) {
        let isAllChildrenChecked = true;
        const newChildren = node.children?.map((child, childIndex) => {
          const newSelected = childIndex === cIndex ? selected : child.selected;
          if (!newSelected) isAllChildrenChecked = false;
          return {
            ...child,
            selected: newSelected,
          };
        });
        const _node = {
          ...node,
          selected: isAllChildrenChecked,
          children: newChildren,
        };
        if (isAllElementSelected) {
          isAllElementSelected = _node.selected || false;
        }
        return _node;
      }
      if (isAllElementSelected) isAllElementSelected = node.selected || false;
      return node;
    });
    if (isAllElementExist && isAllElementSelected) {
      newNodes[0].selected = true;
    }
    setNodes(newNodes);
    if (onChange) onChange(newNodes);
  };

  return (
    <div
      className={`flex flex-col ${className}`}
      style={{
        width: '100%',
      }}
    >
      {nodes.sort().map((node: any, pIndex) => {
        const shouldDisplayParent =
          node.label.toLowerCase().includes(filterStr.toLowerCase()) ||
          node.children?.some((child: any) =>
            child.label.toLowerCase().includes(filterStr.toLowerCase()),
          );
        if (!shouldDisplayParent) {
          return null;
        }
        return (
          <div key={`${node.label}-${pIndex}`} className={`${rowClassName}`}>
            <div
              className={`flex h-10 align-center hover:bg-grey1 ${parentPadding}`}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <CheckBox
                index={pIndex}
                label={node.label}
                preLabelIcon={node.companyIcon}
                preLabelIconAlt={node.iconLeftAlt}
                selected={node.selected}
                isDisabled={node.disabled && !node.selected}
                onChange={handleParentChange}
                translationOn={translationOn}
                dataTestId={dataTestId}
              />
              {node.children?.length > NUMBER.ZERO && (
                <Icon
                  className='ml-4'
                  src={ChevronDown}
                  onClick={() =>
                    setShowHideChildNodes({
                      ...showHideChildNodes,
                      [node.id]: !showHideChildNodes[node.id],
                    })
                  }
                  alt={node.iconRightAlt}
                />
              )}
            </div>
            {!showHideChildNodes[node.id] && (
              <div>
                {node.children?.sort().map((child: any, cIndex: any) => {
                  const shouldDisplayChild = child.label
                    .toLowerCase()
                    .includes(filterStr.toLowerCase());
                  if (!shouldDisplayChild) return null;
                  return (
                    <div
                      key={`${child.label}-${cIndex}`}
                      className={`flex hover:bg-grey1 h-10 ${childPadding}`}
                    >
                      <CheckBox
                        name={node.label}
                        index={cIndex}
                        label={child.label}
                        selected={child.selected}
                        isDisabled={child.disabled && !child.selected}
                        onChange={(selected: boolean, childIndex: number) =>
                          handleChildrenChange(selected, childIndex, pIndex)
                        }
                        translationOn={translationOn}
                        dataTestId={dataTestId}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

CheckBoxTree.defaultProps = {
  onChange: () => null,
};

export default memo<CheckBoxTreeProps>(CheckBoxTree);
