import {
  autoUpdate,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CancelClose,
  ChevronDown,
  ChevronUp,
  Search,
} from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { NUMBER } from '../../../constant/Number.constant';
import { FormInput } from '../Input.component';
import { Label, LabelType } from '../Label.component';
import { ColorType } from '../index';

interface IProps {
  contentDivWidth?: string;
  contentDivHeight?: string;
  width?: string;
  options?: Array<any>;
  onSelect: Function;
  placeholder?: string;
  disabled: boolean;
  iconSize?: { width: number; height: number };
  inputIconPadding?: string;
  suffixIconClassName?: string;
  inputId: string;
  inputTitle?: string;
  clearInput?: boolean;
}
const InputSearch = ({
  width = 'max-content',
  contentDivWidth,
  contentDivHeight = 'max-h-[30rem]',
  options = [],
  onSelect,
  placeholder,
  disabled,
  iconSize = IconSize.SIZE_16x16,
  inputIconPadding = 'pl-7',
  suffixIconClassName = '',
  inputId = 'dropdown-search-input',
  inputTitle = '',
  clearInput = false,
}: IProps) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const handleItemClick = (item: any) => {
    onSelect(item);
    setSearchText('');
  };

  const onEnterKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      onSelect(searchText);
    }
  };
  const className = 'bg-grey1';

  const optionsList = useMemo(() => {
    return options?.filter((option) =>
      option.label.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [searchText, options]);

  const renderItems = () => {
    return optionsList?.map((item) => {
      return (
        <button
          key={item.id}
          type='button'
          className={`hover:bg-grey1 pl-2 pr-2 min-h-[40px] text-left ${
            item.selected && 'bg-grey1 rounded'
          }`}
          onClick={() => handleItemClick(item)}
        >
          <div className='flex flex-row justify-between items-center'>
            <Label
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              text={item.label}
              className='truncate'
            />
          </div>
        </button>
      );
    });
  };

  const removeClass = (el: any) => {
    if (el.classList) {
      el.classList?.remove(className);
    }
  };

  const addClass = (el: any) => {
    if (el.classList) {
      el.classList?.add(className);
    }
  };

  useEffect(() => {
    let selectedDropdownItem: Element | null = null;
    let selectedDropdownItemIndex = -1;

    const handleKeyDown = (event: { key: string }) => {
      const dropdownListButtonEle = document.querySelectorAll(
        '#dropdown-list button',
      );
      const dropdownListLength = dropdownListButtonEle.length;

      if (event.key === 'ArrowDown') {
        selectedDropdownItemIndex =
          (selectedDropdownItemIndex + 1) % dropdownListLength;
      } else if (event.key === 'ArrowUp') {
        selectedDropdownItemIndex =
          (selectedDropdownItemIndex - 1 + dropdownListLength) %
          dropdownListLength;
      } else if (event.key === 'Enter' && options?.length > 0) {
        dropdownListButtonEle[selectedDropdownItemIndex]?.dispatchEvent(
          new MouseEvent('click', { bubbles: true }),
        );
      }

      if (selectedDropdownItem) {
        removeClass(selectedDropdownItem);
      }

      selectedDropdownItem = dropdownListButtonEle[selectedDropdownItemIndex];

      if (selectedDropdownItem) {
        addClass(selectedDropdownItem);
        selectedDropdownItem.scrollIntoView({ block: 'nearest' });
      }
    };

    if (isOpen) {
      document
        .getElementById('dropdown-search-input')
        ?.focus({ preventScroll: true });
      document.addEventListener('keydown', handleKeyDown);
    } else {
      if (selectedDropdownItem) {
        removeClass(selectedDropdownItem);
        selectedDropdownItem = null;
        selectedDropdownItemIndex = -1;
      }
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const inputChangeHandler = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleClose = () => {
    setSearchText('');
    setIsOpen(false);
  };

  const onFocus = () => {
    setIsOpen(true);
  };

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-end',
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });
  const click = useClick(context, { keyboardHandlers: false });
  const dismiss = useDismiss(context);
  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    click,
  ]);

  const renderContent = () => {
    return (
      <div
        className={`p-2 flex flex-col bg-white overflow-auto ${contentDivWidth} ${contentDivHeight}`}
        id='dropdown-list'
      >
        {searchText?.length > NUMBER.ZERO && (
          <button
            type='button'
            className='hover:bg-grey1 pl-2 pr-2 min-h-[40px] text-left'
            onClick={() => handleItemClick({ id: '', label: searchText })}
          >
            <div className='flex flex-row justify-between items-center'>
              <Label
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                text={`${t('search_for')} "${searchText}"`}
                className='truncate'
              />
            </div>
          </button>
        )}
        <div className='flex items-center'>
          <Label
            type={LabelType.BODY3}
            color={ColorType.GREY3}
            text={t('common_searches')}
          />
          <div className='m-3 flex-1 border-t border-grey2' />
        </div>
        {renderItems()}
      </div>
    );
  };
  const renderSuffixIcon = () => {
    if (options?.length > 1) {
      if (isOpen) {
        return ChevronUp;
      }
      return ChevronDown;
    }
    if (options?.length < 1 && searchText) {
      return CancelClose;
    }
  };

  const handleOnClickSuffix = () => {
    if (options?.length > 1) {
      if (isOpen) {
        handleClose();
      }
      onFocus();
    }
    if (options?.length < 1 && searchText) {
      setSearchText('');
    }
  };

  useEffect(() => {
    clearInput && setSearchText('');
  }, [clearInput]);

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()} style={{ width }}>
        <FormInput
          defaultValue={searchText}
          onChange={inputChangeHandler}
          icon={Search}
          iconSize={iconSize}
          iconAlt={t('search')}
          placeholder={placeholder}
          suffixIcon={renderSuffixIcon()}
          onClickSuffix={handleOnClickSuffix}
          disabled={disabled}
          width='100%'
          inputId={inputId}
          options={options}
          onEnterKeyPress={onEnterKeyPress}
          inputIconPadding={inputIconPadding}
          suffixIconClassName={suffixIconClassName}
          inputTitle={inputTitle}
        />
      </div>

      {isOpen && options?.length > 1 && !disabled && (
        <div
          className='z-50 flex flex-col p-2 bg-white outline-0 rounded-lg list-shadow hover:border-0'
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          {renderContent()}
        </div>
      )}
    </>
  );
};

export default InputSearch;
