import { t } from 'i18next';
import { tierPriceItem } from '../utils';
import AddTierButton from './AddTierButton.component';
import FormItem from './FormItem';

interface IProps {
  tierPrices: Array<any>;
  setTierPrices: Function;
  disabled?: boolean;
}

const TieredPricingForm = ({
  tierPrices,
  setTierPrices,
  disabled = false,
}: IProps) => {
  const tier = [...tierPrices];
  const poppedTierPrices = tier.pop();

  const handleTierSize = () => {
    if (!disabled) {
      const newTier = [...tier, tierPriceItem(), poppedTierPrices];
      setTierPrices(newTier);
    }
  };

  const deleteTier = (id: string) => {
    if (!disabled) {
      const filterTierArray = tierPrices.filter((item) => item.id !== id);
      setTierPrices(filterTierArray);
    }
  };

  const getTierNumber = (i: number) =>
    i === 0 ? t('during_first') : t('during_next');

  return (
    <tbody>
      {tier.map((item, i) => (
        <FormItem
          tierNumber={getTierNumber(i)}
          tierIndex={i + 1}
          key={item.id}
          item={item}
          showDeleteTier={i !== 0}
          deleteTier={deleteTier}
          tierPrices={tierPrices}
          setTierPrices={setTierPrices}
          disabled={disabled}
          dataTestId={item.pricingType}
        />
      ))}
      <AddTierButton handleTierSize={handleTierSize} />
      <FormItem
        tierIndex={tier.length + 1}
        isAnyTimeTier
        showDeleteTier={false}
        deleteTier={deleteTier}
        item={poppedTierPrices}
        tierPrices={tierPrices}
        setTierPrices={setTierPrices}
        disabled={disabled}
        dataTestId='isAnyTimeTier'
      />
    </tbody>
  );
};

export default TieredPricingForm;
