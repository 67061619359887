/* eslint-disable react/display-name */
import _ from 'lodash';
import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dollar, Info } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import {
  DayPrice,
  PricePeriod,
  PricingType,
  RuleTypes,
  ScheduleType,
} from '../../stores/types/price.interface';
import {
  Button,
  ButtonSize,
  ButtonType,
  CheckBox,
  CheckBoxGroup,
  ColorType,
  Dropdown,
  FormInput,
  Label,
  LabelType,
  useGlobalModalContext,
} from '../_ui';
import { InputType, ValidationType } from '../_ui/Input.component';
import { validateCurrency } from '../_ui/modal/utils';
import { is24Hours, isTimeOverlapWithDayPrice, minDuration } from './utils';

interface Props {
  day: string;
  index?: number;
  pricePeriod?: PricePeriod | undefined;
  scheduleType: ScheduleType | RuleTypes;
  onSave: Function;
  dayPrices?: DayPrice[];
}

const AM = 'am';
const PM = 'pm';
export const PricingPeriodEditor = memo(
  ({
    day,
    index = -1,
    scheduleType,
    pricePeriod,
    onSave,
    dayPrices = [],
  }: Props) => {
    const { t } = useTranslation();
    const { hideModal } = useGlobalModalContext();

    const theme = useSelector(getCurrentTheme);

    const [price, setPrice] = useState(
      pricePeriod?.price ? Number(pricePeriod.price.slice(1)).toFixed(2) : '',
    );
    const [pricingType, setPricingType] = useState(
      pricePeriod?.pricingType || PricingType.BILLED_BY_EFFECTIVE_CHARGING_TIME,
    );

    const start = pricePeriod?.startTime.split(' ');
    const end = pricePeriod?.endTime.split(' ');
    const [startHour, setStartHour] = useState(
      start && start?.length > 0 ? start[0].split(':')[0] : '',
    );
    const [startMin, setStartMin] = useState(
      start && start?.length > 0 ? start[0].split(':')[1] : '',
    );
    const [endHour, setEndHour] = useState(
      end && end?.length > 0 ? end[0].split(':')[0] : '',
    );
    const [endMin, setEndMin] = useState(
      end && end?.length > 0 ? end[0].split(':')[1] : '',
    );
    const [startAmPm, setStartAmPm] = useState(
      start && start?.length > 0 ? start[1].toLowerCase() : AM,
    );
    const [endAmPm, setEndAmPm] = useState(
      end && end?.length > 0 ? end[1].toLowerCase() : AM,
    );

    const [days, setDays] = useState(() => {
      let keys;
      switch (scheduleType) {
        case ScheduleType.PER_DAY:
          keys = [
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
            'sunday',
          ];
          break;
        case ScheduleType.WEEKDAYS_WEEKEND:
          keys = ['weekday', 'weekend'];
          break;
        case ScheduleType.SAME_EVERYDAY:
        default:
          keys = ['daily'];
          break;
      }
      return keys.map((key) => ({
        id: key,
        key,
        label: t(`pricing_${key}_short`),
        selected: key === day.toLowerCase(),
        disabled: key === day.toLowerCase(),
      }));
    });

    const startAmPmList = useMemo(() => {
      return [
        {
          id: AM,
          label: t(AM),
          selected: startAmPm === AM,
        },
        {
          id: PM,
          label: t(PM),
          selected: startAmPm === PM,
        },
      ];
    }, [startAmPm]);

    const endAmPmList = useMemo(() => {
      return [
        {
          id: AM,
          label: t(AM),
          selected: endAmPm === AM,
        },
        {
          id: PM,
          label: t(PM),
          selected: endAmPm === PM,
        },
      ];
    }, [endAmPm]);

    const pricingTypes = useMemo(() => {
      return Object.values(PricingType).map((type) => {
        return {
          id: type,
          label:
            type === PricingType.BILLED_BY_EFFECTIVE_CHARGING_TIME
              ? t('pricing_per_hour_charging')
              : type === PricingType.BILLED_BY_TIME_PLUGGED_IN
              ? t('pricing_per_hour_plugged_in')
              : t('pricing_per_kwh'),
          selected: pricingType === type,
        };
      });
    }, [pricingType]);

    const onButtonValidation = () => {
      if (
        _.isEmpty(price) ||
        !validateCurrency(price) ||
        _.isEmpty(startHour) ||
        Number(startHour) <= 0 ||
        Number(startHour) > 12 ||
        _.isEmpty(startMin) ||
        Number(startMin) < 0 ||
        Number(startMin) > 60 ||
        _.isEmpty(endHour) ||
        Number(endHour) <= 0 ||
        Number(endHour) > 12 ||
        _.isEmpty(endMin) ||
        Number(endMin) < 0 ||
        Number(endMin) > 60 ||
        minDuration(startHour, startMin, startAmPm, endHour, endMin, endAmPm) <
          15
      ) {
        return false;
      }
      return true;
    };

    const formatPricePeriod = () => {
      return {
        price: `$${price}`,
        pricingType,
        startTime: `${startHour.toString().padStart(2, '0')}:${startMin
          .toString()
          .padStart(2, '0')} ${startAmPm.toUpperCase()}`,
        endTime: `${endHour.toString().padStart(2, '0')}:${endMin
          .toString()
          .padStart(2, '0')} ${endAmPm.toUpperCase()}`,
      } as PricePeriod;
    };
    const handleConfirm = () => {
      if (onButtonValidation()) {
        const newPricePeriod = formatPricePeriod();
        onSave(day, index, newPricePeriod, days);
        hideModal();
      }
    };

    const renderOverlapWarn = () => {
      if (
        _.isEmpty(price) ||
        _.isEmpty(startHour) ||
        _.isEmpty(startMin) ||
        _.isEmpty(endHour) ||
        _.isEmpty(endMin)
      ) {
        return false;
      }

      if (
        isTimeOverlapWithDayPrice(
          formatPricePeriod(),
          dayPrices,
          days,
          day,
          index,
        )
      ) {
        return (
          <div className='mt-[18px]'>
            <Label
              icon={Info}
              text={t('pricing_overlap_warn')}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
            />
          </div>
        );
      }
      return null;
    };

    const render15minsError = () => {
      if (
        (_.isEmpty(startHour) || _.isEmpty(startMin) || _.isEmpty(endHour),
        _.isEmpty(endMin))
      ) {
        return null;
      }
      if (
        minDuration(startHour, startMin, startAmPm, endHour, endMin, endAmPm) <
        15
      ) {
        return (
          <Label
            text={t('pricing_period_15_warning')}
            type={LabelType.BODY3}
            color={ColorType.NEGATIVE1}
          />
        );
      }
    };

    const renderHourAndMinError = (hour: any, min: any) => {
      if (hour && (Number(hour) <= 0 || Number(hour) > 12)) {
        return (
          <Label
            text={t('pricing_hour_warning')}
            type={LabelType.BODY3}
            color={ColorType.NEGATIVE1}
          />
        );
      }
      if (min && (Number(min) < 0 || Number(min) > 60)) {
        return (
          <Label
            text={t('pricing_min_warning')}
            type={LabelType.BODY3}
            color={ColorType.NEGATIVE1}
          />
        );
      }

      return null;
    };

    return (
      <div className='flex flex-col justify-between w-full h-full pb-[16px]'>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-row items-center'>
            <Label
              text={t('pricing_rate')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='w-[156px]'
              labelHtmlFor='pricing_rate_field'
            />
            <div className='flex flex-row gap-2'>
              <FormInput
                inputId='pricing_rate_field'
                placeholder='0.00'
                onChange={(event: any) => setPrice(event.target.value)}
                defaultValue={price}
                validationType={ValidationType.CURRENCY}
                icon={Dollar}
                width='88px'
                iconSize={IconSize.SIZE_16x16}
                dataTestId='pricingRate'
              />
              <Dropdown
                headerWidth='auto'
                items={pricingTypes}
                onItemClick={(item: any) => {
                  setPricingType(item.id);
                }}
                headerHighLightClassName='bg-grey1 hover:bg-grey2 border-grey3 rounded'
                labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
                chevdownIconHighlightColor={ColorType.GREY6}
                dataTestId='pricingPriedType'
              />
            </div>
          </div>
          <div className='flex flex-row items-center'>
            <Label
              text={t('pricing_starts_at')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='w-[156px]'
              labelHtmlFor='pricing_starts_at'
            />
            <div className='flex flex-col gap-2'>
              <div className='flex flex-row gap-2 items-center'>
                <FormInput
                  inputId='pricing_starts_at'
                  placeholder='12'
                  onChange={(event: any) => setStartHour(event.target.value)}
                  defaultValue={startHour}
                  validationType={ValidationType.HOURS_12}
                  inputType={InputType.NUMBER}
                  maxLen='2'
                  width='61px'
                  dataTestId='pricingStartsAtHours'
                  inputTitle={t('input_title_starting_hour')}
                />
                <Label
                  text=':'
                  type={LabelType.BODY3}
                  color={ColorType.BLACK}
                />
                <FormInput
                  placeholder='00'
                  onChange={(event: any) => setStartMin(event.target.value)}
                  defaultValue={startMin}
                  validationType={ValidationType.MINS}
                  inputType={InputType.NUMBER}
                  maxLen='2'
                  width='61px'
                  dataTestId='pricingStartsAtMinutes'
                  inputTitle={t('input_title_starting_minute')}
                />
                <Dropdown
                  headerWidth='auto'
                  items={startAmPmList}
                  onItemClick={(item: any) => setStartAmPm(item.id)}
                  headerHighLightClassName='bg-grey1 hover:bg-grey2 border-grey3 rounded'
                  labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
                  chevdownIconHighlightColor={ColorType.GREY6}
                  dataTestId='pricingStartsAtAmPm'
                />
              </div>
              {renderHourAndMinError(startHour, startMin)}
            </div>
          </div>
          <div className='flex flex-row items-center'>
            <Label
              text={t('pricing_ends_at')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='w-[156px]'
              labelHtmlFor='pricing_ends_at'
            />
            <div className='flex flex-col gap-2'>
              <div className='flex flex-row gap-2 items-center'>
                <FormInput
                  inputId='pricing_ends_at'
                  placeholder='12'
                  onChange={(event: any) => setEndHour(event.target.value)}
                  defaultValue={endHour}
                  validationType={ValidationType.HOURS_12}
                  inputType={InputType.NUMBER}
                  maxLen='2'
                  width='61px'
                  dataTestId='pricingEndsAtHours'
                  inputTitle={t('input_title_ending_hour')}
                />
                <Label
                  text=':'
                  type={LabelType.BODY3}
                  color={ColorType.BLACK}
                />
                <FormInput
                  placeholder='00'
                  onChange={(event: any) => setEndMin(event.target.value)}
                  defaultValue={endMin}
                  validationType={ValidationType.MINS}
                  inputType={InputType.NUMBER}
                  maxLen='2'
                  width='61px'
                  dataTestId='pricingEndsAtMinutes'
                  inputTitle={t('input_title_ending_minute')}
                />
                <Dropdown
                  placement='top-start'
                  headerWidth='auto'
                  items={endAmPmList}
                  onItemClick={(item: any) => setEndAmPm(item.id)}
                  headerHighLightClassName='bg-grey1 hover:bg-grey2 border-grey3 rounded'
                  labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
                  chevdownIconHighlightColor={ColorType.GREY6}
                  dataTestId='pricingEndsAtAmPm'
                />
                {is24Hours(
                  startHour,
                  startMin,
                  startAmPm,
                  endHour,
                  endMin,
                  endAmPm,
                ) && (
                  <Label
                    text={t('pricing_24_hours')}
                    type={LabelType.BODY3}
                    color={ColorType.GREY5}
                  />
                )}
              </div>
              {renderHourAndMinError(endHour, endMin)}
              {render15minsError()}
            </div>
          </div>
          {scheduleType !== ScheduleType.SAME_EVERYDAY && (
            <div className='flex flex-col'>
              <div className='flex flex-row items-center'>
                <Label
                  text={t('pricing_save_to')}
                  type={LabelType.LABEL_S}
                  color={ColorType.GREY6}
                  className='w-[156px]'
                />
                <div className='flex flex-row divide-x divide-grey3 gap-[16px] justify-between whitespace-nowrap items-center'>
                  <CheckBox
                    selected={days.every((_day) => _day.selected)}
                    label={t('pricing_all_days')}
                    onChange={(checked: boolean) => {
                      setDays(
                        days.map((_day) => ({
                          ..._day,
                          selected: checked,
                        })),
                      );
                    }}
                    style={{ color: theme.navigationSelectedColor }}
                  />

                  <div className='pl-[16px]'>
                    <CheckBoxGroup
                      defaultItems={days}
                      onChange={(items: any) => {
                        setDays(items);
                      }}
                    />
                  </div>
                </div>
              </div>
              {renderOverlapWarn()}
            </div>
          )}
        </div>

        <div className='w-full h-[40px] mt-[16px]'>
          <Button
            label={t('confirm')}
            size={ButtonSize.SMALL_FULL}
            type={ButtonType.PRIMARY}
            disabled={!onButtonValidation()}
            onClick={handleConfirm}
            dataTestId='confirmButton'
          />
        </div>
      </div>
    );
  },
);
