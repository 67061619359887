/* eslint-disable react/display-name */
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CheckBox, ColorType, Icon, Switch } from '..';
import { Info } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { Label, LabelType } from '../Label.component';
import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip.component';
import { GridColumnType } from './enums/Grid-Column-Type.enum';
import { GridColumn } from './types/Grid-Column.interface';

interface InputProps {
  columns: GridColumn[];
  onChange?: Function;
  checkRef?: any;
  selected?: boolean;
  setIsLocalTimezone?: Function;
  isLocalTimezone?: boolean;
  switchClass?: string;
  switchBtnClass?: string;
  translateClass?: string;
}

export const GridHeader = memo(
  ({
    columns,
    onChange,
    checkRef,
    selected,
    setIsLocalTimezone,
    isLocalTimezone,
    switchClass,
    switchBtnClass,
    translateClass,
  }: InputProps) => {
    const { t } = useTranslation();
    return (
      <thead className='border-t border-b border-grey2'>
        <tr>
          {columns.map((col: GridColumn) => {
            if (col.type === GridColumnType.CHECKBOX) {
              return (
                <th key={col.key} className='text-left py-2.5 pl-3'>
                  <CheckBox
                    onChange={onChange}
                    checkRef={checkRef}
                    selected={selected}
                  />
                </th>
              );
            }
            if (col.type === GridColumnType.RADIOBTN) {
              return (
                <td
                  key={col.key}
                  className='text-left py-2.5 pl-3'
                  aria-label='radio-btn-header-col'
                />
              );
            }
            if (col.type === GridColumnType.TIMEZONE) {
              return (
                <th
                  key={col.key}
                  className={`text-left py-2.5 pl-3 ${col.class}`}
                  // style={{ width: '128px' }}
                >
                  <div className='flex items-center'>
                    <Label
                      type={LabelType.LABEL_S}
                      color={ColorType.GREY6}
                      text={col.title}
                      className='contents'
                    />
                    {col.secondaryTitle && (
                      <Label
                        type={LabelType.BODY3}
                        color={ColorType.GREY6}
                        text={col.secondaryTitle}
                        className='inline ml-[2px]'
                      />
                    )}
                    <Switch
                      value={isLocalTimezone}
                      labelColor={ColorType.GREY6}
                      enableLabel={t('timezone_toggle_local')}
                      disableLabel={t('timezone_toggle_local')}
                      onChange={setIsLocalTimezone}
                      dataTestId='localSwitch'
                      switchClass={switchClass}
                      switchBtnClass={switchBtnClass}
                      translateClass={translateClass}
                      className='gap-1 ml-2 mr-1'
                    />
                    <Tooltip>
                      <TooltipTrigger>
                        <Icon src={Info} size={IconSize.SIZE_16x16} />
                      </TooltipTrigger>
                      <TooltipContent>
                        <span
                          className='text-sm text-white font-medium'
                          style={{ fontFamily: 'Inter' }}
                        >
                          <Trans
                            i18nKey={
                              isLocalTimezone
                                ? 'local_toggle_message'
                                : 'enable_local_toggle_message'
                            }
                            components={{
                              br: <br />,
                            }}
                          />
                        </span>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                </th>
              );
            }
            return (
              <th
                key={col.key}
                className={`text-left py-2.5 pl-3 ${col.class}`}
                // style={{ width: '128px' }}
              >
                <Label
                  type={LabelType.LABEL_S}
                  color={ColorType.GREY6}
                  text={col.title}
                  className='contents'
                />
                {col.secondaryTitle && (
                  <Label
                    type={LabelType.BODY3}
                    color={ColorType.GREY6}
                    text={col.secondaryTitle}
                    className='inline ml-[2px] whitespace-nowrap'
                  />
                )}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  },
);
