import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { ColorType, Icon, Label, LabelType, Pill } from '..';
import { IconSize } from '../../../constant/IconSize.constant';
import { getCurrentTheme } from '../../../stores/selectors/theme.selector';

interface CheckBoxProps {
  name?: string;
  label?: string;
  selected?: boolean;
  onChange?: Function;
  isDisabled?: boolean;
  singleSelection?: boolean;
  index?: number;
  style?: any;
  checkRef?: any;
  translationOn?: boolean;
  icon?: string;
  labelType?: LabelType;
  labelColor?: ColorType;
  pillLabel?: string;
  pillIcon?: any;
  selectedLabelColor?: string;
  secondaryLabel?: string;
  preLabelIcon?: any;
  dataTestId?: string;
  title?: string;
  preLabelIconAlt?: string;
}

const CheckBox = ({
  name = '',
  onChange,
  label = '',
  selected = false,
  isDisabled = false,
  singleSelection = false,
  index,
  style,
  checkRef,
  selectedLabelColor = '',
  translationOn = false,
  icon = '',
  labelType = LabelType.BODY3,
  labelColor = ColorType.BLACK,
  pillLabel,
  pillIcon,
  secondaryLabel = '',
  preLabelIcon = '',
  dataTestId,
  title = '',
  preLabelIconAlt = '',
}: CheckBoxProps) => {
  const { t } = useTranslation();

  const uniqueId = `checkbox-${uuidv4()}`;

  const [checked, setChecked] = useState(selected);
  const theme = useSelector(getCurrentTheme);
  useEffect(() => {
    if (checked !== selected) {
      setChecked(selected);
    }
  }, [selected]);

  const handleChange = (value: any) => {
    if (singleSelection) {
      setChecked(true);
    } else {
      setChecked(!value);
    }

    if (onChange) onChange(!value, index);
  };

  return (
    <div className='flex flex-col items-start w-full h-full gap-1'>
      <div className='flex flex-row items-center w-full h-full'>
        <div
          data-testid={dataTestId || label}
          className='flex flex-row gap-2 items-center grow h-full'
          style={{ cursor: 'pointer' }}
        >
          <input
            className={`w-4 h-4 justify-center ${
              isDisabled ? 'text-grey3 border-grey0 bg-grey0' : 'text-brand2 '
            } ${singleSelection ? 'form-radio' : 'rounded-sm form-checkbox'}`}
            type='checkbox'
            checked={checked}
            disabled={isDisabled}
            name={name}
            value={label}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                handleChange(checked);
              }
            }}
            onChange={() => {
              handleChange(checked);
            }}
            style={{ color: theme.navigationSelectedColor, ...style }}
            ref={checkRef}
            readOnly
            id={uniqueId}
            title={title}
            aria-label={title}
          />
          {!!preLabelIcon && (
            <Icon
              src={preLabelIcon}
              size={IconSize.SIZE_16x16}
              alt={preLabelIconAlt}
            />
          )}
          {label && (
            <Label
              labelHtmlFor={uniqueId}
              type={isDisabled ? LabelType.BODY3_DISABLED : labelType}
              text={translationOn ? t(label) : label}
              icon={icon}
              lazyIcon
              style={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'left',
              }}
              color={isDisabled ? ColorType.GREY3 : labelColor}
            />
          )}
        </div>
        {pillLabel && (
          <div className='pr-[8px] flex-none'>
            <Pill label={pillLabel} iconLeft={pillIcon} />
          </div>
        )}
      </div>
      {secondaryLabel && (
        <Label
          className='pl-6'
          type={isDisabled ? LabelType.BODY3_DISABLED : LabelType.BODY3}
          text={translationOn ? t(secondaryLabel) : secondaryLabel}
          style={{ display: 'flex', alignItems: 'center' }}
        />
      )}
    </div>
  );
};

CheckBox.defaultProps = {
  name: '',
  label: '',
  preLabelIcon: '',
  selected: false,
  onChange: () => null,
  isDisabled: false,
  singleSelection: false,
  index: -1,
  title: '',
  preLabelIconAlt: '',
};

export default memo<CheckBoxProps>(CheckBox);
