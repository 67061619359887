/* eslint-disable react/display-name */
/* eslint-disable react/jsx-wrap-multilines */
import { forwardRef, memo, useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../hooks';
import './Date-Picker.css';

import {
  ArrowLeft,
  ArrowRight,
  Calendar,
  ChevronDown,
} from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { getCurrentTheme } from '../../../stores/selectors/theme.selector';
import { isValidDate, parseDate } from '../../../utils/Date.Util';
import { Icon } from '../Icon.component';
import { FormInput, InputType } from '../Input.component';
import { ColorType, getHexColorByType } from '../shared/Colors.g';

export enum CustomInputType {
  DEFAULT,
  FORMINPUTDATE,
}
export interface InputProps {
  defaultDate?: Date;
  format?: string;
  onChange?: (date: any) => void;
  showMonthYearPicker?: boolean;
  inputBG?: string;
  maxDate?: Date | null;
  customInputType?: CustomInputType;
  showDefaultDate?: boolean;
  customFormInputDivWidth?: string;
  customFormInputErrorLabel?: string;
  disabled?: boolean;
  defaultSelectedDate?: any;
  customFontClass?: string;
  inputId?: string;
}

export const DateTimePicker = memo(
  ({
    defaultDate = new Date(),
    onChange,
    showMonthYearPicker = false,
    format = 'MMM yyyy',
    inputBG = 'bg-grey1 hover:bg-grey2',
    maxDate = null,
    customInputType = CustomInputType.DEFAULT,
    showDefaultDate = true,
    customFormInputDivWidth = '',
    customFormInputErrorLabel = '',
    disabled = false,
    defaultSelectedDate = null,
    customFontClass = 'font-inter',
    inputId = '',
  }: InputProps) => {
    const auth = useAuth();
    const theme = useSelector(getCurrentTheme);

    const [selectedDate, setSelectedDate] = useState(
      showDefaultDate ? defaultDate : null,
    );

    // Need to update the state when default changes
    // This will happen when clicks back button on browser
    // useEffect(() => {
    //   setSelectedDate(defaultDate);
    // }, [defaultDate]);

    // this hook take care for initial value for the datepicker regardless any other settings
    useEffect(() => {
      if (defaultSelectedDate) {
        setSelectedDate(parseDate(defaultSelectedDate, 'yyyy-MM-dd'));
      }
    }, [defaultSelectedDate]);

    const handleFormInputDate = (date: any) => {
      if (isValidDate(date)) {
        setSelectedDate(parseDate(date));
        onChange && onChange(parseDate(date));
      }
    };

    const handleFormInputDateValidation = (val: any) => {
      return isValidDate(val);
    };

    const DateCustomInput = forwardRef(({ value, onClick }: any, ref: any) => {
      if (customInputType === CustomInputType.FORMINPUTDATE) {
        return (
          <div
            className={`flex flex-row items-center ${customFormInputDivWidth}`}
          >
            <FormInput
              defaultValue={value}
              inputType={InputType.DATE}
              placeholder='MM/DD/YYYY'
              iconClassName='pl-2'
              inputIconPadding='pl-8'
              icon={Calendar}
              iconSize={IconSize.SIZE_20x20}
              onClickIcon={onClick}
              onChange={(event: any) => handleFormInputDate(event.target.value)}
              onValidation={handleFormInputDateValidation}
              errorLabel={customFormInputErrorLabel}
              width='100%'
              disabled={disabled}
              inputId={inputId}
            />
          </div>
        );
      }
      return (
        <button
          className={`react-datepicker__input-container-button ${inputBG}`}
          onClick={onClick}
          ref={ref}
          disabled={disabled}
        >
          <div className='block'>
            <div className={`inline-block text-grey6 ${customFontClass}`}>
              {value}
            </div>
            <div className='inline-block align-middle ml-4 mr-2'>
              <Icon src={ChevronDown} />
            </div>
          </div>
        </button>
      );
    });
    const onDateChanged = useCallback(
      (date: any) => {
        setSelectedDate(date);
        onChange && onChange(date);
      },
      [onChange],
    );

    const renderArrow = (arrow: any, alt: string) => {
      return <Icon src={arrow} size={IconSize.SIZE_20x20} alt={alt} />;
    };

    const onCalendarOpen = () => {
      const monthSelectedElement = document.querySelector(
        '.react-datepicker__month--selected',
      ) as HTMLElement;
      if (
        monthSelectedElement &&
        monthSelectedElement.getAttribute('aria-selected')
      ) {
        monthSelectedElement.style.backgroundColor = theme.chartColor;
      } else {
        const keyboardSelected = document.querySelector(
          '.react-datepicker__month-text--keyboard-selected',
        ) as HTMLElement;
        if (keyboardSelected) {
          keyboardSelected.style.backgroundColor = getHexColorByType(
            ColorType.WHITE,
          );
        }
      }
    };

    return (
      <DatePicker
        onYearChange={onCalendarOpen}
        onCalendarOpen={onCalendarOpen}
        locale={auth.user.attributes.profile?.sitehostDashboardLanguage}
        selected={selectedDate}
        onChange={(date: any) => onDateChanged(date)}
        dateFormat={format}
        showMonthYearPicker={showMonthYearPicker}
        customInput={<DateCustomInput />}
        maxDate={maxDate}
        previousYearButtonLabel={renderArrow(
          ArrowLeft,
          'icon_alt_previous_year',
        )}
        nextYearButtonLabel={renderArrow(ArrowRight, 'icon_alt_next_year')}
        previousMonthButtonLabel={renderArrow(
          ArrowLeft,
          'icon_alt_previous_month',
        )}
        nextMonthButtonLabel={renderArrow(ArrowRight, 'icon_alt_next_month')}
        disabled={disabled}
      />
    );
  },
);

DateTimePicker.displayName = 'DateTimePicker';
