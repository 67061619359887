import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import { More } from '../../../assets/icons';
import { URL_SEARCH_PARAM_KEY } from '../../../constant/UrlSearchParams.constant';
import { payterContext } from '../../../hooks/usePayter';
import { useUpdatePaymentTerminalInfoVisiblityMutation } from '../../../services/payouts.api';
import {
  convertDateToString,
  convertMilliSecondToDate,
} from '../../../utils/Date.Util';
import { Card, DateTimePicker, Menu, MenuItem } from '../../_ui';
import ReportStats from './ReportStats.component';

const PayoutReport = () => {
  const today = useMemo(() => new Date(), []);

  const { t } = useTranslation();

  const payterConsumer = useContext(payterContext);
  const { showTerminal, company } = payterConsumer;

  const [summaryDate, setSummaryDate] = useQueryParam(
    URL_SEARCH_PARAM_KEY.OVERVIEW_SUMMARY_DATE,
    StringParam,
  );

  const date = useMemo(() => {
    return summaryDate ? convertMilliSecondToDate(summaryDate) : today;
  }, [summaryDate, today]);

  const dateChanged = (newDate: any) => {
    setSummaryDate(convertDateToString(newDate));
  };

  const [updateShowTerminalInfoFlag] =
    useUpdatePaymentTerminalInfoVisiblityMutation();

  const onClickTerminalState = () => {
    updateShowTerminalInfoFlag({
      body: {
        ...company,
        showPaymentTerminalInfo: !showTerminal,
      },
    });
  };

  return (
    <Card className='flex flex-col mb-4' title=''>
      <div className='flex items-center justify-between'>
        <DateTimePicker
          showMonthYearPicker
          onChange={dateChanged}
          defaultDate={date}
          maxDate={today}
        />
        <Menu
          placement='bottom-end'
          icon={More}
          iconAlt={t('icon_alt_more_options')}
          dataTestId='select-terminal-view-button'
        >
          <MenuItem
            label={`${
              showTerminal
                ? t('hide_payment_terminal')
                : t('show_payment_terminal')
            } `}
            onClick={onClickTerminalState}
            dataTestId='terminal-view-toggle-button'
          />
        </Menu>
      </div>
      <ReportStats date={date} onClickTerminalState={onClickTerminalState} />
    </Card>
  );
};

export default PayoutReport;
