import Modal from 'react-modal';
import { Spinner } from '..';
import { useTimeout } from '../../../hooks';
import { useGlobalModalContext } from './GlobalModal.component';
import { MODAL_LAYER_INDEX } from './utils';

Modal.setAppElement('#root');

export const SpinnerModal = () => {
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store;
  const {
    delay = 0,
    onClose,
    spinnerComponent = null,
    height = '48px',
    width = '48px',
  } = modalProps || {};

  useTimeout(() => {
    if (delay) {
      hideModal();
      onClose && onClose();
    }
  }, delay);

  return (
    <Modal
      isOpen
      style={{
        content: {
          top: '50%',
          left: '50%',
          width,
          height,
          transform: 'translate(-50%, -50%)',
          padding: 0,
          margin: 0,
          borderRadius: 10,
          borderWidth: 0,
          overflow: 'hidden',
        },
        overlay: {
          zIndex: MODAL_LAYER_INDEX,
          backgroundColor: 'rgba(32,34,35,0.5)',
        },
      }}
      shouldCloseOnOverlayClick={false}
    >
      {spinnerComponent !== null ? (
        spinnerComponent
      ) : (
        <div className='flex flex-col justify justify-between w-full h-full'>
          <Spinner width='w-12' height='h-12' />
        </div>
      )}
    </Modal>
  );
};
