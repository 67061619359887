import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AddPlus } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { Icon } from '../Icon.component';
import { Label, LabelType } from '../Label.component';
import { ColorType } from '../index';
import { CalendarItem } from './CalendarItem.component';

export interface EventContent {
  key: string;
  title: string;
  message?: string;
  start: string;
  end: string;
  isBigItem?: boolean;
}
interface ColData {
  key: string;
  events: EventContent[];
}

interface ColHeader {
  key: string;
  label: string;
  disabled?: boolean;
  placeHolder?: string;
}
interface Props {
  headers: ColHeader[];
  data: ColData[] | undefined;
  onHeaderClick?: Function;
  onItemClick?: Function;
  onChange?: Function;
}

export const Calendar = memo(
  ({ headers, data, onHeaderClick, onItemClick, onChange }: Props) => {
    const { t } = useTranslation();

    const renderHeader = () => {
      return (
        <div className='border-t border-b border-grey2 flex flex-row items-center'>
          {headers.map((header, index) => (
            <div
              key={header.key}
              className='pl-[12px] flex flex-row flex-1 h-[40px] items-center gap-[6px]'
            >
              <Label
                text={header.label}
                type={LabelType.BODY3}
                color={ColorType.GREY6}
              />
              <Icon
                src={AddPlus}
                alt={t('add_rule')}
                size={IconSize.SIZE_16x16}
                disabled={header.disabled}
                color={header.disabled ? ColorType.GREY3 : undefined}
                onClick={() =>
                  onHeaderClick && onHeaderClick(header.key, index)
                }
                dataTestId='calenderOpen'
              />
            </div>
          ))}
        </div>
      );
    };

    const handleEventClick = (event: EventContent) => {
      onItemClick && onItemClick(event);
    };

    const renderEvent = (event: EventContent) => {
      return (
        <div
          className={`w-[139px] drop-shadow-lg ${
            event.isBigItem ? 'h-[184px]' : ''
          }`}
        >
          <CalendarItem event={event} onClick={handleEventClick} />
        </div>
      );
    };

    const renderContent = () => {
      return (
        <div className='flex flex-row mt-[4px]'>
          {headers.map(({ key, label, disabled, placeHolder }) => {
            const events = data?.find(
              (colData) => colData.key.toLowerCase() === key,
            )?.events;
            return (
              <div key={key} className='flex flex-col flex-1 gap-[8px]'>
                {events && events.length > 0 ? (
                  events?.map((event, index) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={`${event.title}-${index}`}>
                        {renderEvent(event)}
                      </div>
                    );
                  })
                ) : (
                  <div className='pl-3'>
                    <Label
                      text={placeHolder}
                      type={LabelType.LABEL_S}
                      color={ColorType.GREY5}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      );
    };
    return (
      <div className='flex flex-col w-full'>
        {renderHeader()}
        {renderContent()}
      </div>
    );
  },
);

Calendar.displayName = 'Calendar';
