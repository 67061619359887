import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  ChargerEV,
  LocationPindrop,
  More,
  NoPricing,
} from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { NUMBER } from '../../constant/Number.constant';
import { TEXT } from '../../constant/Text.constant';
import {
  useDeleteDiscountMutation,
  useFetchDiscountsQuery,
} from '../../services/discount.api';
import {
  ChargerSelection,
  ChargerSelectionList,
} from '../../stores/types/chargers.interface';
import { DiscountsType, Resource } from '../../stores/types/discount.interface';
import { roundToTwoDecimalPlaces } from '../Pricing/utils';
import {
  ButtonSize,
  ButtonType,
  ColorType,
  Grid,
  Icon,
  Label,
  LabelType,
  MODAL_TYPES,
  Menu,
  MenuItem,
  Pill,
  Skeleton,
  useGlobalModalContext,
} from '../_ui';
import { DiscountForm } from './DiscountForm/DiscountForm.component';

interface Props {
  chargersList: any[];
  allChargerList: any[];
}

export const Discounts = ({ chargersList, allChargerList }: Props) => {
  const { t } = useTranslation();
  const { ZERO, TEN, HUNDRED } = NUMBER;
  const [currentPage, setCurrentPage] = useState(1);
  const RECORDS_PER_PAGE = TEN;

  const { showModal } = useGlobalModalContext();

  const { data, isLoading: isDiscountLoading } = useFetchDiscountsQuery();

  const discounts = useMemo(() => {
    return (
      data?.entities?.map((ele: DiscountsType) => ({
        id: ele.id,
        type:
          ele.userGroupSelector.type.toUpperCase() === TEXT.INDIVIDUAL
            ? 'Individual'
            : 'Company',
        email: ele.userGroupSelector.value,
        discount: ele.adjustmentPercentage,
        appliedAt:
          ele?.resources?.map((resource: Resource) => resource.id) || [],
      })) || []
    );
  }, [data]);

  const [triggerDeleteDiscount] = useDeleteDiscountMutation();

  const onDeleteDiscount = (discount: any) => {
    triggerDeleteDiscount({
      id: discount.id,
      type: discount.type.toLowerCase(),
    });
  };

  const handleDeleteClick = (discount: any) => {
    showModal(MODAL_TYPES.ALERT_MODAL, {
      title: t('discount_delete_modal_title'),
      width: '400px',
      height: 'max-content',
      message: (
        <Trans
          i18nKey='discount_delete_modal_message'
          values={{ user: discount.email }}
        />
      ),
      iconColor: ColorType.NEGATIVE1,
      buttons: [
        {
          label: t('cancel'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
          dataTestId: 'cancelDelete',
        },
        {
          label: t('delete'),
          type: ButtonType.DESTRUCTIVE,
          size: ButtonSize.SMALL,
          onClick: () => onDeleteDiscount(discount),
          dataTestId: 'deleteConfirm',
        },
      ],
    });
  };

  const handleEditDiscount = (row: any) => {
    // PM-1665 Remove @ from company domain email before displaying in DiscountForm
    const updatedRowValue = {
      ...row,
      email: row.type === 'Company' ? row.email.substring(1) : row.email,
    };
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('discount_edit_modal_title'),
      width: '540px',
      height: 'max-content',
      onRenderBody: () => (
        <DiscountForm
          chargersList={allChargerList}
          isEditMode
          editDiscountDetails={updatedRowValue}
        />
      ),
      shouldCloseOnOverlayClick: false,
    });
  };

  const getDiscountValue = (value: number) => {
    const discountValue = Math.abs(value);
    if (discountValue === HUNDRED) return t('free');
    return `${roundToTwoDecimalPlaces(discountValue)}% ${t('off')}`;
  };

  const renderAppliedAt = (row: any) => {
    const chargers = chargersList.map((ele: ChargerSelectionList) => {
      const children = ele.children.map((charger: ChargerSelection) => {
        return {
          id: charger.id,
          label: charger.label,
          selected: row.appliedAt?.includes(charger.id),
        };
      });

      return {
        id: ele.id,
        label: ele.label,
        selected: children?.every((child: ChargerSelection) => child.selected),
        children,
      };
    });

    const isAllLocations: boolean = chargers.every(
      (ele: ChargerSelectionList) => ele.selected,
    );

    if (isAllLocations) {
      return (
        <Label
          text={t('all_location')}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      );
    }

    return (
      <div className='flex flex-wrap'>
        {chargers.map((ele: ChargerSelectionList) =>
          ele.selected ? (
            <div className='flex flex-row flex-wrap pr-2 pb-2' key={ele.id}>
              <Pill
                iconLeft={LocationPindrop}
                label={ele.label}
                iconLeftAlt={t('location')}
              />
            </div>
          ) : (
            ele.children.map((child: ChargerSelection) =>
              child.selected ? (
                <div
                  className='flex flex-row flex-wrap pr-2 pb-2'
                  key={child.id}
                >
                  <Pill iconLeft={ChargerEV} label={child.label} />
                </div>
              ) : null,
            )
          ),
        )}
      </div>
    );
  };

  const getColumnTitle = () => {
    return [
      {
        key: 'type',
        title: t('discount_type'),
        component: (row: any) => {
          if (isDiscountLoading) {
            return <Skeleton height='h-5' />;
          }
          return (
            <Label
              text={row.type}
              className='flex flex-col justify-start max-w-[198px]'
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              dataTestId={row.type}
            />
          );
        },
      },
      {
        key: 'email',
        title: t('discount_email'),
        component: (row: any) => {
          if (isDiscountLoading) {
            return <Skeleton height='h-5' />;
          }
          return (
            <Label
              text={row.email}
              className='flex flex-col justify-start'
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              dataTestId={row.email}
            />
          );
        },
      },
      {
        key: 'discount',
        title: t('discount'),
        component: (row: any) => {
          if (isDiscountLoading) {
            return <Skeleton height='h-5' />;
          }
          return (
            <Label
              text={getDiscountValue(row.discount)}
              className='flex flex-col justify-start max-w-[198px]'
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
          );
        },
      },
      {
        key: 'appliedAt',
        title: t('discount_applied_at'),
        component: (row: any) => {
          if (isDiscountLoading) {
            return <Skeleton height='h-5' />;
          }
          return renderAppliedAt(row);
        },
      },
      {
        key: 'button',
        title: t(''),
        component: (row: any) => (
          <div className='flex flex-row gap-[16px] justify-end pr-2'>
            <Menu
              placement='bottom-end'
              icon={More}
              iconAlt={t('icon_alt_more_options')}
              dataTestId='menuWrapper'
            >
              <MenuItem
                label={t('discount_edit_modal_title')}
                onClick={() => handleEditDiscount(row)}
                dataTestId='editDiscount'
              />
              <MenuItem
                label={t('discount_delete_button')}
                labelType={LabelType.BODY3}
                labelColor={ColorType.NEGATIVE1}
                onClick={() => handleDeleteClick(row)}
                dataTestId='deleteDiscount'
              />
            </Menu>
          </div>
        ),
      },
    ];
  };

  const renderDiscountsGrid = () => {
    if (!isDiscountLoading && discounts?.length === ZERO) {
      return null;
    }
    return (
      <Grid
        pageIndex={currentPage}
        loadPage={(page) => setCurrentPage(page)}
        local
        columns={getColumnTitle()}
        data={discounts}
        primaryKey='cardNumber'
        totalPage={Math.ceil((discounts.length || ZERO) / RECORDS_PER_PAGE)}
        rowCount={RECORDS_PER_PAGE}
        isLoading={isDiscountLoading}
        hasRowBottomBorder
        dataTestId='discountsData'
      />
    );
  };

  const renderEmptyDiscount = () => {
    if (!isDiscountLoading && !discounts?.length) {
      return (
        <div className='flex flex-col items-center gap-2 mt-[16px] mb-[28px] '>
          <Icon src={NoPricing} size={IconSize.SIZE_48x48} />
          <Label
            text={t('discount_empty_message')}
            type={LabelType.H4}
            color={ColorType.GREY5}
          />
          <Label
            text={t('discount_empty_desc')}
            type={LabelType.BODY2}
            color={ColorType.GREY5}
          />
        </div>
      );
    }
  };

  return (
    <>
      {renderEmptyDiscount()}
      {renderDiscountsGrid()}
    </>
  );
};
