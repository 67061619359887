export const addSearchParam = (
  url: string,
  paramName: string,
  paramValue: string,
) => {
  return `${url}?${paramName}=${paramValue}`;
};

export const getbrandname = () => {
  let brandName = window.location.hostname.split('.')?.[0];
  if (['dashboard', 'localhost'].includes(brandName)) {
    brandName = 'chargelab';
  }
  return brandName;
};
