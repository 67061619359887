import { t } from 'i18next';
import { useMemo } from 'react';
import { DeleteTrashRemove, Dollar } from '../../../../assets/icons';
import { IconSize } from '../../../../constant/IconSize.constant';
import {
  DurationType,
  PricingType,
} from '../../../../stores/types/price.interface';
import {
  ColorType,
  Dropdown,
  FormInput,
  Icon,
  Label,
  LabelType,
} from '../../../_ui';
import { InputType, ValidationType } from '../../../_ui/Input.component';

type Tier = {
  id: string;
  price: string;
  currency: string;
  duration: string;
  pricingType: string;
  durationUnit: string;
  tieredUnit: string;
};
interface IProps {
  tierNumber?: string;
  item: Tier;
  isAnyTimeTier?: boolean;
  tierPrices: Array<Tier>;
  setTierPrices: Function;
  deleteTier: Function;
  showDeleteTier: boolean;
  disabled?: boolean;
  dataTestId?: string;
  tierIndex?: number;
}

const FormItem = ({
  tierNumber,
  item,
  tierPrices,
  deleteTier,
  setTierPrices,
  isAnyTimeTier,
  showDeleteTier,
  disabled = false,
  dataTestId,
  tierIndex = 0,
}: IProps) => {
  const { id, price, pricingType, duration, durationUnit } = item;

  // inputHandler handles input change update tier entry in array or tiers

  const inputHandler = (name: string, value: string) => {
    const updatedItem = { ...item, [name]: value };
    const newTier = tierPrices?.map((tierItem) => {
      return tierItem.id === id ? updatedItem : tierItem;
    });
    setTierPrices(newTier);
  };

  // pricingTypes modifies price unit dropdown data.

  const pricingTypes = useMemo(() => {
    return Object.values(PricingType).map((type) => {
      return {
        id: type,
        label:
          type === PricingType.BILLED_BY_EFFECTIVE_CHARGING_TIME
            ? t('pricing_per_hour_charging')
            : type === PricingType.BILLED_BY_TIME_PLUGGED_IN
            ? t('pricing_per_hour_plugged_in')
            : t('pricing_per_kwh'),
        selected: pricingType === type,
      };
    });
  }, [pricingType]);

  const durationTypes = useMemo(() => {
    return Object.values(DurationType).map((type) => {
      return {
        id: type,
        label:
          type === DurationType.HOUR
            ? t('hours_of_session')
            : t('kwh_of_session'),
        selected: durationUnit === type,
      };
    });
  }, [durationUnit]);
  // show duration label - referenced by seleted price unit.

  const durationUnitLabel = useMemo(() => {
    return pricingType === PricingType.BILLED_BY_KWH
      ? t('kwh_used')
      : t('hours_of_session');
  }, [pricingType]);

  return (
    <tr className='mt-[6px] mb-[6px]'>
      <td className='pt-[12px] pb-[12px] h-[40px]  gap-[6px]'>
        <FormInput
          placeholder='0.00'
          onChange={(event: any) => inputHandler('price', event.target.value)}
          defaultValue={price}
          validationType={ValidationType.CURRENCY_ZERO_ALLOWED}
          icon={Dollar}
          width='88px'
          iconSize={IconSize.SIZE_16x16}
          inputType={InputType.FLOAT_NUMBER}
          disabled={disabled}
          dataTestId={dataTestId}
          inputTitle={t('input_title_tier_rate', { index: tierIndex })}
        />
      </td>
      <td className='min-w-[180px] pt-[12px] pb-[12px] h-[40px]  gap-[6px]'>
        <Dropdown
          headerWidth='auto'
          items={pricingTypes}
          onItemClick={(priceEvent: any) => {
            inputHandler('pricingType', priceEvent.id);
          }}
          headerHighLightClassName='bg-grey1 hover:bg-grey2 border-grey3 rounded'
          labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
          chevdownIconHighlightColor={ColorType.GREY6}
          disabled={disabled}
          dataTestId={dataTestId}
        />
      </td>

      {/* isAnyTimeTier checks if tier is last one and don't show duration form field */}

      {!isAnyTimeTier ? (
        <>
          <td className='pt-[12px] pb-[12px] h-[40px] flex flex-row items-center gap-[6px] box-content'>
            <Label
              text={t('during')}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
            />
            <Label
              text={tierNumber}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
            />
          </td>
          <td>
            <FormInput
              placeholder='0'
              onChange={(event: any) =>
                inputHandler('duration', event.target.value)
              }
              defaultValue={duration}
              inputType={InputType.NUMBER}
              width='60px'
              validationType={ValidationType.DURATION}
              disabled={disabled}
              dataTestId={dataTestId}
              inputTitle={t('input_title_tier_duration', { index: tierIndex })}
            />
          </td>
          {PricingType.BILLED_BY_KWH === pricingType ? (
            <td className='min-w-[180px] pt-[12px] pb-[12px] h-[40px]  gap-[6px]'>
              <Dropdown
                headerWidth='auto'
                items={durationTypes}
                onItemClick={(priceEvent: any) => {
                  inputHandler('durationUnit', priceEvent.id);
                }}
                headerHighLightClassName='bg-grey1 hover:bg-grey2 border-grey3 rounded'
                labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
                chevdownIconHighlightColor={ColorType.GREY6}
                disabled={disabled}
                dataTestId={dataTestId}
              />
            </td>
          ) : (
            <td className='min-w-[140px]'>
              <Label
                text={durationUnitLabel}
                type={LabelType.BODY3}
                color={ColorType.GREY6}
              />
            </td>
          )}
        </>
      ) : (
        <td className='pt-[12px] pb-[12px] h-[40px] flex flex-row items-center box-content gap-[6px]'>
          <Label
            text={t('tiered_pricing_anytime_after')}
            type={LabelType.BODY3}
            color={ColorType.GREY6}
          />
        </td>
      )}

      {/* showDeleteTier shows delete button except 1st tier */}

      {showDeleteTier && (
        <td>
          <Icon onClick={() => deleteTier(id)} src={DeleteTrashRemove} />
        </td>
      )}
    </tr>
  );
};

export default FormItem;
