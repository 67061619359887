import { ColorType, Label, LabelType, Skeleton } from '../../_ui';

interface IProps {
  name: string;
  collection: string;
  currency?: string;
  className?: string;
  isLoading?: boolean;
}

const ReportItem = ({
  name,
  collection,
  currency,
  className = 'bg-grey0',
  isLoading = false,
}: IProps) => {
  return isLoading ? (
    <Skeleton
      className='rounded-2xl block w-full bg-grey2'
      height='h-[120px]'
      loadingColor='white'
    />
  ) : (
    <div className={`block h-[120px] w-full ${className}  p-4 rounded-2xl`}>
      <Label
        type={LabelType.LABEL_M_MEDIUM}
        color={ColorType.GREY5}
        text={name}
      />
      <div className='flex justify-between mt-7'>
        <Label type={LabelType.H2} color={ColorType.BLACK} text={collection} />
        <Label
          type={LabelType.LABEL_M_MEDIUM}
          color={ColorType.GREY5}
          text={currency}
          className='flex items-end'
        />
      </div>
    </div>
  );
};

export default ReportItem;
